import React, { useState } from 'react';
import s from './SelectOptionModal.module.css';
import GeneralModal from './GeneralModal';
import { Button } from '../button/Button';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../redux/actions/modal';
import { Dropdown } from '../dropdown/Dropdown';

type Props = {
  options: { name: string; val: any }[];
  callback: (option: any) => void;
};

export const SelectOptionModal: React.FC<Props> = ({ options, callback }) => {
  const [option, setOption] = useState<any>();
  const dispatch = useDispatch();
  const save = async () => {
    if (!option) {
      return;
    }
    callback(option.val);
    dispatch(hideModal());
  };
  return (
    <GeneralModal withOverflow>
      <div className={s.container}>
        <div className={s.head}>Bæta við hlaðvarpi</div>
        <div className={s.content}>Veldu hlaðvarp til að bæta við</div>
        <div className={s.input}>
          <Dropdown
            selected={option?.name}
            label={'Hlaðvarp'}
            options={options}
            onChange={setOption}
            placeholder={'Veldu hlaðvarp'}
          />
        </div>
        <div className={s.action}>
          <Button disabled={!option} onClick={save}>
            Vista
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};
