import React, { HTMLProps } from 'react';
import s from './FormFields.module.css';

type Props = {
  error?: string;
} & HTMLProps<HTMLInputElement>;

export const FormCheckbox = React.forwardRef<any, Props>((props, ref) => {
  const { error, children, ...rest } = props;
  return (
    <div className={s.container}>
      <div className={s.checkbox}>
        <label className={s.label}>
          <input type="checkbox" ref={ref} {...rest} />
          <span className={s.checkmark} />
          <span className={s.checkmarkText}>{props.children}</span>
        </label>
      </div>
      {error && <div className={s.error}>{error}</div>}
    </div>
  );
});
