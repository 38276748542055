import React from 'react';
import { Container } from 'react-bootstrap';
import { TopNav } from '../components/top-nav/TopNav';
import { PodcastsList } from '../components/podcasts-list/PodcastsList';
import { Podcast } from '../types';
import { useTypedSelector } from '../redux/reducers';
import {
  isLoaded,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';
import PodcastBusiness from '../business/PodcastBusiness';
import { Spinner } from '../components/spinner/Spinner';
import { PageContainer } from '../components/page-container/PageContainer';
import clientConfig from '../client-config';

const Podcasts: React.FC<any> = () => {
  const firestore = useFirestore();
  const profile = useTypedSelector<any>((state) => state.firebase.profile);
  useFirestoreConnect({
    collection: 'podcasts',
    orderBy: ['createdAt', 'asc'],
    where: profile.isAdmin
      ? [
          ['active', '==', true],
          ['client', '==', clientConfig.name],
        ]
      : [
          ['userId', '==', profile.userId],
          ['active', '==', true],
          ['client', '==', clientConfig.name],
        ],
  });

  let podcasts = useTypedSelector((state) => state.firestore.ordered.podcasts);
  if (podcasts?.length) {
    podcasts = profile.isAdmin
      ? podcasts
      : podcasts.filter((p: Podcast) => p.userId === profile.userId);
  }
  const podcastBusiness = new PodcastBusiness(
    firestore,
    profile?.userId,
    profile?.email
  );
  const deletePodcast = (podcast: Podcast) => {
    return async () => {
      await podcastBusiness.update({ id: podcast.id, active: false });
    };
  };
  return (
    <PageContainer>
      <TopNav />
      <Container>
        {isLoaded(podcasts) ? (
          <PodcastsList
            deletePodcast={deletePodcast}
            showEmail={profile.isAdmin}
            podcasts={podcasts}
          />
        ) : (
          <Spinner />
        )}
      </Container>
    </PageContainer>
  );
};

export default Podcasts;
