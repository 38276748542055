import React from 'react';
import s from './PodcastFeedHeader.module.css';
import { FeedType, ModalType, Podcast } from '../../types';
import cn from 'classnames';
import { showModal } from '../../redux/actions/modal';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../redux/reducers';

type Props = {
  feedType?: FeedType | null;
  setFeedType: (type: FeedType | null) => void;
  metaText?: string;
  slug: string;
};
export const PodcastFeedHeader: React.FC<Props> = ({
  setFeedType,
  feedType,
  metaText,
  slug,
}) => {
  const dispatch = useDispatch();

  const podcast = useTypedSelector(
    (state) => state.firestore.ordered['podcast-' + slug][0]
  );
  return (
    <div className={s.container}>
      <div className={s.navContainer}>
        <button
          className={cn(s.btn, { [s.active]: !feedType })}
          onClick={() => setFeedType(null)}
        >
          Allt
        </button>

        <button
          className={cn(s.btn, { [s.active]: feedType === 'TEXT' })}
          onClick={() => setFeedType('TEXT')}
        >
          Innlegg
        </button>

        <button
          className={cn(s.btn, { [s.active]: feedType === 'VIDEO' })}
          onClick={() => setFeedType('VIDEO')}
        >
          Video
        </button>

        <button
          className={cn(s.btn, { [s.active]: feedType === 'IMAGE' })}
          onClick={() => setFeedType('IMAGE')}
        >
          Myndir
        </button>
        <button
          className={cn(s.btn, { [s.right]: true })}
          onClick={() =>
            dispatch(
              showModal(ModalType.EPISODES_MODAL, {
                podcast,
              })
            )
          }
        >
          Hljóðþættir
        </button>
      </div>
      <div className={s.titleContainer}>
        <h3 className={s.title}>Nýlegar færslur</h3>
        {metaText && <div className={s.metaText}>{metaText}</div>}
      </div>
    </div>
  );
};
