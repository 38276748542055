import React from 'react';
import { User } from '../../types';
import s from './UsersList.module.css';
import { SimpleTable } from '../simple-table/SimpleTable';
import moment from 'moment';

type Props = {
  users: User[];
};

export const UsersList: React.FC<Props> = ({ users }) => {
  return (
    <div className={s.container}>
      <SimpleTable
        cols={['Skráður þann', 'Netfang']}
        rows={users.map(u => {
          return [
            moment(u.createdAt.toDate()).format('DD. MMM YYYY HH:mm'),
            u.email
          ];
        })}
      />
    </div>
  );
};
