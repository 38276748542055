import React, { FC } from 'react';
import s from './FeedPost.module.css';
import { Button } from '../button/Button';

export const LockedTop: FC<{ podcastId: string; image?: string }> = ({
  image,
  podcastId,
}) => {
  if (!image) {
    return null;
  }
  return (
    <div
      className={s.top}
      style={{
        backgroundImage: `url(${image}?w=720)`,
      }}
    >
      <div className={s.overlay} />
      <div className={s.topContent}>
        <div className={s.lock} />
        <div className={s.title}>Færsla er læst</div>
        <div className={s.info}>Til að fá aðgang þarftu að kaupa áskrift</div>
        <div className={s.btn}>
          <Button to={'/podcasts/purchase/' + podcastId}>Kaupa áskrift</Button>
        </div>
      </div>
    </div>
  );
};
