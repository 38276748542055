import React, { FC, PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown';
import { Podcast } from '../../types';
import { Spinner } from '../spinner/Spinner';
import s from './SubscriptionInfo.module.css';
import { Button } from '../button/Button';
import { PodcastPrice } from './PodcastPrice';
import { useHistory } from 'react-router';
import { useTypedSelector } from '../../redux/reducers';
import cn from 'classnames';

type Props = PropsWithChildren<{
  hasSubscription: boolean;
  podcast: Podcast;
  isLoading?: boolean;
}>;
export const SubscriptionInfo: FC<Props> = ({
  podcast,
  hasSubscription,
  isLoading,
  children,
}) => {
  const history = useHistory();
  const profile = useTypedSelector((state) => state.firebase.profile);
  const auth = useTypedSelector((state) => state.firebase.auth);
  const subscriptionPlan = podcast.subscriptionPlans?.length
    ? podcast.subscriptionPlans[0]
    : null;
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className={s.container}>
      {!subscriptionPlan && <h2>Opið efni</h2>}
      {subscriptionPlan && (
        <h2>{hasSubscription ? 'Þú ert áskrifandi!' : 'Áskrift'}</h2>
      )}
      <ReactMarkdown>{podcast.subscriptionDescription || ''}</ReactMarkdown>
      {subscriptionPlan && !hasSubscription && !podcast.expiration && (
        <div className={s.subscribe}>
          <PodcastPrice podcast={podcast} withBorder />
          <div
            className={cn(s.btn)}
          >
            <Button
              onClick={() => history.push('/podcasts/purchase/' + podcast.id)}
              grow
              uppercase
            >
              Kaupa áskrift
            </Button>
          </div>
          {<div className={s.info}>
              Áskriftin endurnýjast eftir{' '}
              {subscriptionPlan?.months === 1
                ? '1 mánuð'
                : subscriptionPlan?.months + ' mánuði'}
            </div>}
        </div>
      )}
      {(podcast.userId === auth?.uid || profile?.isAdmin) && (
        <div className={s.manageBtn}>
          <Button
            onClick={() => history.push('/podcasts/manage/' + podcast.id)}
            grow
          >
            Stjórna
          </Button>
        </div>
      )}
    </div>
  );
};
