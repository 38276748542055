import React from 'react';
import s from './Textarea.module.css';
import { uuid } from '../../utils/utilities';

type Props = {
  value: string;
  onChange: (val: string) => void;
  label?: string;
  placeholder?: string;
  maxLength?: number;
};

export const Textarea: React.FC<Props> = ({
  value,
  onChange,
  label,
  placeholder,
  maxLength
}) => {
  const id = uuid();
  return (
    <div className={s.container}>
      {label && (
        <label className={s.label} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={s.areaContainer}>
        <textarea
          id={id}
          placeholder={placeholder}
          className={s.textarea}
          onChange={e => onChange(e.target.value)}
          maxLength={maxLength}
          value={value}
        />
      </div>
    </div>
  );
};
