import React, { FC, HTMLProps } from 'react';
import { uuid } from '../../utils/utilities';
import s from './FormFields.module.css';
type Props = {
  error?: string;
  label?: string;
  onEnter?: () => void;
} & HTMLProps<HTMLInputElement>;
export const FormInput: any = React.forwardRef<any, Props>((props, ref) => {
  const { error, label, ...rest } = props;
  const id = uuid();
  return (
    <div className={s.container}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        id={id}
        ref={ref}
        onKeyDown={(e) =>
          props.onEnter && e.key === 'Enter' ? props.onEnter() : null
        }
        {...rest}
      />
      {error && <div className={s.error}>{error}</div>}
    </div>
  );
});
