import React, { ReactNode } from 'react';
import s from './TitleCard.module.css';
import classNames from 'classnames';

type Props = {
  title: string;
  className?: string;
  children: ReactNode;
}

export const TitleCard: React.FC<Props> = ({ title, className, children}) => (
  <div className={classNames({ [s.titleCard]: true, [className || '']: !!className })}>
    {!!title && <div className={s.title}>
      {title}
    </div>}
    {children}
  </div>
);
