import React, { useState } from 'react';
import s from './FeedCommentsList.module.css';
import { FeedComment } from '../../types';
import { FeedCommentItem } from '../feed-comment/FeedCommentItem';

type Props = {
  comments: FeedComment[];
  slug: string;
};

export const FeedCommentsList: React.FC<Props> = ({ comments, slug }) => {
  const maxComments = 3;
  const [showAllComments, setShowAllComments] = useState<boolean>(false);
  const filteredComments =
    showAllComments || comments?.length <= maxComments ? comments : [];
  if (comments?.length > maxComments && !showAllComments) {
    for (let i = comments?.length - maxComments; i < comments?.length; i++) {
      filteredComments.push(comments[i]);
    }
  }
  const restComments = comments?.length - maxComments;
  return (
    <div className={s.container}>
      {!showAllComments && comments?.length > maxComments ? (
        <span
          className={s.showAllComments}
          onClick={() => setShowAllComments(true)}
        >
          Skoða{' '}
          {restComments === 1
            ? 'eina athugasemd í viðbót'
            : restComments + ' aðrar athugasemdir'}
        </span>
      ) : null}
      {filteredComments?.map((c: FeedComment, index) => {
        if (index >= maxComments && !showAllComments) {
          return null;
        }
        return <FeedCommentItem key={c.id} comment={c} slug={slug} />;
      })}
    </div>
  );
};
