import { useEffect, useLayoutEffect, useRef } from 'react';

export const TallyPoll = ({ id, title }: { id: string; title: string }) => {
  useEffect(() => {
    const externalScript = document.createElement('script');
    externalScript.src = 'https://tally.so/widgets/embed.js';
    externalScript.async = true;
    externalScript.onload = () => {
      const Tally: any = (window as any).Tally;
      Tally.loadEmbeds();
    };
    document.head.append(externalScript);
    return () => {
      externalScript.remove();
    };
  }, []);
  return (
    <>
      <iframe
        data-tally-src={`https://tally.so/embed/${id}?alignLeft=1&transparentBackground=1&dynamicHeight=1`}
        loading="lazy"
        width="100%"
        height="567"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
        title={title}
      ></iframe>
    </>
  );
};
