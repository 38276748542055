import { ThunkDispatch } from 'redux-thunk';
import {ModalAction, ModalActionType, ModalState, ModalType} from "../../types";

export const showModal = (
  type: ModalType,
  modalProps: any
) => (dispatch: ThunkDispatch<ModalState, void, ModalAction>) => {
  dispatch({
    type: ModalActionType.SHOW_MODAL,
    modalType: type,
    modalProps
  });
};

export const hideModal = () => (
  dispatch: ThunkDispatch<ModalState, void, ModalAction>
) => {
  dispatch({
    type: ModalActionType.HIDE_MODAL
  });
};
