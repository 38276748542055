import React from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useTypedSelector } from '../../redux/reducers';
import { FrontCategory, Podcast } from '../../types';
import { TailwindContainer } from '../tailwind-container/TailwindContainer';
import { FrontCategoryItem } from '../front-category-item/FrontCategoryItem';
export type HomepageVisirListProps = {};
export const HomepageVisirList: React.FC<HomepageVisirListProps> = ({}) => {
  useFirestoreConnect({
    collection: 'podcasts',
    orderBy: ['createdAt', 'asc'],
    where: [
      ['active', '==', true],
      ['status', '==', 'PUBLISHED'],
      ['client', '==', 'visir'],
    ],
  });
  useFirestoreConnect({
    collection: 'frontcategories',
    orderBy: ['ordinal', 'asc'],
    where: [
      ['active', '==', true],
      ['client', '==', 'visir'],
    ],
  });
  const categories = useTypedSelector(
    (state) => state.firestore.ordered.frontcategories
  );
  const podcasts = useTypedSelector(
    (state) => state.firestore.ordered.podcasts
  );
  const publishedPodcasts = podcasts?.filter(
    (p: Podcast) => p.status === 'PUBLISHED'
  );
  return (
    <TailwindContainer>
      <div className="tw-my-10">
        {categories?.map((cat: FrontCategory) => (
          <FrontCategoryItem
            key={cat.id}
            title={cat.name}
            layout={cat.layout}
            podcasts={cat.podcasts
              .map((id) => publishedPodcasts.find((p: Podcast) => p.id === id))
              .filter((p) => !!p)}
          />
        ))}
      </div>
    </TailwindContainer>
  );
};
