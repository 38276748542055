import React, { HTMLProps, ReactNode } from 'react';
import s from './Button.module.css';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import clientConfig from '../../client-config';

type Props = {
  to?: string;
  disabled?: boolean;
  icon?: string;
  onClick?: () => void;
  className?: string;
  grow?: boolean;
  uppercase?: boolean;
  children: ReactNode;
} & HTMLProps<HTMLButtonElement>;

export const Button: React.FC<Props> = ({
  to,
  disabled,
  icon,
  onClick,
  children,
  grow,
  uppercase,
  className,
  ...rest
}) => {
  const isLink = typeof to !== 'undefined';
  const iconBtn = typeof icon !== 'undefined';
  const passProps: any = { ...rest };
  passProps.className = cx({
    [s.visirPrimary]: clientConfig.name === 'visir' && !className,
    [s.visir]: clientConfig.name === 'visir',
    [s.grow]: grow,
    [s.uppercase]: uppercase,
    [s.button]: true,
    [s.disabled]: disabled,
    [s.iconBtn]: iconBtn,
    [s[className || '']]: !!className,
  });
  passProps.disabled = disabled;
  if (iconBtn) {
    passProps.style = { backgroundImage: 'url(/img/icons/' + icon };
  }
  if (isLink) {
    return (
      <NavLink to={to} {...passProps}>
        {children}
      </NavLink>
    );
  }

  if (onClick) {
    passProps.onClick = () => onClick();
  }

  return (
    <button data-jest={'button'} {...passProps}>
      {children}
    </button>
  );
};
