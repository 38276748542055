import React, { useEffect, useLayoutEffect } from 'react';
import { PlayerSdk } from '@api.video/player-sdk';
import { uuid } from '../../utils/utilities';
type Props = {
  src: string;
  mute?: boolean;
} & any;
export const LivePlayer: React.FC<Props> = ({ src, mute, ...rest }) => {
  const id = uuid();

  useLayoutEffect(() => {
    if (mute) {
      const player = new PlayerSdk('#live-player', { id });
      player.setVolume(0);
    }
  }, []);
  return (
    <iframe
      id={'live-player'}
      src={src}
      width={'100%'}
      height={'100%'}
      allowFullScreen={true}
      frameBorder={0}
      scrolling={'no'}
      {...rest}
    />
  );
};
