import React, { useState } from 'react';
import { PodcastForm } from '../components/podcast-form/PodcastForm';
import { Podcast } from '../types';
import PodcastBusiness from '../business/PodcastBusiness';
import { error, success } from '../utils/Notifications';
import { Redirect } from 'react-router';
import { useFirestore } from 'react-redux-firebase';
import { useTypedSelector } from '../redux/reducers';
import { PageContainer } from '../components/page-container/PageContainer';

const NewPodcast: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');
  const firestore = useFirestore();
  const profile: any = useTypedSelector((state) => state.firebase.profile);
  const create = async (podcast: Podcast) => {
    setIsLoading(true);
    const podcastBusiness = new PodcastBusiness(
      firestore,
      profile?.userId,
      profile?.email
    );
    try {
      await podcastBusiness.create(podcast);
      setIsLoading(false);
      success('Hlaðvarpið hefur verið búið til.');
      setRedirect('/podcasts');
    } catch (err: any) {
      error(err.message || 'Ekki tókst að búa til hlaðvarp');
      setIsLoading(false);
    }
  };
  return (
    <PageContainer>
      <PodcastForm
        title={'Nýtt hlaðvarp'}
        save={create}
        isLoading={isLoading}
      />
      {redirect && <Redirect to={redirect} />}
    </PageContainer>
  );
};

export default NewPodcast;
