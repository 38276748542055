//@ts-ignore
import firebase from 'firebase/compat/app';
import { FileMimeType } from "./mime-types";

export const uuid = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const validateEmail = (mail?: string): boolean => {
  if (!mail) {
    return false;
  }
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
};

export const dataURLtoFile = (dataurl: string): File => {
  let arr = dataurl.split(',');
  let m = arr[0].match(/:(.*?);/);
  let mime = m && m.length > 1 ? m[1] : '';
  let bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], '', { type: mime });
};

export const formatMoney = (amount: number) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const readFilesFromFileList = (
  files: FileList,
  inputId: string
): Promise<{ name: string; size: number; file: File }[]> => {
  return new Promise((resolve, reject) => {
    if (!files?.length) {
      return resolve([]);
    }
    let count = files.length;
    const fileList: {
      name: string;
      size: number;
      file: File;
    }[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i) as File;
      fileList.push({ name: file.name, size: file.size, file });
      if (!--count) {
        //@ts-ignore
        document.getElementById(inputId).value = '';
        resolve(fileList);
      }
    }
  });
};

export const uploadAttachment = async (
  file: File,
  fireb: any,
  uid: string,
  isVideo?: boolean,
  setUploadProgress?: (progress: number) => void
): Promise<string> => {
  return new Promise((resolve) => {
    //@ts-ignore
    let fileName = uuid() + '.' + (FileMimeType[file.type] || '.jpg');
    const uploadTask = firebase
      .storage()
      .ref('/attachments/' + uid + '/' + fileName)
      .put(file);
    if ((window as any).uploadTask) {
      (window as any).uploadTask.cancel();
      (window as any).uploadTask = null;
    }
    if (isVideo) {
      (window as any).uploadTask = uploadTask;
    }
    uploadTask.on(
      fireb.storage.TaskEvent.STATE_CHANGED,
      (snapshot: any) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(progress);
        if (isVideo && setUploadProgress) {
          setUploadProgress(progress);
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        (window as any).uploadTask = null;
        const url = isVideo
          ? uploadTask.snapshot.ref.getDownloadURL()
          : process.env.REACT_APP_IMGIX +
            '/attachments/' +
            uid +
            '/' +
            fileName;
        resolve(url);
      }
    );
  });
};

export const makeid = (length: number) => {
  let result = '';
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const b64toBlob = (
  b64Data: string,
  contentType = '',
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
export const fileToBuffer = (file: File): Promise<Buffer | null> => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onerror = (err) => reject(err);
    fr.onloadend = () => resolve(fr.result ? Buffer.from(fr.result as any) : null);
  });
};
export const blobToBase64 = function (blob: any) {
  let blobUrl = URL.createObjectURL(blob);

  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = blobUrl;
  }).then((img: any) => {
    URL.revokeObjectURL(blobUrl);
    let canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx: any = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    return canvas.toDataURL();
  });
};
export const CC_ERROR_MESSAGES = {
  emptyCardNumber: 'Kortanúmerið má ekki vera tómt',
  invalidCardNumber: 'Vitlaust kortanúmer',
  emptyExpiryDate: 'Vitlaus gildistími',
  monthOutOfRange: 'Mánuður verður að vera á milli 01 og 12',
  yearOutOfRange: 'Vitlaust ár',
  dateOutOfRange: 'Vitlaus dagsetning',
  invalidExpiryDate: 'Vitlaus dagsetning',
  emptyCVC: 'CVC má ekki vera tómt',
  invalidCVC: 'Vitlaust CVC',
};
export function slugify(val: string) {
  let str = val;
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

const fallbackCopyTextToClipboard = (text: string) => {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.log(err);
  }

  document.body.removeChild(textArea);
};
export const copyTextToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text);
};
