import React from 'react';
import s from './PodcastsList.module.css';
import { ModalType, Podcast } from '../../types';
import { Button } from '../button/Button';
import { SimpleTable } from '../simple-table/SimpleTable';
import { NoItems } from '../no-items/NoItems';
import { ContextMenu } from '../context-menu/ContextMenu';
import { Link } from 'react-router-dom';
import { showModal } from '../../redux/actions/modal';
import { useDispatch } from 'react-redux';

type Props = {
  podcasts: Podcast[];
  showEmail?: boolean;
  deletePodcast?: (podcast: Podcast) => () => Promise<void>;
  lockEdit?: boolean;
};

export const PodcastsList: React.FC<Props> = ({
  podcasts,
  showEmail,
  lockEdit,
  deletePodcast,
}) => {
  const dispatch = useDispatch();
  if (!podcasts) {
    return null;
  }
  const cols = ['Hlaðvarp', 'Staða'];
  if (showEmail) {
    cols.push('Notandi');
  }
  cols.push('');
  const rows = podcasts.map((p) => {
    const ret: any = [
      lockEdit ? (
        <Link className={s.link} to={`/${p.slug}`}>
          {p.name}
        </Link>
      ) : (
        <Link className={s.link} to={`/podcasts/manage/${p.id}`}>
          {p.name}
        </Link>
      ),
      p.status === 'DRAFT' ? 'Óvirkt' : 'Virkt',
    ];
    if (showEmail) {
      ret.push(p.userEmail);
    }
    const actions = (
      <div style={{ textAlign: 'right', paddingTop: '8px' }}>
        {!lockEdit && (
          <ContextMenu>
            <Link to={`/podcasts/manage/${p.id}`}>Opna</Link>
            <Link to={`/podcasts/edit/${p.id}`}>Breyta</Link>
            <div
              className={s.delete}
              onClick={() =>
                dispatch(
                  showModal(ModalType.CONFIRM_MODAL, {
                    title: 'Eyða hlaðvarpi',
                    actionText: 'Eyða',
                    //@ts-ignore
                    action: deletePodcast(p),
                    body: 'Ertu viss um að þú viljir eyða?',
                  })
                )
              }
            >
              Eyða
            </div>
          </ContextMenu>
        )}
      </div>
    );
    ret.push(actions as any);
    return ret;
  });
  return (
    <div className={s.container}>
      {!!podcasts.length && !lockEdit && (
        <div className={s.new}>
          <Button to={'/podcasts/new'}>+Nýtt hlaðvarp</Button>
        </div>
      )}
      {!!podcasts.length && <SimpleTable cols={cols} rows={rows} />}
      {!podcasts.length && !lockEdit && (
        <div style={{ marginTop: '15px' }}>
          <NoItems message={'Þú hefur ekki búið til hlaðvarp ennþá.'} />
          <div style={{ textAlign: 'center' }}>
            <Button to={'/podcasts/new'}>+Nýtt hlaðvarp</Button>
          </div>
        </div>
      )}
    </div>
  );
};
