import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FrontCategoryLayout, Podcast } from '../../types';
import { useWindowSize } from '../../hooks/use-window.size';
import s from './FrontCategoryItem.module.css';
import cn from 'classnames';

export type FrontCategoryItemProps = {
  layout: FrontCategoryLayout;
  title: string;
  podcasts: Podcast[];
};
export const FrontCategoryItem: React.FC<FrontCategoryItemProps> = ({
  layout,
  title,
  podcasts,
}) => {
  const [computedAr, setComputedAr] = useState(0);
  const [width] = useWindowSize();
  const ar = layout === FrontCategoryLayout.LARGE ? '0.6475:1' : '1.55:1';
  const height =
    width < 576 ? 320 : layout === FrontCategoryLayout.LARGE ? 400 : 167;
  const imgContainerRef = useRef(null);
  useLayoutEffect(() => {
    if (imgContainerRef?.current) {
      const el: any = imgContainerRef?.current;
      const elHeight = el?.getBoundingClientRect().height;
      const elWidth = el?.getBoundingClientRect().width;
      if (elHeight && elWidth) {
        setComputedAr(elWidth / elHeight);
      }
    }
  }, [imgContainerRef, width]);
  return (
    <section className={'tw-mt-5 tw-flex tw-flex-col sm:tw-block'}>
      <h2 className="tw-text-lg">{title}</h2>
      <div className="tw-w-full sm:tw-grid sm:tw-mt-5 sm:tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5 tw-gap-6">
        {podcasts.map((podcast) => (
          <Link
            to={`/${podcast.slug}`}
            key={podcast.id}
            className="tw-mt-5 tw-block sm:tw-mt-0 tw-cursor-pointer"
          >
            <div
              ref={imgContainerRef}
              className={cn(s.imgContainer, 'tw-bg-gray-200 tw-min-w-full')}
              style={{
                minHeight: `${height}px`,
                backgroundImage: `url(${podcast.image}?h=${height}&ar=${
                  computedAr || ar
                }&fit=crop&crop=top)`,
              }}
            />
            <div className="tw-mt-4 tw-text-black">
              <strong>{podcast.name}</strong>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};
