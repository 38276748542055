import React, { useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { Redirect } from 'react-router';
import CategoryBusiness from '../../business/CategoryBusiness';
import { CategoryForm } from '../../components/category-form/CategoryForm';
import { PageContainer } from '../../components/page-container/PageContainer';
import { FrontCategory } from '../../types';
import { error, success } from '../../utils/Notifications';
import NewPodcast from '../NewPodcast';

const NewCategory: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');
  const firestore = useFirestore();
  const create = async (cat: FrontCategory) => {
    setIsLoading(true);
    const categoryBusiness = new CategoryBusiness(firestore);
    try {
      await categoryBusiness.create(cat);
      setIsLoading(false);
      success('Flokkur hefur verið búinn til.');
      setRedirect('/admin');
    } catch (err: any) {
      error(err.message || 'Ekki tókst að búa til flokk');
      setIsLoading(false);
    }
  };
  return (
    <PageContainer>
      <CategoryForm title={'Nýr flokkur'} save={create} isLoading={isLoading} />
      {redirect && <Redirect to={redirect} />}
    </PageContainer>
  );
};

export default NewCategory;
