import React from 'react';
import { Container } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { PageContainer } from '../../components/page-container/PageContainer';
import { RegisterForm } from '../../components/register-form/RegisterForm';
import { useTypedSelector } from '../../redux/reducers';
import s from './LoginVisir.module.css';
import clientConfig from '../../client-config';
import { Helmet } from 'react-helmet';
export const RegisterVisir: React.FC<any> = (props) => {
  const auth = useTypedSelector((state) => state.firebase.auth);

  return (
    <PageContainer>
      <Helmet>
        <title>Stofna aðgang | {clientConfig.title}</title>
        <link rel={'canonical'} href={clientConfig.canonical + '/register'} />
      </Helmet>
      <Container>
        {auth.isLoaded && auth.isEmpty && (
          <div className={s.flex}>
            <div className={s.right}>
              <div className={s.loginContainer}>
                <div className={s.loginContent}>
                  <h1>Nýskráning</h1>
                  <div>
                    Fylltu út upplýsingar hér að neðan til að stofna aðgang
                  </div>
                  <div style={{ marginBottom: '1.5rem' }}>
                    <RegisterForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {auth.isLoaded && !auth.isEmpty && (
          <Redirect to={props.location.state?.from || '/'} />
        )}
      </Container>
    </PageContainer>
  );
};
