import React, { useEffect, useState } from 'react';
import s from './FeedAttachments.module.css';
import classNames from 'classnames';
import { getThumbnails } from 'video-metadata-thumbnails';
import { blobToBase64 } from '../../utils/utilities';

export type FeedPostAttachmentType = 'IMAGE' | 'VIDEO' | 'VIMEO';
export type FeedPostAttachment = {
  id: string;
  type: FeedPostAttachmentType;
  isUploading?: boolean;
  name?: string;
  content?: string;
  file?: File;
  url?: string;
  blurredUrl?: string;
  vimeo?: { url: string };
  thumbnail?: string;
  klippari?: { url: string };
};
type Props = {
  attachments: FeedPostAttachment[];
  setAttachments: (attachments: FeedPostAttachment[]) => void;
};

const FeedAttachment: React.FC<{
  attachment: FeedPostAttachment;
  remove: () => void;
}> = ({ attachment, remove }) => {
  const [imgContent, setImgContent] = useState('');
  useEffect(() => {
    if (attachment.type === 'IMAGE') {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(attachment.file as File);
      fileReader.onload = () => {
        const content = fileReader.result as string;
        setImgContent(content);
      };
    }
  }, [attachment]);
  return (
    <div className={classNames({ [s.attachment]: true })}>
      <div className={s.wrapper}>
        <div
          className={classNames(s.overlay, {
            [s.full]: attachment.type !== 'IMAGE' && !attachment.thumbnail,
          })}
        >
          <div className={s.remove} onClick={remove} />
          {attachment.isUploading && <div className={s.loading} />}
        </div>
        {attachment.type === 'IMAGE' && (
          <img src={imgContent} className={s.img} alt={''} />
        )}
        {(attachment.type === 'VIDEO' || attachment.type === 'VIMEO') && (
          <>
            {attachment.thumbnail ? (
              <img src={attachment.thumbnail} className={s.img} alt={''} />
            ) : (
              <>
                {!attachment.isUploading && (
                  <img
                    src={'/img/icons/video-accept.svg'}
                    className={s.placeholderImg}
                    alt={''}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const FeedAttachments: React.FC<Props> = ({
  attachments,
  setAttachments,
}) => {
  if (!attachments.length) {
    return null;
  }
  return (
    <div className={s.container}>
      {attachments.map((a) => (
        <FeedAttachment
          key={a.id}
          attachment={a}
          remove={() => {
            if ((window as any).uploadTask) {
              (window as any).uploadTask.cancel();
            }
            setAttachments(attachments.filter((at) => at.id !== a.id));
          }}
        />
      ))}
    </div>
  );
};
