import React from 'react';
import { Container } from 'react-bootstrap';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { PageContainer } from '../components/page-container/PageContainer';
import { PodcastSubscriptionFrame } from '../components/podcast-subscription-frame/PodcastSubscriptionFrame';
import { CenteredSpinner } from '../components/spinner/CenteredSpinner';
import { useTypedSelector } from '../redux/reducers';
import { Podcast } from '../types';
import { VisirTheme } from '../visir-components/visir-theme/VisirTheme';
import { Redirect } from 'react-router-dom';
import { RegisterForm } from '../components/register-form/RegisterForm';
import { ScrollToTop } from '../components/scroll-to-top/ScrollToTop';

const PodcastPurchaseLogin: React.FC<any> = (props) => {
  const {
    match: { params },
  } = props;
  const { podcastId } = params;
  const auth = useTypedSelector((state) => state.firebase.auth);
  useFirestoreConnect({
    collection: 'podcasts',
    doc: podcastId,
    storeAs: 'podcast-' + podcastId,
  });

  const data = useTypedSelector(
    (state) => state.firestore.ordered['podcast-' + podcastId]
  );
  const podcast: Podcast = data ? data[0] : null;

  if (isEmpty(podcast) || !isLoaded(podcast)) {
    return <CenteredSpinner />;
  }

  return (
    <VisirTheme>
      <ScrollToTop />
      <PageContainer>
        <Container>
          <PodcastSubscriptionFrame podcast={podcast}>
            <h1>Um þig</h1>
            <RegisterForm podcastId={podcastId} slug={podcast.slug} />
          </PodcastSubscriptionFrame>
        </Container>
      </PageContainer>
      {auth.isLoaded && !auth.isEmpty && (
        <Redirect to={'/podcasts/payment/' + podcastId} />
      )}
    </VisirTheme>
  );
};
export default PodcastPurchaseLogin;
