import React from 'react';
import { NavLink } from 'react-router-dom';
import s from './VisirHeader.module.css';
import { Button } from '../../components/button/Button';
import { useTypedSelector } from '../../redux/reducers';
import { useFirebase } from 'react-redux-firebase';
import { useWindowSize } from '../../hooks/use-window.size';

type Props = {};
export const VisirHeader: React.FC<Props> = ({}) => {
  const auth = useTypedSelector((state) => state.firebase.auth);
  const profile = useTypedSelector((state) => state.firebase.profile);
  const firebase = useFirebase();
  const [width] = useWindowSize();
  return (
    <div className={s.container}>
      <div className={s.top}>
        <img
          src={'/img/visir_logo.svg'}
          alt={'Vísir'}
          onClick={() => (window.location.href = 'https://visir.is')}
        />
      </div>
      <nav className={s.nav}>
        <div className={s.left}>
          <NavLink exact to={'/'} activeClassName={s.active}>
            Heim
          </NavLink>
          <NavLink to={'/algengar-spurningar'} activeClassName={s.active}>
            {width < 620 ? 'FAQ' : 'Algengar spurningar'}
          </NavLink>
          {auth.isLoaded && !auth.isEmpty && (
            <>
              <NavLink to={'/subscriptions'} activeClassName={s.active}>
                Mitt svæði
              </NavLink>
              <NavLink to={'/rss'} activeClassName={s.active}>
                RSS hlekkir
              </NavLink>
            </>
          )}
        </div>
        <div className={s.right}>
          {auth.isLoaded && auth.isEmpty && (
            <>
              <Button to={'/login'}>Innskráning</Button>
              {width > 620 && (
                <Button
                  className={'black'}
                  onClick={() =>
                    window.open(
                      'https://docs.google.com/forms/d/e/1FAIpQLSeoLr2ed60cPMfqTB7qyMDvVz3eucgVMYfZaNLgFHn9Vbyxgg/viewform?usp=sf_link '
                    )
                  }
                >
                  Ég vil vinna með Tal
                </Button>
              )}
            </>
          )}
          {auth.isLoaded && !auth.isEmpty && (
            <div className={s.profile}>
              <span className={s.profileName}>
                {profile?.name?.split(' ')?.[0] || profile?.email}
              </span>
              <Button
                onClick={async () => {
                  await firebase.logout();
                  window.location.reload();
                }}
              >
                Útskráning
              </Button>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};
