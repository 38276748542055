import React, { ReactNode } from 'react';
import s from './RoundedContainer.module.css';
import classNames from 'classnames';

type Props = {
  className?: string;
  children?: ReactNode;
};

export const RoundedContainer: React.FC<Props> = ({ className, children }) => {
  return (
    <div
      className={classNames({
        [s.container]: true,
        [className || '']: !!className
      })}
    >
      {children}
    </div>
  );
};
