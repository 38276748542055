import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import clientConfig from '../client-config';
import { ChangePassword } from '../components/change-password/ChangePassword';
import { MySubscriptionsList } from '../components/my-subscriptions-list/MySubscriptionsList';
import { PageContainer } from '../components/page-container/PageContainer';
import { Spinner } from '../components/spinner/Spinner';
import { TopNav } from '../components/top-nav/TopNav';
import { useTypedSelector } from '../redux/reducers';
import { Podcast, ProductSubscription, Subscription } from '../types';
import s from './Subscriptions.module.css';

const Subscriptions: React.FC<any> = () => {
  const profile = useTypedSelector<any>((state) => state.firebase.profile);
  useFirestoreConnect({
    collection: 'podcastsubscriptions',
    orderBy: ['createdAt', 'desc'],
    where: [['userId', '==', profile.userId]],
  });
  useFirestoreConnect({
    collection: 'productsubscriptions',
    orderBy: ['createdAt', 'desc'],
    where: [['userId', '==', profile.userId]],
  });
  useFirestoreConnect({
    collection: 'products',
    where: ['active', '==', true],
  });
  useFirestoreConnect({
    collection: 'podcasts',
    orderBy: ['createdAt', 'desc'],
    where: [
      ['active', '==', true],
      ['status', '==', 'PUBLISHED'],
    ],
  });
  let podcasts = useTypedSelector((state) => state.firestore.ordered.podcasts);
  let podcastsubscriptions: Subscription[] = useTypedSelector(
    (state) => state.firestore.ordered.podcastsubscriptions
  );
  let filteredPodcasts = [];
  if (podcasts?.length && podcastsubscriptions?.length) {
    const subscriptionIds = podcastsubscriptions.map((s) => s.podcastId);
    filteredPodcasts = podcasts.filter((p: Podcast) =>
      subscriptionIds.includes(p.id as string)
    );
  }
  return (
    <PageContainer>
      <Helmet>
        <title>Mitt svæði | {clientConfig.title}</title>
        <link
          rel={'canonical'}
          href={clientConfig.canonical + '/subscriptions'}
        />
      </Helmet>
      <TopNav />
      <Container>
        {isLoaded(podcasts) && isLoaded(podcastsubscriptions) ? (
          <div className={s.container}>
            {!!filteredPodcasts?.length && (
              <>
                <h2>Mínar áskriftir</h2>
                <MySubscriptionsList
                  subscriptions={podcastsubscriptions}
                  podcasts={filteredPodcasts}
                />
              </>
            )}
            <h2>Nýtt lykilorð</h2>
            <ChangePassword />
          </div>
        ) : (
          <Spinner />
        )}
      </Container>
    </PageContainer>
  );
};

export default Subscriptions;
