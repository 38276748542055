import React, { useEffect, useState } from 'react';
import withPodcast from '../utils/withPodcast';
import { ManagePodcastTab, Podcast } from '../types';
import { TopNav } from '../components/top-nav/TopNav';
import { ManagePodcast } from '../components/manage-podcast/ManagePodcast';
import { PageContainer } from '../components/page-container/PageContainer';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import LivesBusiness from '../business/LivesBusiness';
import { Spinner } from '../components/spinner/Spinner';
import { useTypedSelector } from '../redux/reducers';
import { ManageLiveEvent } from '../components/manage-live-event/ManageLiveEvent';

const ManagePodcastLive: React.FC<any> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    podcast,
    profile,
  }: {
    podcast: Podcast;
    profile: any;
  } = props;
  useFirestoreConnect({
    collection: 'liveevents',
    orderBy: ['createdAt', 'desc'],
    where: profile.isAdmin
      ? [['podcastId', '==', podcast.id]]
      : [
          ['podcastId', '==', podcast.id],
          ['userId', '==', profile.userId],
        ],
  });
  useEffect(() => {
    const liveBusiness: LivesBusiness = new LivesBusiness();
    const createLiveEvent = async () => {
      setIsLoading(true);
      if (podcast.id) {
        await liveBusiness.createLiveEvent(podcast.id);
      }
      setIsLoading(false);
    };
    createLiveEvent();
  }, []);
  const liveEvents = useTypedSelector(
    (state) => state.firestore.ordered.liveevents
  );

  const liveEvent = liveEvents ? liveEvents[0] : null;
  return (
    <PageContainer>
      <TopNav />
      <ManagePodcast context={ManagePodcastTab.LIVE} podcast={podcast}>
        {isLoading || !isLoaded(liveEvents) || !liveEvent ? (
          <Spinner />
        ) : (
          <ManageLiveEvent liveEvent={liveEvent} podcast={podcast} />
        )}
      </ManagePodcast>
    </PageContainer>
  );
};

export default withPodcast(ManagePodcastLive);
