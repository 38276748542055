import React from 'react';
import GeneralModal from './GeneralModal';

export type InstructionsModalProps = {
  content: React.DOMElement<any, any>;
};
const BasicModal: React.FC<InstructionsModalProps> = ({ content }) => {
  return <GeneralModal>{content}</GeneralModal>;
};

export default BasicModal;
