import React, { useState } from 'react';
import s from './LiveEventComments.module.css';
import { PardusTextareaAutosize } from '../textarea-autosize/PardusTextareaAutosize';
import { error } from '../../utils/Notifications';
import { showModal } from '../../redux/actions/modal';
import { LiveEventComment, ModalType, Podcast } from '../../types';
import { useTypedSelector } from '../../redux/reducers';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  isLoaded,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';
import LivesBusiness from '../../business/LivesBusiness';
import classNames from 'classnames';
import { Spinner } from '../spinner/Spinner';
import { LiveCommentItem } from '../live-comment-item/LiveCommentItem';
import { Button } from '../button/Button';

type Props = {
  liveEventId: string;
  podcast: Podcast;
};
export const LiveEventComments: React.FC<Props> = ({
  podcast,
  liveEventId,
}) => {
  const commentsLimit = 5;
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState('');
  const [limit, setLimit] = useState(commentsLimit);
  const auth = useTypedSelector((state) => state.firebase.auth);
  const profile = useTypedSelector((state) => state.firebase.profile);
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const livesBusiness = new LivesBusiness(firestore);
  const submitComment = async (name?: string) => {
    if (!content) {
      return;
    }
    if (auth.isEmpty) {
      error('Þú verður að skrá þig inn til að skrifa athugasemd.');
      return;
    }
    if (!profile.name && !name) {
      dispatch(
        showModal(ModalType.USERNAME_MODAL, { callback: submitComment })
      );
      return;
    }
    setIsLoading(true);
    try {
      await livesBusiness.postComment(
        liveEventId,
        {
          userName: name || profile.name,
          content,
        },
        auth.uid,
        podcast.id as string
      );
      setContent('');
    } catch (err: any) {
      error(err.message || 'Tókst ekki að vista athugasemd.');
    } finally {
      setIsLoading(false);
    }
  };

  const lastLiveStreamingStart = podcast.lastLiveStreamingStart;

  useFirestoreConnect({
    collection: 'liveeventscomments',
    where: lastLiveStreamingStart
      ? [
          ['liveEventId', '==', liveEventId],
          ['createdAt', '>', lastLiveStreamingStart],
        ]
      : [['liveEventId', '==', liveEventId]],
    storeAs: 'liveeventscomments-' + liveEventId,
    orderBy: ['createdAt', 'desc'],
    limit,
  });

  const comments = useTypedSelector(
    (state) => state.firestore.ordered['liveeventscomments-' + liveEventId]
  );

  return (
    <div className={s.container}>
      <PardusTextareaAutosize
        value={content}
        maxRows={30}
        minRows={1}
        disabled={isLoading}
        placeholder={'Skrifaðu athugasemd...'}
        onChange={(e) => setContent(e.target.value)}
        onKeyDown={async (e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            await submitComment();
          }
        }}
        gray={true}
      />
      <div
        className={classNames({
          [s.commentsList]: true,
          [s.empty]: isLoaded(comments) && !comments?.length,
        })}
      >
        {isLoaded(comments) && !comments?.length && (
          <div className={s.empty}>Engar athugasemdir ennþá.</div>
        )}
        {!isLoaded(comments) && <Spinner />}
        {isLoaded(comments) && !!comments?.length && (
          <>
            {comments.map((c: LiveEventComment) => (
              <LiveCommentItem key={c.id} comment={c} />
            ))}
            {limit <= comments?.length && (
              <div className={s.loadMoreBtn}>
                <Button
                  onClick={() => setLimit(limit + commentsLimit)}
                  className={'light-purple'}
                >
                  Hlaða fleiri
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
