import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import s from './ManageEpisodePlayer.module.css';

type Props = {
  url: string;
  title: string;
  createdAt?: string;
  reset: () => void;
}

export const ManageEpisodePlayer: React.FC<Props> = ({ title, url, reset }) => {
return <div className={s.container}>
  <div className={s.title}>{title}
    {url && <span className={s.edit} onClick={reset}>Eyða</span>}
  </div>
  <ReactAudioPlayer title={title} src={url} autoPlay={false} controls controlsList="nodownload" />
</div>
};
