import React, { useState } from 'react';
import s from './VimeoUrlModal.module.css';
import GeneralModal from './GeneralModal';
import { TextInput } from '../text-input/TextInput';
import { Button } from '../button/Button';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../redux/actions/modal';

type Props = {
  callback?: (url: string) => void;
};

export const VimeoUrlModal: React.FC<Props> = ({ callback }) => {
  const [url, setUrl] = useState<string>('');
  const dispatch = useDispatch();
  const save = async () => {
    if (!url) {
      return;
    }
    if (callback) {
      callback(url);
    }
    dispatch(hideModal());
  };
  return (
    <GeneralModal>
      <div className={s.container}>
        <div className={s.head}>Myndband á Vimeo</div>
        <div className={s.content}>Skrifaðu slóð að myndbandinu á Vimeo.</div>
        <div className={s.input}>
          <TextInput
            label={'Slóð að myndbandi'}
            placeholder={'https://vimeo.com'}
            onChange={(val) => setUrl(val)}
            autofocus
          />
        </div>
        <div className={s.action}>
          <Button disabled={!url} onClick={save}>
            Staðfesta
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};
