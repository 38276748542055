import React, { useEffect, useRef } from 'react';
import s from './GeneralModal.module.css';
import useOutsideClick from '../../utils/useOutsideClick';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ModalAction, ModalState } from '../../types';
import { hideModal } from '../../redux/actions/modal';
import clientConfig from '../../client-config';
import { VisirTheme } from '../../visir-components/visir-theme/VisirTheme';

type Props = {
  children: React.ReactNode;
  dispatch: ThunkDispatch<ModalState, void, ModalAction>;
  width?: number;
  disableOutsideClick?: boolean;
  withOverflow?: boolean;
};
const GeneralModal: React.FC<Props> = ({
  children,
  dispatch,
  width,
  disableOutsideClick,
  withOverflow,
}) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    if (!disableOutsideClick) {
      dispatch(hideModal());
    }
  });
  useEffect(() => {
    document.documentElement.classList.add('with-modal');
    return () => {
      document.documentElement.classList.remove('with-modal');
    };
  });
  const content = (
    <div className={s.overlay}>
      <div
        ref={ref}
        className={classNames(s.container, {
          [s.hideOverflow]: !withOverflow,
        })}
        style={{ maxWidth: width ? `${width}px` : 'auto' }}
      >
        {children}
      </div>
    </div>
  );
  return clientConfig.name === 'visir' ? (
    <VisirTheme>{content}</VisirTheme>
  ) : (
    <>{content}</>
  );
};

export default connect()(GeneralModal);
