import React, { useState } from 'react';
import { error, success } from '../utils/Notifications';
import { Redirect } from 'react-router';
import { useFirestore } from 'react-redux-firebase';
import SubscriptionBusiness from '../business/SubscriptionBusiness';
import withPodcast from '../utils/withPodcast';
import { SubscriptionForm } from '../components/subscription-form/SubscriptionForm';
import { Podcast, SubscriptionPlan } from '../types';
import { PageContainer } from '../components/page-container/PageContainer';

const ManagePodcastNewSubscription: React.FC<any> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');
  const firestore = useFirestore();
  const { podcast }: { podcast: Podcast } = props;
  const create = async (email: string, plan: SubscriptionPlan) => {
    setIsLoading(true);
    const subscriptionBusiness = new SubscriptionBusiness(firestore);
    try {
      await subscriptionBusiness.create(email, podcast.id as string, plan);
      setIsLoading(false);
      success('Áskriftin hefur verið stofnuð.');
      setRedirect('/podcasts/users/' + podcast.id);
    } catch (err: any) {
      error(err.message || 'Ekki tókst að stofna áskrift');
      setIsLoading(false);
    }
  };
  return (
    <PageContainer>
      {podcast.subscriptionPlans?.length && (
        <SubscriptionForm
          title={'Ný áskrift'}
          save={create}
          backRoute={'/podcasts/users/' + podcast.id}
          plans={podcast.subscriptionPlans}
          isLoading={isLoading}
        />
      )}
      {redirect && <Redirect to={redirect} />}
    </PageContainer>
  );
};

export default withPodcast(ManagePodcastNewSubscription);
