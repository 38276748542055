import React, { useState } from 'react';
import s from './LoginVisir.module.css';
import { TextInput } from '../../components/text-input/TextInput';
import { Button } from '../../components/button/Button';
import { useFirebase } from 'react-redux-firebase';
import { validateEmail } from '../../utils/utilities';
import { error } from '../../utils/Notifications';
import { useTypedSelector } from '../../redux/reducers';
import { Link, Redirect } from 'react-router-dom';
import { VisirTheme } from '../../visir-components/visir-theme/VisirTheme';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../components/form-fields/FormInput';
import { VisirHeader } from '../../visir-components/visir-header/VisirHeader';
import { PageContainer } from '../../components/page-container/PageContainer';
import clientConfig from '../../client-config';
import { Helmet } from 'react-helmet';
export const LoginVisir: React.FC<any> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const firebase = useFirebase();
  const auth = useTypedSelector((state) => state.firebase.auth);
  const login = async (email: string, password: string): Promise<any> => {
    setIsLoading(true);
    try {
      await firebase.login({
        email,
        password,
      });
    } catch (err: any) {
      error('Notandanafn og lykilorð passa ekki saman.');
      setIsLoading(false);
    }
  };
  const requiredMessage = 'Vinsamlegast fylltu út í þennan reit.';
  const schema = yup.object().shape({
    email: yup
      .string()
      .required(requiredMessage)
      .email('Netfangið er á vitlausu formi.'),
    password: yup.string().required(requiredMessage),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });
  return (
    <PageContainer>
      <Helmet>
        <title>Innskráning | {clientConfig.title}</title>
        <link rel={'canonical'} href={clientConfig.canonical + '/login'} />
      </Helmet>
      {auth.isLoaded && auth.isEmpty && (
        <div className={s.flex}>
          <div className={s.right}>
            <div className={s.loginContainer}>
              <div className={s.loginContent}>
                <h1>Innskráning</h1>
                <div>
                  Skráðu þig inn eða{' '}
                  <Link to={'/register'}>stofnaðu aðgang</Link> til að halda
                  áfram
                </div>
                <div className={s.inputs}>
                  <form
                    onSubmit={handleSubmit(
                      (d) => login(d.email, d.password),
                      (e) => console.log(e)
                    )}
                  >
                    <div className={s.input}>
                      <FormInput
                        error={errors.email?.message}
                        label={'Netfang'}
                        type="text"
                        placeholder="Skrifaðu netfangið þitt"
                        {...register('email')}
                      />
                    </div>
                    <div className={s.input}>
                      <FormInput
                        type="password"
                        error={errors.password?.message}
                        label={'Lykilorð'}
                        placeholder={'Lykilorð'}
                        {...register('password')}
                      />
                    </div>
                    <div className={s.actions}>
                      <Link to={'/forgot'} className={s.forgotLink}>
                        Gleymt lykilorð
                      </Link>
                      <Button type="submit" disabled={isLoading}>
                        {isLoading ? 'Hinkraðu...' : 'Skrá inn'}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {auth.isLoaded && !auth.isEmpty && (
        <Redirect to={props.location.state?.from || '/'} />
      )}
    </PageContainer>
  );
};
