import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { Redirect, useLocation } from 'react-router-dom';
import { PageContainer } from '../components/page-container/PageContainer';
import { PaymentForm } from '../components/payment-form/PaymentForm';
import { PodcastSubscriptionFrame } from '../components/podcast-subscription-frame/PodcastSubscriptionFrame';
import { ScrollToTop } from '../components/scroll-to-top/ScrollToTop';
import { CenteredSpinner } from '../components/spinner/CenteredSpinner';
import { useTypedSelector } from '../redux/reducers';
import { Podcast } from '../types';
import { VisirTheme } from '../visir-components/visir-theme/VisirTheme';
import clientConfig from '../client-config';
import { Helmet } from 'react-helmet';
import { TopNav } from '../components/top-nav/TopNav';
const PodcastPurchasePayment: FC<any> = (props) => {
  const {
    match: { params },
  } = props;
  const { podcastId } = params;
  const search = useLocation().search;
  const subscriptionId = new URLSearchParams(search).get('subscriptionId');
  const auth = useTypedSelector((state) => state.firebase.auth);
  useFirestoreConnect({
    collection: 'podcasts',
    doc: podcastId,
    storeAs: 'podcast-' + podcastId,
  });

  const data = useTypedSelector(
    (state) => state.firestore.ordered['podcast-' + podcastId]
  );

  const podcast: Podcast = data ? data[0] : null;

  if (isEmpty(podcast) || !isLoaded(podcast)) {
    return <CenteredSpinner />;
  }

  const subscriptionPlan = podcast.subscriptionPlans?.length
    ? podcast.subscriptionPlans[0]
    : null;
  if (!subscriptionPlan) {
    return null;
  }

  return (
    <VisirTheme>
      <Helmet>
        <link
          rel={'canonical'}
          href={clientConfig.canonical + '/podcasts/payment/' + podcast.id}
        />
      </Helmet>
      <ScrollToTop />
      <PageContainer>
        {clientConfig.name !== 'visir' ? <TopNav /> : null}
        <Container>
          <PodcastSubscriptionFrame
            podcast={podcast}
            subscriptionId={subscriptionId || undefined}
          >
            <h1 className={'tw-font-700'}>Kortaupplýsingar</h1>
            <PaymentForm
              subscriptionPlan={subscriptionPlan}
              podcastId={podcastId}
              subscriptionId={subscriptionId || undefined}
              slug={podcast.slug as string}
            />
          </PodcastSubscriptionFrame>
        </Container>
      </PageContainer>
      {auth.isLoaded && auth.isEmpty && (
        <Redirect to={'/podcasts/purchase/' + podcastId} />
      )}
    </VisirTheme>
  );
};
export default PodcastPurchasePayment;
