import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { Podcast } from '../../types';
import s from './PodcastCover.module.css';
import { Background } from 'react-imgix';
import cn from 'classnames';
type Props = {
  podcast: Podcast;
};
export const PodcastCover: FC<Props> = ({ podcast }) => {
  if (!podcast) {
    return null;
  }
  if (!podcast.cover) {
    return (
      <div className={cn(s.top)} style={{ backgroundColor: '#000000' }}>
        <div
          className={cn(
            s.topEmpty,
            'tw-container tw-mx-auto tw-px-6 tw-bg-black-500'
          )}
        >
          {!podcast?.hideTitleOnDetailPage && (
            <div className={s.title}>{podcast.name}</div>
          )}
        </div>
      </div>
    );
  }
  return (
    <Background
      className={s.top}
      src={podcast.cover}
      imgixParams={{ crop: 'faces', h: 400, dpr: 1 }}
    >
      <div
        className="tw-container tw-mx-auto tw-px-6"
        style={{ height: '100%' }}
      >
        {!podcast?.hideTitleOnDetailPage && (
          <div className={s.title}>{podcast.name}</div>
        )}
      </div>
    </Background>
  );
};
