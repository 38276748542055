import React, { useRef, useState } from 'react';
import s from './Dropdown.module.css';
import classNames from 'classnames';
import useOutsideClick from "../../utils/useOutsideClick";


type Props = {
  id?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  selected?: string;
  options: Array<any>;
  onChange: (k: any) => void;
};

export const Dropdown: React.FC<Props> = ({
  id,
  label,
  placeholder,
  className,
  disabled,
  selected,
  options,
  onChange
}) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => setIsActive(false));

  return (
    <div
      className={classNames({
        [s.dropdownContainer]: true,
        [s.active]: isActive,
        [s.disabled]: disabled,
        [className || '']: !!className
      })}
      id={id || ''}
    >
      <label className={s.label}>{label}</label>
      <div
        ref={ref}
        className={classNames({ [s.dropdown]: true, [s.selected]: !!selected })}
        onClick={() => setIsActive(!isActive)}
      >
        {selected ? selected : placeholder}
      </div>
      <div className={s.dropdownList}>
        {options &&
          options.map((o, index) => (
            <div key={index} onClick={() => onChange(o)} className={
              classNames({[s.option]: true, [s.optionSelected]: selected === o.name})}>
              {o.name}
            </div>
          ))}
      </div>
    </div>
  );
};
