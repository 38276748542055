import React, { useEffect, useState } from 'react';
import { call } from '../../utils/ApiManager';
import { Spinner } from '../spinner/Spinner';
import { Episode, Podcast } from '../../types';
import { EpisodesGroup } from './EpisodesGroup';
import { useTypedSelector } from '../../redux/reducers';

type Props = {
  podcast: Podcast;
  noCollapse?: boolean;
};
export const PodcastEpisodes: React.FC<Props> = ({ podcast, noCollapse }) => {
  const [isLoading, setIsLoading] = useState(false);
  const auth = useTypedSelector((state) => state.firebase.auth);
  const isLoggedIn = auth.isLoaded && !auth.isEmpty;
  const [data, setData] = useState<{
    episodes: Episode[];
    hasSubscription: boolean;
    openEpisodes: Episode[];
    timeLimit: string | null;
    timeLimitResetPrice?: number;
    subscriptionId?: string;
    uploadedOpenEpisodes: Episode[];
  }>({
    episodes: [],
    hasSubscription: false,
    openEpisodes: [],
    timeLimit: null,
    uploadedOpenEpisodes: [],
  });
  const loadEpisodes = async () => {
    setIsLoading(true);
    const episodes = await call(
      `/podcasts/${podcast.id}/episodes${noCollapse ? '' : `?limit=5`}`,
      true
    )();
    setData(episodes);
    setIsLoading(false);
  };

  useEffect(() => {
    loadEpisodes();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <>
      {!!data.episodes?.length && (
        <EpisodesGroup
          title={podcast.subscriptionPlans?.length ? 'Læstir þættir' : 'Þættir'}
          episodes={data.episodes}
          isLocked={!data.hasSubscription}
          timeLimit={data.timeLimit}
          timeLimitResetPrice={data.timeLimitResetPrice}
          isCollapsed={!noCollapse}
          podcast={podcast}
          subscriptionId={data.subscriptionId}
        />
      )}
      {!!data.uploadedOpenEpisodes?.length && (
        <EpisodesGroup
          title={'Opið efni'}
          episodes={data.uploadedOpenEpisodes}
          isLocked={false}
          isCollapsed={!noCollapse}
          podcast={podcast}
          embeddable
        />
      )}
      {!!data.openEpisodes?.length && (
        <EpisodesGroup
          title={'Opnir þættir'}
          episodes={data.openEpisodes}
          isLocked={false}
          isCollapsed={!noCollapse}
          podcast={podcast}
          embeddable
        />
      )}
    </>
  );
};
