import React, { FC, PropsWithChildren, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import cn from 'classnames';
import s from './PaymentForm.module.css';
//@ts-ignore
import { usePaymentInputs } from 'react-payment-inputs';
import { CC_ERROR_MESSAGES, formatMoney } from '../../utils/utilities';
import { FormInput } from '../form-fields/FormInput';
import { SubscriptionPlan } from '../../types';
import { Button } from '../button/Button';
import { ThreeDSecurePayment } from './ThreeDSecurePayment';
import { success, warn } from '../../utils/Notifications';
type Props = PropsWithChildren<{
  podcastId: string;
  slug: string;
  subscriptionPlan: SubscriptionPlan;
  subscriptionId?: string;
}>;

export const PaymentForm: FC<Props> = ({
  slug,
  podcastId,
  subscriptionPlan,
  subscriptionId,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs({ errorMessages: CC_ERROR_MESSAGES });
  const history = useHistory();
  const pay = async () => {
    if (isLoading || meta.error) {
      return;
    }
    setIsPaying(true);
  };

  return (
    <>
      {isPaying && (
        <ThreeDSecurePayment
          subscriptionId={subscriptionId}
          subscriptionPlan={subscriptionPlan}
          podcastId={podcastId}
          cardNumber={cardNumber}
          expiry={expiry}
          onSuccess={() => {
            setIsPaying(false);
            setIsLoading(false);
            if (!subscriptionId) {
              history.push('/' + slug);
            } else {
              success('Kortaupplýsingarnar hafa verið uppfærðar.');
              history.push('/subscriptions');
            }
          }}
          onError={(message) => {
            setIsPaying(false);
            setIsLoading(false);
            warn(message);
          }}
        />
      )}
      <div className={s.container}>
        {!!subscriptionId && (
          <div className={'tw-mb-8 tw--mt-2 tw-font-700'}>
            Engin rukkun verður framkvæmd af kortinu þínu núna. Uppfærðar
            kortaupplýsingar verða notaðar einungis til að endurnýja áskriftina
            þína næst þegar hún rennur út.
          </div>
        )}
        <FormInput
          {...getCardNumberProps({
            onChange: (e: any) => setCardNumber(e.target.value),
            value: cardNumber,
          })}
          error={
            meta.touchedInputs.cardNumber ? meta.erroredInputs.cardNumber : ''
          }
          placeholder={'Kortanúmer'}
          type="text"
          label="Kortanúmer"
        />
        <div className={s.input}>
          <FormInput
            {...getExpiryDateProps({
              onChange: (e: any) => setExpiry(e.target.value),
              value: expiry,
            })}
            error={
              meta.touchedInputs.expiryDate ? meta.erroredInputs.expiryDate : ''
            }
            placeholder={'MM/ÁÁ'}
            label="Gildistími"
            type="text"
          />
        </div>
        <div className={s.input}>
          <FormInput
            {...getCVCProps({
              onChange: (e: any) => setCvc(e.target.value),
              value: cvc,
            })}
            placeholder={'CVC'}
            error={meta.touchedInputs.cvc ? meta.erroredInputs.cvc : ''}
            label="CVC"
            type="text"
          />
        </div>
        <div className={cn(s.submit, { [s.submitRight]: !podcastId })}>
          {podcastId && !subscriptionId && (
            <NavLink to={'/' + slug}>Aftur á þáttasíðu</NavLink>
          )}
          {podcastId && subscriptionId && (
            <NavLink to={'/subscriptions'}>Til baka</NavLink>
          )}
          <Button
            type="submit"
            disabled={isLoading || !!meta.error}
            onClick={() => pay()}
          >
            {isLoading
              ? 'Hinkraðu...'
              : !subscriptionId
              ? 'Greiða ' + formatMoney(subscriptionPlan.price) + ' kr.'
              : 'Uppfæra kortaupplýsingar'}
          </Button>
        </div>
      </div>
    </>
  );
};
