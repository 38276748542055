import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { TopNav } from '../components/top-nav/TopNav';
import { User } from '../types';
import { useTypedSelector } from '../redux/reducers';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { Spinner } from '../components/spinner/Spinner';
import { UsersList } from '../components/users-list/UsersList';
import { PageContainer } from '../components/page-container/PageContainer';
import s from '../components/manage-subscriptions-list/ManageSubscriptionsList.module.css';
import { TextInput } from '../components/text-input/TextInput';
import { throttle } from 'throttle-debounce';
import { EmptyIllustration } from '../components/empty-illustration/EmptyIllustration';

const RegisteredUsers: React.FC<any> = () => {
  const [search, setSearch] = useState<string>('');
  useFirestoreConnect({
    collection: 'users',
    orderBy: ['createdAt', 'desc']
  });
  let users: User[] = useTypedSelector(state => state.firestore.ordered.users);
  const searchFunction = throttle(1000, val => setSearch(val));
  if (search?.length >= 3 && isLoaded(users)) {
    users = users.filter(u => u.email.toLowerCase().includes(search.toLowerCase()));
  } else {
    users = users?.slice(0, 20);
  }
  return (
    <PageContainer>
      <TopNav />
      <Container>
        <div className={s.search}>
          <TextInput
            label={'Leita'}
            placeholder={'Leita að netfangi...'}
            onChange={val => searchFunction(val)}
          />
        </div>
        {isLoaded(users) ? (
          !isEmpty(users) ? (
            <div style={{ margin: '20px 0' }}>
              <UsersList users={users} />
            </div>
          ) : (
            <EmptyIllustration message={'Ekkert fannst.'} />
          )
        ) : (
          <Spinner />
        )}
      </Container>
    </PageContainer>
  );
};

export default RegisteredUsers;
