import { ThunkDispatch } from 'redux-thunk';
import { Episode, PlayerAction, PlayerState, Podcast } from '../../types';
import { call } from '../../utils/ApiManager';
import { uuid } from '../../utils/utilities';

export const togglePlay = (episode?: Episode, podcast?: Podcast) => async (
  dispatch: ThunkDispatch<PlayerState, void, PlayerAction>,
  getState: () => any
) => {
  const isPlaying = !!getState().player?.isPlaying;
  dispatch({
    type: 'TOGGLE_PLAY',
    episode,
    podcast,
  });
  if (episode && !isPlaying) {
    const anonymousUserToken = localStorage?.getItem('userToken') || uuid();
    localStorage?.setItem('userToken', anonymousUserToken);
    await call(
      '/episodes/' + episode.id + '/play',
      true
    )({ anonymousUserToken });
  }
};

export const stopPlayer = () => (
  dispatch: ThunkDispatch<PlayerState, void, PlayerAction>
) => {
  dispatch({
    type: 'STOP',
  });
};
export const startPlayer = () => (
  dispatch: ThunkDispatch<PlayerState, void, PlayerAction>
) => {
  dispatch({
    type: 'START',
  });
};
export const hidePlayer = () => (
  dispatch: ThunkDispatch<PlayerState, void, PlayerAction>
) => {
  dispatch({ type: 'HIDE' });
};
