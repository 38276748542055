import React, { useState } from 'react';
import s from './PodcastForm.module.css';
import { Podcast, SubscriptionPlan } from '../../types';
import { Center } from '../position/Center';
import { TitleCard } from '../title-card/TitleCard';
import { TextInput } from '../text-input/TextInput';
import { Button } from '../button/Button';
import { makeid } from '../../utils/utilities';

type Props = {
  podcast?: Podcast;
  title: string;
  save: (podcast: Podcast) => void;
  isLoading: boolean;
};

const SubscriptionPlanItem: React.FC<{
  item: SubscriptionPlan;
  removePlan: () => void;
  newPlan: () => void;
  showPlus?: boolean;
  updatePlan: (plan: SubscriptionPlan) => void;
}> = ({ item, updatePlan, removePlan, newPlan, showPlus }) => {
  return (
    <div className={s.subscriptionPlanItem}>
      <TextInput
        value={item.months?.toString() || '0'}
        isNumeric={true}
        label={'Fjöldi mánaða'}
        placeholder={'Skrifaðu fjölda mánaða'}
        onChange={val => {
          updatePlan({ ...item, months: Number(val) });
        }}
        maxLength={2}
      />
      <TextInput
        value={item.price.toString()}
        isNumeric={true}
        label={'Verð í krónum'}
        placeholder={'Skrifaðu verð í krónum'}
        onChange={val => {
          updatePlan({ ...item, price: Number(val) });
        }}
      />
      <div className={s.itemActions}>
        <div className={s.delete} onClick={() => removePlan()} />
        {showPlus && <div className={s.add} onClick={() => newPlan()} />}
      </div>
    </div>
  );
};

export const PodcastForm: React.FC<Props> = ({
  podcast,
  title,
  save,
  isLoading
}) => {
  const [name, setName] = useState<string>(podcast?.name || '');
  const [plans, setPlans] = useState<SubscriptionPlan[]>(
    podcast?.subscriptionPlans?.length ? podcast.subscriptionPlans : []
  );
  const makeDefaultPlan = () => {
    return {
      id: makeid(5),
      months: 1,
      price: 0
    };
  };

  const validate = (): boolean => {
    if (!name) {
      return false;
    }
    let seen = new Set();
    let hasDuplicates = plans.some((obj: any) => {
      return seen.size === seen.add(obj.months).size;
    });
    return !hasDuplicates && !plans?.find(p => !p.months || !p.price);
  };
  const onSave = () => {
    const saveObj: any = { name, subscriptionPlans: plans };
    if (podcast?.id) {
      saveObj.id = podcast.id;
    }
    save(saveObj);
  };

  return (
    <Center>
      <TitleCard title={title}>
        <TextInput
          autofocus
          value={name}
          label={'Nafn'}
          placeholder={'Nafn hlaðvarps'}
          onChange={val => setName(val)}
        />
        <div
          style={{ marginTop: '15px', maxHeight: '500px', overflowY: 'scroll' }}
        >
          {plans.map((plan, index) => (
            <SubscriptionPlanItem
              key={plan.id}
              item={plan}
              removePlan={() => {
                setPlans(plans.filter(p => p.id !== plan.id));
              }}
              showPlus={index === plans?.length - 1}
              newPlan={() => {
                setPlans([...plans, makeDefaultPlan()]);
              }}
              updatePlan={plan => {
                const index = plans.findIndex(p => p.id === plan.id);
                const newPlans = [...plans];
                newPlans[index] = plan;
                setPlans(newPlans);
              }}
            />
          ))}
          {!plans?.length && (
            <div className={s.newPlan}>
              <span onClick={() => setPlans([makeDefaultPlan()])}>
                Ný áskriftarleið
              </span>
            </div>
          )}
        </div>
        <div className={s.actions}>
          <Button className={'gray'} to={'/podcasts'}>
            Til baka
          </Button>
          <Button disabled={isLoading || !validate()} onClick={onSave}>
            {isLoading ? 'Hinkraðu...' : 'Áfram'}
          </Button>
        </div>
      </TitleCard>
    </Center>
  );
};
