import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { FeedPreview } from '../../components/feed-preview/FeedPreview';
import { PageContainer } from '../../components/page-container/PageContainer';
import { PodcastCover } from '../../components/podcast-cover/PodcastCover';
import { PodcastDescription } from '../../components/podcast-description/PodcastDescription';
import { SubscriptionInfo } from '../../components/podcast-description/SubscriptionInfo';
import { PodcastFeed } from '../../components/podcast-feed/PodcastFeed';
import { Center } from '../../components/position/Center';
import { PostToFeed } from '../../components/post-to-feed/PostToFeed';
import { Spinner } from '../../components/spinner/Spinner';
import { useTypedSelector } from '../../redux/reducers';
import { Podcast } from '../../types';
import { call } from '../../utils/ApiManager';
import { PodcastEpisodes } from '../../components/podcast-episodes/PodcastEpisodes';
import s from './PodcastDetailVisir.module.css';
import clientConfig from '../../client-config';
import { LiveStreamBanner } from '../../components/livestream-banner/LiveStreamBanner';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import { VisirCookieBanner } from '../../visir-components/visir-cookie-banner/VisirCookieBanner';
import { PendingFeeds } from '../../components/pending-feeds/PendingFeeds';
import { Button } from '../../components/button/Button';
import { TallyPoll } from '../../components/tally-poll/TallyPoll';
import { RssContainer } from "../../components/podcast-description/RssContainer";

const PodcastDetailVisir: React.FC<any> = (props) => {
  const auth = useTypedSelector((state) => state.firebase.auth);
  const profile = useTypedSelector((state) => state.firebase.profile);
  const {
    match: { params },
  } = props;
  const { slug } = params;
  useFirestoreConnect({
    collection: 'podcasts',
    where: [
      ['slug', '==', slug],
      ['status', '==', 'PUBLISHED'],
      ['client', '==', clientConfig.name],
    ],
    storeAs: 'podcast-' + slug,
  });

  const data = useTypedSelector(
    (state) => state.firestore.ordered['podcast-' + slug]
  );
  const podcast: Podcast = data ? data[0] : null;
  const isLoggedIn = auth.isLoaded && !auth.isEmpty;

  const [isLoading, setIsLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const podcastId = podcast?.id || '';
  const checkForSubscription = async () => {
    setIsLoading(true);
    const res = await call('/podcasts/' + podcastId + '/checkaccess', true)();
    setHasSubscription(!!res?.hasAccess);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isEmpty(podcast) || !isLoaded(podcast)) {
      return;
    }
    if (isLoggedIn) {
      checkForSubscription();
    } else {
      setIsLoading(false);
    }
    if (podcast.slug) {
      localStorage.setItem('home-path', `/${podcast.slug}`);
    }
  }, [isLoggedIn, podcastId]);

  if (!auth.isLoaded || isLoading || !isLoaded(podcast) || !podcast) {
    return (
      <PageContainer>
        <Center>
          <Spinner />
        </Center>
      </PageContainer>
    );
  }
  const isAdmin = podcast.userId === auth?.uid || profile?.isAdmin;
  const isBehindPaywall = !!podcast.subscriptionPlans?.length;
  const showLeftColumn =
    !!podcast.subscriptionDescription || isBehindPaywall || isAdmin;
  return (
    <>
      {podcast.isLiveStreaming && (
        <LiveStreamBanner
          podcastId={podcastId}
          hasSubscription={
            hasSubscription || !podcast.subscriptionPlans?.length
          }
        />
      )}
      <PageContainer>
        <Helmet>
          <title>
            {podcast.name} | {clientConfig.title}
          </title>
          <link
            rel={'canonical'}
            href={clientConfig.canonical + '/' + podcast.slug}
          />
          <meta name="description" content={podcast.description} />
        </Helmet>
        <PodcastCover podcast={podcast} />
        <div className="tw-container tw-px-6 tw-pb-6 tw-mx-auto">
          <div
            className={cn(
              'tw-block',
              'lg:tw-grid',
              'tw-grid-cols-1',
              'tw-gap-10',
              {
                ['lg:tw-grid-cols-4']: showLeftColumn,
                ['lg:tw-grid-cols-3']: !showLeftColumn,
              }
            )}
          >
            <div className={cn({ ['tw-hidden']: !showLeftColumn })}>
              {showLeftColumn && (
                <SubscriptionInfo
                  podcast={podcast}
                  hasSubscription={hasSubscription}
                />
              )}
            </div>
            <div className={cn('lg:tw-col-span-2')}>
              {hasSubscription && podcast.eventButton?.visible && (
                <div>
                  <a
                    href={podcast.eventButton.url}
                    className={cn(
                      s.eventBtn,
                      'tw-mx-auto',
                      'tw--mb-2',
                      'tw-bg-red-500',
                      'tw-text-white',
                      'tw-block',
                      'tw-text-center',
                      'tw-p-3',
                      'hover:tw-bg-red-600',
                      'tw-border',
                      'tw-border-red-600',
                      'tw-rounded-t-md',
                      'tw-font-700',
                      'tw-uppercase'
                    )}
                  >
                    <span className="tw-text-white tw-underline">
                      {podcast.eventButton.text}
                    </span>
                  </a>
                </div>
              )}
              {hasSubscription && isBehindPaywall && <RssContainer />}
              {isAdmin ? (
                <>
                  <PostToFeed podcastId={podcastId} userId={auth.uid} />
                  <PendingFeeds podcastId={podcastId} />
                </>
              ) : (
                <PodcastDescription podcast={podcast} />
              )}
              {hasSubscription &&
                podcast.tallyPoll?.id &&
                podcast.tallyPoll.visible && (
                  <TallyPoll
                    id={podcast.tallyPoll.id}
                    title={podcast.tallyPoll.title}
                  />
                )}
              <div className={cn('tw-block', 'lg:tw-hidden')}>
                <div className={cn(s.episodesContainer)}>
                  <PodcastEpisodes podcast={podcast} />
                </div>
              </div>
              {hasSubscription || !podcast.subscriptionPlans?.length ? (
                <>
                  <PodcastFeed podcastId={podcastId} slug={slug} />
                </>
              ) : !podcast.expiration ? (
                <FeedPreview podcastId={podcastId} slug={slug} />
              ) : null}
            </div>
            <div className={cn('tw-hidden', 'lg:tw-block')}>
              <div className={cn(s.episodesContainer)}>
                <PodcastEpisodes podcast={podcast} />
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  );
};

export default PodcastDetailVisir;
