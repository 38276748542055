import React from 'react';
import s from './LockedFeed.module.css';

type Props = {
  count: number;
};

export const LockedFeed: React.FC<Props> = ({ count }) => {
  return (
    <div className={s.container}>
      <strong>
        {count} {count === 1 ? 'færsla' : 'færslur'}
      </strong>{' '}
      {count === 1 ? 'er aðeins sýnileg' : 'eru aðeins sýnilegar'} fyrir
      áskrifendur.
      <div className={s.image}>
        <img src={'/img/feed-locked.svg'} alt={''} />
      </div>
    </div>
  );
};
