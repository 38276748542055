import React from 'react';
import { Container } from 'react-bootstrap';
import s from './CategoriesList.module.css';
import { PodcastCategory } from '../../types';
import classNames from 'classnames';
import { Dropdown } from '../dropdown/Dropdown';
type Props = {
  categories: PodcastCategory[];
  selectedCategory: string;
  setSelectedCategory: (cat: string) => void;
  podcastsCount: number;
};

type CategoryItemProps = {
  active: boolean;
  item: PodcastCategory;
  onClick: () => void;
};

const CategoryItem: React.FC<CategoryItemProps> = ({
  item,
  active,
  onClick
}) => {
  return (
    <span
      className={classNames({ [s.categoryItem]: true, [s.active]: active, [s.christmas]: item.title === 'Jólakveðjur' })}
      onClick={onClick}
    >
      {item.title} {item.title !== 'Jólakveðjur' && <span className={s.count}>({item.count})</span>}
    </span>
  );
};

export const CategoriesList: React.FC<Props> = ({
  categories,
  selectedCategory,
  setSelectedCategory,
  podcastsCount
}) => {
  const categoryItems = [
    {
      active: !selectedCategory,
      item: { title: 'Allt', count: podcastsCount },
      onClick: () => setSelectedCategory('')
    },
    ...categories.map(cat => ({
      item: cat,
      active: cat.title === selectedCategory,
      onClick: () => setSelectedCategory(cat.title)
    }))
  ];
  if (!categories?.length) {
    return null;
  }
  return (
    <div className={s.container}>
      <Container>
        <div className={s.hideMobile}>
          {categoryItems.map(i => (
            <CategoryItem key={i.item.title} {...i} />
          ))}
        </div>
        <div className={s.showMobile}>
          <Dropdown
            selected={selectedCategory || 'Allt (' + podcastsCount + ')'}
            options={categoryItems.map(cat => ({
              name: `${cat.item.title} (${cat.item.count})`,
              value: cat.item.title
            }))}
            onChange={val =>
              setSelectedCategory(val.value === 'Allt' ? '' : val.value)
            }
          />
        </div>
      </Container>
    </div>
  );
};
