import React, { useState } from 'react';
import s from './SubscriptionForm.module.css';
import { Center } from '../position/Center';
import { TitleCard } from '../title-card/TitleCard';
import { TextInput } from '../text-input/TextInput';
import { Button } from '../button/Button';
import { Dropdown } from '../dropdown/Dropdown';
import { SubscriptionPlan } from '../../types';

type Props = {
  title: string;
  save: (email: string, plan: SubscriptionPlan) => void;
  isLoading: boolean;
  backRoute: string;
  plans: SubscriptionPlan[];
};

export const SubscriptionForm: React.FC<Props> = ({
  title,
  save,
  backRoute,
  isLoading,
  plans
}) => {
  const [email, setEmail] = useState<string>('');
  const [
    subscriptionPlan,
    setSubscriptionPlan
  ] = useState<SubscriptionPlan | null>(plans.length ? plans[0] : null);
  const onSave = () => {
    if (!subscriptionPlan) {
      console.error('Áskriftarleið vantar.');
      return;
    }
    save(email, subscriptionPlan);
  };

  const getPlanName = (subscriptionPlan?: SubscriptionPlan | null): string => {
    if (!subscriptionPlan) {
      return '';
    }
    if (subscriptionPlan.days) {
      return subscriptionPlan.days === 1 ? '1 dagur' : (subscriptionPlan.days + ' dagar');
    }
    return subscriptionPlan.months === 1 ? '1 mánuður' : (subscriptionPlan.months + ' mánuðir');
  }
  return (
    <Center>
      <TitleCard title={title}>
        <TextInput
          autofocus
          value={email}
          label={'Netfang'}
          placeholder={'Skrifaðu netfang notanda'}
          onEnter={onSave}
          onChange={val => setEmail(val)}
        />
        {plans?.length && (
          <Dropdown
            selected={getPlanName(subscriptionPlan)}
            options={plans.map(plan => ({
              name: getPlanName(plan),
              value: plan
            }))}
            onChange={val => setSubscriptionPlan(val.value)}
          />
        )}
        <div className={s.actions}>
          <Button className={'gray'} to={backRoute}>
            Til baka
          </Button>
          <Button
            disabled={isLoading || !email || !subscriptionPlan}
            onClick={onSave}
          >
            {isLoading ? 'Hinkraðu...' : 'Áfram'}
          </Button>
        </div>
      </TitleCard>
    </Center>
  );
};
