import React from 'react';
import s from './PrivacyModal.module.css';
import GeneralModal from './GeneralModal';
import clientConfig from '../../client-config';
import { VisirPrivacy } from '../../visir-components/visir-privacy/VisirPrivacy';
import { Button } from '../button/Button';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../redux/actions/modal';

type Props = {};
export const PrivacyModal: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();
  if (clientConfig.name === 'visir') {
    return (
      <GeneralModal>
        <div className={s.container}>
          <VisirPrivacy />
          <div className={s.actions}>
            <Button onClick={() => dispatch(hideModal())}>Loka</Button>
          </div>
        </div>
      </GeneralModal>
    );
  }
  return null;
};
