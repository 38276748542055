import React, { useState } from 'react';
import s from './PardusRss.module.css';
import cn from 'classnames';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useTypedSelector } from '../../redux/reducers';
import { ModalType, Podcast, RssLink, Subscription } from '../../types';
import { Spinner } from '../spinner/Spinner';
import { Link } from 'react-router-dom';
import { ContextMenu } from '../context-menu/ContextMenu';
import { EmptyIllustration } from '../empty-illustration/EmptyIllustration';
import { SimpleTable } from '../simple-table/SimpleTable';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modal';
import clientConfig from '../../client-config';
import { success } from '../../utils/Notifications';
import { copyTextToClipboard } from '../../utils/utilities';
import { getDeepLinkForProvider, supportsDeepLink } from './deep-link';
import { useWindowSize } from '../../hooks/use-window.size';

type Props = {};

export const PardusRss: React.FC<Props> = () => {
  const profile = useTypedSelector<any>((state) => state.firebase.profile);
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  useFirestoreConnect({
    collection: 'podcastsubscriptions',
    orderBy: ['createdAt', 'desc'],
    where: [['userId', '==', profile.userId]],
  });
  let podcastsubscriptions: Subscription[] = useTypedSelector(
    (state) => state.firestore.ordered.podcastsubscriptions,
  );
  useFirestoreConnect({
    collection: 'podcasts',
    orderBy: ['createdAt', 'desc'],
    where: [
      ['active', '==', true],
      ['status', '==', 'PUBLISHED'],
    ],
  });
  useFirestoreConnect({
    collection: 'podcasts',
    orderBy: ['createdAt', 'desc'],
    where: [
      ['active', '==', true],
      ['userId', '==', profile.userId],
    ],
    storeAs: 'ownpodcasts',
  });
  useFirestoreConnect({
    collection: 'rsslinks',
    where: ['userId', '==', profile.userId],
  });
  let ownPodcasts = useTypedSelector(
    (state) => state.firestore.ordered.ownpodcasts,
  );
  let podcasts = useTypedSelector((state) => state.firestore.ordered.podcasts);
  let rsslinks: RssLink[] = useTypedSelector(
    (state) => state.firestore.ordered.rsslinks,
  );
  let filteredPodcasts: Podcast[] = [];
  if (
    podcasts?.length &&
    (podcastsubscriptions?.length || ownPodcasts?.length)
  ) {
    const subscriptionIds = [
      ...(podcastsubscriptions?.map((s) => s.podcastId) || []),
      ...(ownPodcasts?.map((p: Podcast) => p.id) || []),
    ];
    filteredPodcasts = podcasts.filter((p: Podcast) =>
      subscriptionIds.includes(p.id as string),
    );
  }
  const isMobile = width < 640;
  const cols = isMobile
    ? ['Hlaðvarp', 'RSS Hlekkur', '']
    : ['Hlaðvarp', 'RSS Hlekkur', 'Staða', 'Síðast notað', 'Forrit', ''];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const generateLink = async (podcast: Podcast) => {
    if (!podcast || isLoading) {
      return;
    }
    dispatch(
      showModal(ModalType.CREATE_RSS_LINK, {
        podcast,
      }),
    );
  };
  // create subscription if the user owns the podcast
  const ownPodcastsSubscriptions =
    ownPodcasts?.map((p: Podcast) => ({
      podcastId: p.id,
      endsAt: moment().add(1, 'year'),
    })) || [];
  const iterablePodcastSubscriptions = podcastsubscriptions || [];
  const rows = [...iterablePodcastSubscriptions, ...ownPodcastsSubscriptions]
    ?.filter((sub: Subscription) => {
      const podcast = filteredPodcasts.find(
        (p: Podcast) => p.id === sub.podcastId,
      );
      return !!podcast && moment(sub.endsAt.toDate()).isAfter(moment());
    })
    .filter((sub) => {
      if (clientConfig.name === 'main') {
        return !sub.client || sub.client === 'main';
      }
      return sub.client === clientConfig.name;
    })
    .map((sub) => {
      const podcast = filteredPodcasts.find(
        (p: Podcast) => p.id === sub.podcastId,
      );
      if (!podcast) {
        return [];
      }
      const rssLink = rsslinks?.find((l) => l.podcastId === sub.podcastId);
      const ret: any = [
        <Link className={s.link} to={`/${podcast.slug}`}>
          {podcast.name}
        </Link>,
        rssLink?.link ? (
          <>
            {supportsDeepLink(rssLink.selectedProvider) && (
              <a
                href={getDeepLinkForProvider(
                  rssLink.selectedProvider!,
                  rssLink.link,
                )}
                className={cn(
                  'tw-underline tw-text-purple-500 hover:tw-text-purple-500',
                )}
              >
                Tengja áskrift
              </a>
            )}
            {!supportsDeepLink(rssLink.selectedProvider) && (
              <button
                onClick={() => {
                  if (rssLink?.link) {
                    copyTextToClipboard(rssLink?.link);
                    success('Hlekkurinn hefur verið afritaður.');
                  }
                }}
                className={cn(
                  'tw-underline',
                  'tw-text-purple-500',
                  'hover:tw-text-purple-500',
                )}
              >
                Afrita hlekk
              </button>
            )}
          </>
        ) : (
          <span className={s.link} onClick={() => generateLink(podcast)}>
            Sækja nýjan hlekk
          </span>
        ),
      ];
      if (!isMobile) {
        ret.push(
          rssLink?.active ? 'Opið' : 'Lokað',
          rssLink?.lastUsed
            ? moment(rssLink.lastUsed.toDate()).format('DD. MMM YYYY')
            : 'Aldrei',
          rssLink?.selectedProvider || '-',
        );
      }
      ret.push(
        <div style={{ textAlign: 'right', paddingTop: '8px' }}>
          <ContextMenu minWidth={180}>
            <div onClick={() => generateLink(podcast)}>Sækja nýjan hlekk</div>
          </ContextMenu>
        </div>,
      );
      return ret;
    });
  return isLoaded(podcasts) &&
    isLoaded(ownPodcasts) &&
    isLoaded(podcastsubscriptions) ? (
    <div className={s.container}>
      <h1>RSS hlekkir</h1>
      {clientConfig.name === 'visir' && (
        <div className={'tw-mb-6'}>
          RSS hlekkir eru sérstaklega tengdir við þinn aðgang og gera þér kleift
          að hlusta á áskriftarþættina í nánast hvaða appi sem er (fyrir utan
          Spotify, því það styður ekki RSS ennþá).{' '}
          {!!rows?.length && 'Fylgdu leiðbeiningunum hér að neðan.'}
        </div>
      )}
      {!!rows?.length ? (
        <>
          <SimpleTable cols={cols} rows={rows} noScroll={isMobile} />

          <div className={'tw-mt-6'}>
            <div className={'tw-font-800 tw-text-lg'}>
              Hvernig fæ ég áskriftina í iPhone?
            </div>
            <div className={'tw-mt-2'}>
              1. Skráðu þig inn á{' '}
              {clientConfig.name === 'visir' ? 'TAL.is' : 'pardus.is'}
              <br />
              2. Ýttu á ,,RSS hlekkir" í aðalvalmyndinni
              <br />
              3. Smelltu á ,,Sækja nýjan hlekk"
              <br />
              4. Veldu Apple Podcasts og appið opnast sjálfkrafa
              <br />
              5. Smelltu á ,,Subscribe"
            </div>
            <div className={'tw-font-800 tw-text-lg tw-mt-4'}>
              Hvernig fæ ég áskriftina í Android?
            </div>
            <div className={'tw-mt-2'}>
              1. Skráðu þig inn á{' '}
              {clientConfig.name === 'visir' ? 'TAL.is' : 'pardus.is'}
              <br />
              2. Smelltu á ,,RSS hlekkir" í aðalvalmyndinni
              <br />
              3. Smelltu á ,,Sækja nýjan hlekk"
              <br />
              4. Veldu app og afritaðu hlekkinn
              <br />
              5. Settu hlekkinn í hlaðvarpsappið þitt
              <br />
            </div>
            <div className={'tw-space-y-4 tw-my-4'}>
              <div>
                Það er mismunandi eftir öppum hvar hlekkurinn er settur inn, en
                yfirleitt stendur eitthvað eins og ,,Add Show by URL".
              </div>
              <div>
                Þegar þetta er búið munu hljóðáskriftarþættir birtast sjálfkrafa
                í appinu.
              </div>
            </div>
          </div>
        </>
      ) : (
        <EmptyIllustration
          message={'Þú hefur ekki keypt neina áskrift ennþá.'}
        />
      )}
    </div>
  ) : (
    <Spinner />
  );
};
