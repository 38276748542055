import React from 'react';
import s from './MySubscriptionList.module.css';
import { ModalType, Podcast, Subscription } from '../../types';
import { Link } from 'react-router-dom';
import { ContextMenu } from '../context-menu/ContextMenu';
import { showModal } from '../../redux/actions/modal';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { SimpleTable } from '../simple-table/SimpleTable';
import { useFirestore } from 'react-redux-firebase';
import SubscriptionBusiness from '../../business/SubscriptionBusiness';
import { formatMoney } from '../../utils/utilities';

type Props = {
  subscriptions: Subscription[];
  podcasts: Podcast[];
};

export const MySubscriptionsList: React.FC<Props> = ({
  subscriptions,
  podcasts,
}) => {
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const isAutomaticRenewal = (sub: Subscription) => {
    if (!sub.recurringToken && !sub.borgunToken) {
      return false;
    }
    return !sub.preventRenewal;
  };
  const cols = [
    'Hlaðvarp',
    'Verð',
    'Stofnað',
    'Áskrift lýkur',
    'Síðast endurnýjað',
    'Sjálfvirk endurnýjun',
    'Kort',
    '',
  ];

  const deactivateSubscription = async (sub: Subscription) => {
    const subscriptionBusiness: SubscriptionBusiness = new SubscriptionBusiness(
      firestore
    );
    await subscriptionBusiness.stopSubscription(sub.id as string);
  };

  const activateSubscription = async (sub: Subscription) => {
    const subscriptionBusiness: SubscriptionBusiness = new SubscriptionBusiness(
      firestore
    );
    await subscriptionBusiness.activateSubscription(sub.id as string);
  };

  const toggleNotifications = async (sub: Subscription) => {
    const subscriptionBusiness: SubscriptionBusiness = new SubscriptionBusiness(
      firestore
    );
    await subscriptionBusiness.toggleNotifications(sub.id as string);
  };

  const rows = subscriptions
    .filter((sub: Subscription) => {
      const podcast = podcasts.find((p) => p.id === sub.podcastId);
      return !!podcast;
    })
    .map((sub) => {
      const podcast = podcasts.find((p) => p.id === sub.podcastId);
      if (!podcast) {
        return [];
      }
      const isExpired = moment(sub.endsAt.toDate()).isBefore(moment());
      const ret: any = [
        <Link className={s.link} to={`/${podcast.slug}`}>
          {podcast.name}
        </Link>,
        `${formatMoney(sub.subscriptionPlan.price)} kr.`,
        moment(sub.createdAt.toDate()).format('DD. MMMM YYYY'),
        !isExpired
          ? moment(sub.endsAt.toDate()).format('DD. MMMM YYYY')
          : 'Útrunnin',
        sub.lastRenewal
          ? moment(sub.lastRenewal.toDate()).format('DD. MMMM YYYY')
          : 'Aldrei',
        isAutomaticRenewal(sub) ? 'Já' : 'Nei',
        <>
          {sub?.borgunToken?.PAN.slice(-4)}
          {!isExpired && (
            <>
              {sub?.borgunToken?.PAN ? ' - ' : ''}
              <Link
                to={`/podcasts/payment/${sub.podcastId}?subscriptionId=${sub.id}`}
                className={'tw-underline'}
              >
                Uppfæra kort
              </Link>
            </>
          )}
        </>,
      ];
      const actions = (
        <div style={{ textAlign: 'right', paddingTop: '8px' }}>
          {!podcast.oneTimePurchase && (
            <ContextMenu minWidth={220}>
              {isAutomaticRenewal(sub) ? (
                <div
                  className={s.delete}
                  onClick={() =>
                    dispatch(
                      showModal(ModalType.CONFIRM_MODAL, {
                        title: 'Hætta í áskrift',
                        actionText: 'Staðfesta',
                        action: () => deactivateSubscription(sub),
                        body: 'Ertu viss um að þú viljir hætta í áskrift?',
                      })
                    )
                  }
                >
                  Hætta í áskrift
                </div>
              ) : (
                <div onClick={() => activateSubscription(sub)}>
                  Virkja áskrift
                </div>
              )}
              <div onClick={() => toggleNotifications(sub)}>
                {sub.disableCreatorNotifications
                  ? 'Virkja tilkynningar'
                  : 'Slökkva á tilkynningum'}
              </div>
            </ContextMenu>
          )}
        </div>
      );
      ret.push(
        (sub.recurringToken || sub.borgunToken) && !isExpired ? actions : ''
      );
      return ret;
    });
  return <>{!!podcasts.length && <SimpleTable cols={cols} rows={rows} />}</>;
};
