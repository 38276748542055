import React, { PropsWithChildren, useState } from 'react';
import GeneralModal from './GeneralModal';
import { css } from 'styled-components';
import s from './PaymentModal.module.css';
import { Podcast, SubscriptionPlan } from '../../types';
//@ts-ignore
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
//@ts-ignore
import images from 'react-payment-inputs/images';
import { Button } from '../button/Button';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../redux/actions/modal';
import { CC_ERROR_MESSAGES, formatMoney } from '../../utils/utilities';
import { call } from '../../utils/ApiManager';
import { warn } from '../../utils/Notifications';
import { useWindowSize } from '../../hooks/use-window.size';

export type PaymentModalProps = {
  podcast: Podcast;
  subscriptionPlan: SubscriptionPlan;
  title: string;
};
const PaymentWrapper = ({
  wrapperProps,
  getCardImageProps,
  children,
}: PropsWithChildren<{
  wrapperProps?: any;
  getCardImageProps?: any;
}>) => {
  const [width] = useWindowSize();
  if (width > 580) {
    return (
      <PaymentInputsWrapper
        {...wrapperProps}
        styles={{
          inputWrapper: {
            focused: css`
              border-color: unset;
              box-shadow: unset;
              outline: none;
              outline-offset: 2px;
            `,
          },
          input: {
            cardNumber: css`
              width: 15rem;
            `,
            expiryDate: css`
              width: 10rem;
            `,
            cvc: css`
              width: 5rem;
            `,
          },
        }}
      >
        <svg {...getCardImageProps({ images })} />
        {children}
      </PaymentInputsWrapper>
    );
  }
  return <div className={s.mobilePaymentWrapper}>{children}</div>;
};
const BasicModal: React.FC<PaymentModalProps> = ({
  podcast,
  subscriptionPlan,
  title,
}) => {
  const {
    meta,
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({ errorMessages: CC_ERROR_MESSAGES });
  const dispatch = useDispatch();
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [threeDForm, setThreeDForm] = useState('');
  const enrollThreeDSecure = async () => {
    setIsLoading(true);
    const enrollment = await call(`/payment/enroll`)({
      pan: cardNumber.replace(/\s/g, ''),
      expMonth: expiry.split('/')[0].trim(),
      expYear: expiry.split('/')[1].trim(),
      price: subscriptionPlan.price,
    });
    setIsLoading(false);
    if (enrollment.MdStatus === '9' && enrollment.RedirectToACSForm) {
      const form = enrollment.RedirectToACSForm;
      const threeDSecureCallback = (message: any) => {
        setThreeDForm('');
        if (message.data === true) {
          makePayment(enrollment.MPIToken);
        } else {
          warn('Greiðsla tókst ekki.');
        }
        window.removeEventListener('message', threeDSecureCallback);
      };
      setThreeDForm('data:text/html;charset=utf-8,' + encodeURI(form));
      window.addEventListener('message', threeDSecureCallback);
    } else if (enrollment.MdStatus === '8' || enrollment.MdStatus === '0') {
      warn('Greiðsla tókst ekki.');
    } else {
      await makePayment(enrollment.MPIToken);
    }
  };
  const makePayment = async (mpiToken: string) => {
    setIsLoading(true);
    try {
      await call(`/payment/purchase-subscription`)({
        podcastId: podcast.id,
        planId: subscriptionPlan.id,
        pan: cardNumber.replace(/\s/g, ''),
        expMonth: expiry.split('/')[0].trim(),
        expYear: expiry.split('/')[1].trim(),
        price: subscriptionPlan.price,
        mpiToken,
      });
      window.location.reload();
      dispatch(hideModal());
    } catch (err: any) {
      warn(err.message || 'Greiðsla tókst ekki');
    } finally {
      setIsLoading(false);
    }
  };
  const [width] = useWindowSize();
  return (
    <GeneralModal disableOutsideClick>
      {threeDForm && (
        <iframe
          id={'threeDSecureIframe'}
          className={s.threeDForm}
          src={threeDForm}
        />
      )}
      {!threeDForm && (
        <div className={s.payment}>
          <div className={s.title}>{title}</div>
          <div className={s.description}>
            Sláðu inn kortaupplýsingarnar þínar til að halda áfram
          </div>
          <PaymentWrapper
            wrapperProps={wrapperProps}
            getCardImageProps={getCardImageProps}
          >
            <input
              {...getCardNumberProps({
                onChange: (e: any) => setCardNumber(e.target.value),
                value: cardNumber,
              })}
              placeholder={'Kortanúmer'}
            />
            <input
              {...getExpiryDateProps({
                onChange: (e: any) => setExpiry(e.target.value),
                value: expiry,
              })}
              placeholder={'MM/ÁÁ'}
            />
            <input
              {...getCVCProps({
                onChange: (e: any) => setCvc(e.target.value),
                value: cvc,
              })}
            />
          </PaymentWrapper>
          <div className={s.actions}>
            <Button className={'gray'} onClick={() => dispatch(hideModal())}>
              Hætta við
            </Button>
            <Button
              className={'green'}
              onClick={() => {
                if (!meta.error) {
                  enrollThreeDSecure();
                }
              }}
              disabled={!!meta.error || isLoading}
            >
              {isLoading
                ? 'Hinkraðu...'
                : `Greiða ${formatMoney(subscriptionPlan.price)} kr.`}
            </Button>
          </div>
        </div>
      )}
    </GeneralModal>
  );
};

export default BasicModal;
