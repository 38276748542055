import React from 'react';
import s from './MaterialIcon.module.css';
import classNames from 'classnames';
type Props = {
  onClick: () => void;
  icon: string;
  disabled?: boolean;
};

export const MaterialIcon: React.FC<Props> = ({
  onClick,
  icon,
  disabled,
}) => {
  return (
    <span
      className={classNames({ [s.container]: true, [s.disabled]: disabled })}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <img src={icon} alt={''} />
    </span>
  );
};
