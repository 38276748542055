import React from 'react';
import s from './PodcastNav.module.css';
import { NavLink } from 'react-router-dom';
import className from 'classnames';
import { ManagePodcastTab } from '../../types';
import { useTypedSelector } from '../../redux/reducers';

type Props = {
  podcastId: string;
  active: ManagePodcastTab;
};

export const PodcastNav: React.FC<Props> = ({ podcastId, active }) => {
  const profile = useTypedSelector((state) => state.firebase.profile);
  return (
    <div className={s.manageCampaignNav}>
      <div className={s.dash} />
      <NavLink
        to={'/podcasts/manage/' + podcastId}
        className={className({
          [s.active]: active === ManagePodcastTab.GENERAL,
        })}
      >
        Upplýsingar
      </NavLink>
      <NavLink
        to={'/podcasts/uploads/' + podcastId}
        className={className({
          [s.active]: active === ManagePodcastTab.UPLOADS,
        })}
      >
        Þættir
      </NavLink>
      <NavLink
        to={'/podcasts/analytics/' + podcastId}
        className={className({
          [s.active]: active === ManagePodcastTab.ANALYTICS,
        })}
      >
        Tölfræði
      </NavLink>
      <NavLink
        to={'/podcasts/live/' + podcastId}
        className={className({
          [s.active]: active === ManagePodcastTab.LIVE,
        })}
      >
        Bein útsending
      </NavLink>
      <NavLink
        to={'/podcasts/email/' + podcastId}
        className={className({
          [s.active]: active === ManagePodcastTab.EMAIL,
        })}
      >
        Tilkynningar
      </NavLink>
      {profile.isAdmin && (
        <NavLink
          to={'/podcasts/users/' + podcastId}
          className={className({
            [s.active]: active === ManagePodcastTab.USERS,
          })}
        >
          Áskriftir
        </NavLink>
      )}
    </div>
  );
};
