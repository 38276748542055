import React from 'react';
import s from './PodcastItem.module.css';
import { Podcast } from '../../types';
import { Button } from '../button/Button';
import { LivestreamLabel } from '../livestream-label/LivestreamLabel';
import { Link } from 'react-router-dom';

type Props = {
  podcast: Podcast;
};

export const PodcastItem: React.FC<Props> = ({ podcast }) => {
  const style = {
    backgroundImage: 'url(' + podcast.image + ')',
  };
  return (
    <div className={s.container}>
      <Link to={'/' + podcast.slug}>
        <div className={s.image} style={style} />
      </Link>
      <div className={s.info}>
        {podcast.isLiveStreaming && (
          <LivestreamLabel podcastId={podcast.id as string} />
        )}
        <Link to={'/' + podcast.slug} className={s.title}>
          {podcast.name}
        </Link>
        <div className={s.description}>{podcast.shortDescription}</div>
        <div className={s.actions}>
          <Button className={'light-purple'} to={`/${podcast.slug}`}>
            Skoða
          </Button>
        </div>
      </div>
    </div>
  );
};
