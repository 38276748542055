import './visir.css';
import s from './VisirTheme.module.css';
import React, { FC, PropsWithChildren } from 'react';
import cn from 'classnames';
import clientConfig from '../../client-config';

export const VisirTheme = ({ children }: PropsWithChildren<{}>) => {
  if (clientConfig.name !== 'visir') {
    return <>{children}</>;
  }
  return <div className={cn(['visir-container', s.container])}>{children}</div>;
};
