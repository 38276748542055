import React, { useState } from 'react';
import s from './DateTimePickerInput.module.css';
//@ts-ignore
import DateTimePicker from 'react-datetime-picker';

type Props = {
  label: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
};

export const DateTimePickerInput: React.FC<Props> = ({ label, value, onChange }) => {
  return (
    <div className={s.picker}>
      {label && <label>{label}</label>}
      <div className={s.pickerContainer}>
        <DateTimePicker value={value} onChange={onChange} />
      </div>
    </div>
  );
};
