import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import s from './RegisterForm.module.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormInput } from '../form-fields/FormInput';
import { FormCheckbox } from '../form-fields/FormCheckbox';
import cn from 'classnames';
import { Button } from '../button/Button';
import { call } from '../../utils/ApiManager';
import { useFirebase } from 'react-redux-firebase';
import { error } from '../../utils/Notifications';
import { showModal } from '../../redux/actions/modal';
import { ModalType } from '../../types';
import { useDispatch } from 'react-redux';
import clientConfig from '../../client-config';
type Props = PropsWithChildren<{
  podcastId?: string;
  slug?: string;
}>;
export const RegisterForm: FC<Props> = ({ podcastId, slug, children }) => {
  const firebase = useFirebase();
  const [isLoading, setIsLoading] = useState(false);
  const onSave = async (
    name: string,
    ssn: string,
    email: string,
    password: string
  ) => {
    setIsLoading(true);
    try {
      await call('/register', true)({ email, password, name, ssn });
      await firebase.login({
        email: email.toLowerCase().trim(),
        password,
      });
    } catch (err: any) {
      try {
        await firebase.login({
          email: email.toLowerCase().trim(),
          password,
        });
      } catch (err: any) {
        if (
          err?.message ===
          'The password is invalid or the user does not have a password.'
        ) {
          error(
            'Notandi með þetta netfang er til nú þegar. Prófaðu að skrá þig inn fyrst.'
          );
        } else {
          error(err.message || 'Nýskráning tókst ekki');
        }
      }
    }
    setIsLoading(false);
  };
  const requiredMessage = 'Vinsamlegast fylltu út í þennan reit.';
  const schema = yup.object().shape({
    name: yup.string().required(requiredMessage),
    ssn: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9]+$/, 'Kennitala er á vitlausu formi')
      .min(10, 'Kennitala verður að vera 10 tölustafir að lengd.')
      .max(10, 'Kennitala verður að vera 10 tölustafir að lengd.'),
    email: yup
      .string()
      .required(requiredMessage)
      .email('Netfangið er á vitlausu formi.'),
    emailConfirm: yup
      .string()
      .test('emails-match', 'Netföng passa ekki saman.', function (value) {
        return this.parent.email === value;
      }),
    password: yup
      .string()
      .required(requiredMessage)
      .min(6, 'Lykilorðið verður að vera minnst 6 stafir að lengd.'),
    passwordConfirm: yup
      .string()
      .test('passwords-match', 'Lykilorð passa ekki saman.', function (value) {
        return this.parent.password === value;
      }),
    terms: yup
      .boolean()
      .oneOf([true], 'Þú verður að samþykkja skilmála til að halda áfram.'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (clientConfig.name !== 'visir') {
      history.push({
        pathname: '/login',
      });
    }
  }, []);
  return (
    <>
      <form
        className={s.form}
        onSubmit={handleSubmit(
          (d) => onSave(d.name, d.ssn, d.email, d.password),
          (e) => console.log(e)
        )}
      >
        <div className={s.input}>
          <FormInput
            error={errors.name?.message}
            label={'Nafn'}
            type="text"
            placeholder="Skrifaðu nafnið þitt"
            {...register('name')}
          />
        </div>
        <div className={s.input}>
          <FormInput
            type="text"
            error={errors.ssn?.message}
            label={'Kennitala'}
            placeholder="Skrifaðu kennitöluna þína"
            {...register('ssn')}
          />
        </div>
        <div className={s.input}>
          <FormInput
            label="Netfang"
            error={errors.email?.message}
            type="text"
            placeholder="Skrifaðu netfangið þitt"
            {...register('email')}
          />
        </div>
        <div className={s.input}>
          <FormInput
            label="Netfang aftur"
            error={errors.emailConfirm?.message}
            type="text"
            placeholder="Skrifaðu netfangið þitt aftur"
            {...register('emailConfirm')}
          />
        </div>
        <div className={s.input}>
          <FormInput
            label="Lykilorð (minnst 6 stafir)"
            error={errors.password?.message}
            type="password"
            placeholder="Skrifaðu lykilorðið þitt"
            {...register('password')}
          />
        </div>
        <div className={s.input}>
          <FormInput
            label="Lykilorð aftur"
            error={errors.passwordConfirm?.message}
            type="password"
            placeholder="Skrifaðu lykilorðið þitt aftur"
            {...register('passwordConfirm')}
          />
        </div>
        <div className={s.checkboxInput}>
          <FormCheckbox
            error={errors.terms?.message as string | undefined}
            {...register('terms')}
          >
            Ég samþykki{' '}
            <a
              href={'#'}
              onClick={(e) => {
                e.preventDefault();
                dispatch(showModal(ModalType.PRIVACY_MODAL, {}));
              }}
            >
              skilmála
            </a>{' '}
            Tal
          </FormCheckbox>
        </div>
        <div className={cn(s.submit, { [s.submitRight]: !podcastId })}>
          {slug && <NavLink to={'/' + slug}>Aftur á þáttasíðu</NavLink>}
          <Button type="submit" disabled={isLoading}>
            {isLoading ? 'Hinkraðu...' : 'Áfram'}
          </Button>
        </div>
      </form>
    </>
  );
};
