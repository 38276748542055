import React, { FC } from 'react';
import { Podcast } from '../../types';
import GeneralModal from './GeneralModal';
import { PodcastEpisodes } from '../podcast-episodes/PodcastEpisodes';
import s from './PodcastEpisodesModal.module.css';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../redux/actions/modal';

export type PodcastsEpisodesModalProps = {
  podcast: Podcast;
};
export const PodcastEpisodesModal: FC<PodcastsEpisodesModalProps> = ({
  podcast,
}) => {
  const dispatch = useDispatch();
  return (
    <GeneralModal disableOutsideClick={true}>
      <div className={s.container}>
        <button className={s.close} onClick={() => dispatch(hideModal())}>
          Loka
        </button>
        <PodcastEpisodes podcast={podcast} noCollapse={true} />
      </div>
    </GeneralModal>
  );
};
