import React, { useEffect, useState } from 'react';
import withPodcast from '../utils/withPodcast';
import {
  ExtendedFirebaseInstance,
  ExtendedFirestoreInstance,
  isEmpty,
  isLoaded,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { ManagePodcastTab, ModalType, Podcast, Subscription } from '../types';
import { TopNav } from '../components/top-nav/TopNav';
import { ManagePodcast } from '../components/manage-podcast/ManagePodcast';
import s from './ManagePodcastUsers.module.css';
import { useTypedSelector } from '../redux/reducers';
import { Button } from '../components/button/Button';
import { PageContainer } from '../components/page-container/PageContainer';
import { TextInput } from '../components/text-input/TextInput';
import { ManageSubscriptionsList } from '../components/manage-subscriptions-list/ManageSubscriptionsList';
import { Spinner } from '../components/spinner/Spinner';
import { NoItems } from '../components/no-items/NoItems';
import { validateEmail } from '../utils/utilities';
import { useDispatch } from 'react-redux';
import { showModal } from '../redux/actions/modal';
import SubscriptionBusiness from '../business/SubscriptionBusiness';

const PodcastUsersSearchResults: React.FC<{
  podcast: Podcast;
  searchQuery: string;
}> = ({ searchQuery, podcast }) => {
  useFirestoreConnect({
    collection: 'podcastsubscriptions',
    orderBy: ['createdAt', 'desc'],
    where: [
      ['podcastId', '==', podcast.id],
      ['ownerId', '==', podcast.userId],
      ['userEmail', '==', searchQuery],
    ],
  });
  useFirestoreConnect({
    collection: 'users',
    where: ['email', '==', searchQuery],
  });
  let podcastsubscriptions: Subscription[] = useTypedSelector(
    (state) => state.firestore.ordered.podcastsubscriptions,
  );
  return (
    <>
      {!isLoaded(podcastsubscriptions) && (
        <div>
          <Spinner />
        </div>
      )}
      {isLoaded(podcastsubscriptions) && !isEmpty(podcastsubscriptions) && (
        <ManageSubscriptionsList subscriptions={podcastsubscriptions} />
      )}
      {isLoaded(podcastsubscriptions) && isEmpty(podcastsubscriptions) && (
        <NoItems message={'Engar áskriftir fundust.'} />
      )}
    </>
  );
};

const ManagePodcastUsers: React.FC<any> = (props) => {
  const {
    firebase,
    firestore,
    podcast,
    profile,
  }: {
    firebase: ExtendedFirebaseInstance;
    firestore: ExtendedFirestoreInstance;
    podcast: Podcast;
    profile: any;
  } = props;
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  if (!profile.isAdmin) {
    return null;
  }

  const searchForSubscription = () => {
    setSearchQuery(search);
  };

  return (
    <PageContainer>
      <TopNav />
      <ManagePodcast context={ManagePodcastTab.USERS} podcast={podcast}>
        <div className={s.container}>
          {profile.isAdmin && (
            <>
              <div className={s.button}>
                {!!podcast.subscriptionPlans?.length ? (
                  <>
                    <Button to={`/podcasts/subscriptions/${podcast.id}/new`}>
                      Stofna áskrift
                    </Button>
                    <Button
                      onClick={() => {
                        dispatch(
                          showModal(ModalType.RANDOM_SUBSCRIPTION, {
                            podcastId: podcast.id,
                          }),
                        );
                      }}
                      className={'black'}
                    >
                      Velja af handahófi
                    </Button>
                  </>
                ) : (
                  <Button to={`/podcasts/edit/${podcast.id}`}>
                    Stofna áskriftarleiðir
                  </Button>
                )}
              </div>
              <div className={s.search}>
                <div className={s.searchInput}>
                  <TextInput
                    id={'search-input'}
                    autofocus={true}
                    value={search}
                    label={'Leita að áskrift'}
                    onEnter={() => {
                      if (validateEmail(search)) {
                        searchForSubscription();
                      }
                    }}
                    placeholder={'Skrifaðu netfangið hér...'}
                    onChange={(val) => setSearch(val)}
                  />
                </div>
                <div className={s.searchBtn}>
                  <Button
                    disabled={!search || !validateEmail(search)}
                    className={'green'}
                    onClick={() => searchForSubscription()}
                  >
                    Leita
                  </Button>
                </div>
              </div>
              <div className={s.content}>
                {searchQuery && (
                  <PodcastUsersSearchResults
                    searchQuery={searchQuery}
                    podcast={podcast}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </ManagePodcast>
    </PageContainer>
  );
};

export default withPodcast(ManagePodcastUsers);
