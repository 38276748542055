const supportedProviders = ['Apple Podcasts', 'Overcast'];
export const getDeepLinkForProvider = (
  selectedProvider: string,
  link: string
) => {
  if (!supportedProviders.includes(selectedProvider)) {
    return;
  }
  const linkWithoutProtocol = link.replace('https://', '');
  if (selectedProvider === 'Apple Podcasts') {
    return `podcast://${linkWithoutProtocol}`;
  }
  if (selectedProvider === 'Overcast') {
    return `overcast://x-callback-url/add?url=${encodeURIComponent(
      'https://' + linkWithoutProtocol
    )}`;
  }
};

export const supportsDeepLink = (selectedProvider?: string) => {
  return selectedProvider && supportedProviders.includes(selectedProvider);
};
