import React, { useState } from 'react';
import s from './Login.module.css';
import { TextInput } from '../components/text-input/TextInput';
import { Button } from '../components/button/Button';
import { useFirebase } from 'react-redux-firebase';
import { validateEmail } from '../utils/utilities';
import { error } from '../utils/Notifications';
import { useTypedSelector } from '../redux/reducers';
import { Link, Redirect } from 'react-router-dom';
export const Login: React.FC<any> = (props) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const firebase = useFirebase();
  const auth = useTypedSelector((state) => state.firebase.auth);
  const login = async (email: string, password: string): Promise<any> => {
    setIsLoading(true);
    try {
      await firebase.login({
        email,
        password,
      });
    } catch (err: any) {
      error('Notandanafn og lykilorð passa ekki saman.');
      setIsLoading(false);
    }
  };
  return (
    <>
      {auth.isLoaded && auth.isEmpty && (
        <div className={s.flex}>
          <div className={s.left} />
          <div className={s.right}>
            <div className={s.register}>
              <Link to={'/forgot'} className={s.forgotLink}>
                Gleymt lykilorð
              </Link>
            </div>
            <div className={s.loginContainer}>
              <div className={s.loginContent}>
                <div className={s.title}>Innskráning</div>
                <p>Skráðu þig inn eða stofnaðu aðgang til að halda áfram</p>
                <div className={s.inputs}>
                  <TextInput
                    autofocus={true}
                    value={email}
                    label={''}
                    placeholder={'Netfang'}
                    onChange={(val) => setEmail(val)}
                  />
                  <div style={{ marginTop: '20px' }}>
                    <TextInput
                      value={password}
                      label={''}
                      type={'password'}
                      placeholder={'Lykilorð'}
                      onChange={(val) => setPassword(val)}
                      onEnter={() => login(email, password)}
                    />
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    Nýr notandi?{' '}
                    <Link to={'/register'} className={s.registerLink}>
                      Stofna aðgang
                    </Link>
                  </div>
                  <div className={s.actions}>
                    <Button to={'/'} className={'gray'}>
                      Hætta við
                    </Button>
                    <Button
                      disabled={isLoading || !validateEmail(email) || !password}
                      onClick={() => login(email, password)}
                    >
                      {isLoading ? 'Hinkraðu...' : 'Skrá inn'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {auth.isLoaded && !auth.isEmpty && (
        <Redirect to={props.location.state?.from || '/'} />
      )}
    </>
  );
};
