import { PlayerAction, PlayerState } from '../../types';

const initialState: PlayerState = {
  podcast: undefined,
  episode: undefined,
  isPlaying: false,
};

export const playerReducer = (
  state: PlayerState = initialState,
  action: PlayerAction
): PlayerState => {
  switch (action.type) {
    case 'TOGGLE_PLAY':
      if (state.episode?.id === action.episode?.id) {
        return {
          ...state,
          episode: action.episode,
          isPlaying: !state.isPlaying,
          podcast: action.podcast,
        };
      }
      return {
        ...state,
        episode: action.episode,
        isPlaying: true,
        podcast: action.podcast,
      };
    case 'STOP':
      return {
        ...state,
        isPlaying: false,
      };
    case 'START':
      if (!state.episode || !state.podcast) {
        return { ...state };
      }
      return {
        ...state,
        isPlaying: true,
      };
    case 'HIDE':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
