import React from 'react';
import s from './FaqPage.module.css';
import clientConfig from '../client-config';
import { VisirTheme } from '../visir-components/visir-theme/VisirTheme';
import { PageContainer } from '../components/page-container/PageContainer';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

type FaqProps = {
  items: { title: string; content: string }[][];
};

const Faq: React.FC<FaqProps> = ({ items }) => {
  return (
    <div className={s.container}>
      <Helmet>
        <title>Algengar spurningar | {clientConfig.title}</title>
        <link
          rel={'canonical'}
          href={clientConfig.canonical + '/algengar-spurningar'}
        />
      </Helmet>
      <Container>
        <Row>
          <h1 className={s.title}>Algengar spurningar</h1>
        </Row>
        {items.map((item) => (
          <div className={s.row}>
            <Row>
              {item.map((q) => (
                <Col lg={4}>
                  <h4 className={s.questionTitle}>{q.title}</h4>
                  <div className={s.content}>{q.content}</div>
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </Container>
    </div>
  );
};
const FaqVisir: React.FC = () => {
  const faqVisir = [
    [
      {
        title: 'Hvernig segi ég upp áskrift?',
        content:
          'Þú skráir þig inn, ýtir á ,,Mitt svæði" uppi í valmyndinni vinstra megin, þar sérðu ,,Mínar áskriftir" og fyrir neðan smelliru á punktana hægra megin og velur ,,Hætta í áskrift". Ef þú ert í síma gætirðu þurft að snúa símanum til þess að sjá punktana.',
      },
      {
        title: 'Hvernig slekk ég á tilkynningum?',
        content:
          'Þú skráir þig inn, ýtir á ,,Mitt svæði" uppi í valmyndinni vinstra megin, þar sérðu ,,Mínar áskriftir" og fyrir neðan smelliru á punktana hægra megin og velur ,,Slökkva á tilkynningum". Ef þú ert í síma gætirðu þurft að snúa símanum til þess að sjá punktana.',
      },
      {
        title: 'Hvernig hef ég samband ef það er vandamál?',
        content:
          'Við mælum með að þú notir spjallið hér niðri í hægra horninu.',
      },
    ],
    [
      {
        title: 'Hvað gerist ef ég segi upp áskrift, endar hún strax?',
        content:
          'Eina sem gerist er að hún endurnýjast ekki en aðgangurinn helst opinn út tímabilið.',
      },
      {
        title: 'Endurnýjast áskriftin sjálfkrafa?',
        content:
          'Já eins og kemur fram við öll kaup af áskriftum þá endurnýjast þær sjálfkrafa, sé þeim ekki sagt upp.',
      },
      {
        title: 'Er bara hægt að hlusta í gegnum síðuna eða er til app?',
        content: 'Nú getur þú fundið Tal í Bylgjan, FM957 og X-ið appinu!',
      },
    ],
    [
      {
        title: 'Ég vil vera hluti af TAL',
        content:
          'Sendu okkur skilaboð í spjallið hér í hægra horninu og segðu okkur frá þér og hugmyndinni þinni.',
      },
    ],
  ];
  const faqPardus = [[]];
  if (clientConfig.name === 'visir') {
    return (
      <PageContainer>
        <VisirTheme>
          <Faq items={faqVisir} />
        </VisirTheme>
      </PageContainer>
    );
  }
  return (
    <PageContainer>
      <Faq items={faqPardus} />
    </PageContainer>
  );
};
export default FaqVisir;
