import React, { useEffect, useState } from 'react';
import withPodcast from '../utils/withPodcast';
import clientConfig from '../client-config';
import {
  AnalyticsData,
  AnalyticsItemType,
  ManagePodcastTab,
  Podcast,
} from '../types';
import { TopNav } from '../components/top-nav/TopNav';
import { ManagePodcast } from '../components/manage-podcast/ManagePodcast';
import s from './ManagePodcastAnalytics.module.css';
import { Spinner } from '../components/spinner/Spinner';
import { PageContainer } from '../components/page-container/PageContainer';
import moment from 'moment';
import { Dropdown } from '../components/dropdown/Dropdown';
import { call } from '../utils/ApiManager';

const AnalyticsItem: React.FC<AnalyticsItemType> = ({ name, number }) => {
  return (
    <div className={s.analyticsItem}>
      <div className={s.number}>{number}</div>
      <div className={s.name}>{name}</div>
    </div>
  );
};
const ManagePodcastAnalytics: React.FC<any> = (props) => {
  const {
    podcast,
    profile,
  }: {
    podcast: Podcast;
    profile: any;
  } = props;
  type Period = {
    from: moment.Moment;
    to: moment.Moment;
    name: string;
  };

  const getPeriodName = (from: moment.Moment, to: moment.Moment) => {
    return `${from.format('DD. MMM YYYY')} - ${to.format('DD. MMM YYYY')}`;
  };

  const getPardusPeriodForDate = (date: moment.Moment): Period => {
    let from;
    let to;
    if (date.date() >= 20) {
      const nextMonth = date.clone().add(1, 'month');
      from = date.date(20).startOf('day');
      to = nextMonth.date(19).endOf('day');
    } else {
      const previousMonth = date.clone().subtract(1, 'month');
      from = previousMonth.date(20).startOf('day');
      to = date.date(19).endOf('day');
    }
    return {
      from: from.clone(),
      to: to.clone(),
      name: getPeriodName(from, to),
    };
  };
  const getVisirPeriodForDate = (date: moment.Moment): Period => {
    let from;
    let to;
    if (date.date() >= 1) {
      from = date.date(1).startOf('day');
      to = date.clone().endOf('month').endOf('day');
    } else {
      const previousMonth = date.clone().subtract(1, 'month');
      from = previousMonth.date(1).startOf('day');
      to = previousMonth.clone().endOf('month').endOf('day');
    }
    return {
      from: from.clone(),
      to: to.clone(),
      name: getPeriodName(from, to),
    };
  };

  const getPeriodsForPodcast = (): Period[] => {
    if (!podcast || !podcast.createdAt) {
      return [];
    }
    const start = moment(podcast.createdAt?.toDate()).startOf('month');
    const periods = [];
    const currentDate = moment();
    while (currentDate.isAfter(start)) {
      if (clientConfig.name === 'main') {
        periods.push(getPardusPeriodForDate(currentDate));
      } else {
        periods.push(getVisirPeriodForDate(currentDate));
      }
      currentDate.subtract(1, 'month');
    }
    return periods;
  };
  const periods = getPeriodsForPodcast();

  const renderAnalytics = (analytics: AnalyticsItemType[]) => {
    return (
      <div className={s.rowContainer}>
        {analytics.map((a, index) => {
          if (a.onlyAdmin && !profile.isAdmin) {
            return null;
          }
          return <AnalyticsItem key={index} {...a} />;
        })}
      </div>
    );
  };

  const [data, setData] = useState<AnalyticsData>();
  const [isLoading, setIsLoading] = useState(false);
  const [from, setFrom] = useState<moment.Moment>(
    periods.length ? periods[0].from : moment()
  );
  const [to, setTo] = useState<moment.Moment>(
    periods.length ? periods[0].to : moment()
  );
  useEffect(() => {
    if (
      !from ||
      !to ||
      (podcast.userId !== profile.userId && !profile.isAdmin)
    ) {
      return;
    }
    const getData = async () => {
      setIsLoading(true);
      const res = await call(
        '/analytics/' +
          podcast.id +
          `?from=${from.format('DD/MM/YY')}&to=${to.format('DD/MM/YY')}`
      )();
      setIsLoading(false);
      setData(res);
    };
    getData();
  }, [from, to]);

  return (
    <PageContainer>
      <TopNav />
      <ManagePodcast context={ManagePodcastTab.ANALYTICS} podcast={podcast}>
        <div className={s.container}>
          {!isLoading ? (
            <>
              <div className={s.activeSubscriptions}>
                <strong>Virkar áskriftir:</strong>{' '}
                {data?.activeSubscriptions || 0}
              </div>
              <div className={s.periodSelector}>
                <Dropdown
                  label={'Tímabil'}
                  options={periods}
                  onChange={(period: Period) => {
                    setFrom(period.from);
                    setTo(period.to);
                  }}
                  selected={getPeriodName(from, to)}
                />
              </div>
              <>
                {!!data?.sales?.length && renderAnalytics(data.sales)}
                {!!data?.commissionNumbers?.length &&
                  renderAnalytics(data.commissionNumbers)}
                {!!data?.subscriptions?.length &&
                  renderAnalytics(data.subscriptions)}
              </>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </ManagePodcast>
    </PageContainer>
  );
};

export default withPodcast(ManagePodcastAnalytics);
