import React from 'react';
import { CenteredSpinner } from '../components/spinner/CenteredSpinner';
import {
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { useTypedSelector } from '../redux/reducers';
import { Podcast } from '../types';

type Props = {
  match: any;
};
const withPodcast = (WrappedComponent: React.FC<any>) => {
  return (props: Props) => {
    const {
      match: { params },
    } = props;
    const { podcastId } = params;
    useFirestoreConnect({
      collection: 'podcasts',
      doc: podcastId,
      storeAs: 'podcast-' + podcastId,
    });

    const data = useTypedSelector(
      (state) => state.firestore.ordered['podcast-' + podcastId]
    );
    const profile = useTypedSelector((state) => state.firebase.profile);
    const podcast: Podcast = data ? data[0] : null;
    const firestore = useFirestore();
    const firebase = useFirebase();
    const passProps = {
      //@ts-ignore
      firestore,
      firebase,
      podcast,
      profile,
      ...props,
    };
    return (
      <>
        {podcast && podcast?.id === podcastId && !profile?.isEmpty ? (
          <WrappedComponent {...passProps} />
        ) : (
          <CenteredSpinner />
        )}
      </>
    );
  };
};

export default withPodcast;
