import React, { useState } from 'react';
import { TopNav } from '../components/top-nav/TopNav';
import { PageContainer } from '../components/page-container/PageContainer';
import { Container } from 'react-bootstrap';
import { useTypedSelector } from '../redux/reducers';
import { Spinner } from '../components/spinner/Spinner';
import {
  isLoaded,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { ModalType, Podcast, RssLink, Subscription } from '../types';
import { call } from '../utils/ApiManager';
import moment from 'moment';
import { Link } from 'react-router-dom';
import s from '../components/pardus-rss/PardusRss.module.css';
import { ContextMenu } from '../components/context-menu/ContextMenu';
import { SimpleTable } from '../components/simple-table/SimpleTable';
import { EmptyIllustration } from '../components/empty-illustration/EmptyIllustration';
import RssBusiness from '../business/RssBusiness';
import { useDispatch } from 'react-redux';
import { showModal } from '../redux/actions/modal';

const PardusRssAdminDetail: React.FC<any> = (props) => {
  const profile = useTypedSelector((state) => state.firebase.profile);

  const {
    match: { params },
  } = props;
  const { userId } = params;

  useFirestoreConnect({
    collection: 'podcastsubscriptions',
    orderBy: ['createdAt', 'desc'],
    where: [['userId', '==', userId]],
  });
  let podcastsubscriptions: Subscription[] = useTypedSelector(
    (state) => state.firestore.ordered.podcastsubscriptions
  );
  useFirestoreConnect({
    collection: 'users',
    doc: userId,
  });
  useFirestoreConnect({
    collection: 'podcasts',
    orderBy: ['createdAt', 'desc'],
    where: [
      ['active', '==', true],
      ['status', '==', 'PUBLISHED'],
    ],
  });
  useFirestoreConnect({
    collection: 'podcasts',
    orderBy: ['createdAt', 'desc'],
    where: [
      ['active', '==', true],
      ['userId', '==', userId],
    ],
    storeAs: 'ownpodcasts',
  });
  useFirestoreConnect({
    collection: 'rsslinks',
    where: ['userId', '==', userId],
  });

  const user = useTypedSelector((state) => {
    return state.firestore.data?.users
      ? state.firestore.data.users[userId]
      : null;
  });
  console.log(user);
  const firestore = useFirestore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  let ownPodcasts = useTypedSelector(
    (state) => state.firestore.ordered.ownpodcasts
  );
  let podcasts = useTypedSelector((state) => state.firestore.ordered.podcasts);
  let rsslinks: RssLink[] = useTypedSelector(
    (state) => state.firestore.ordered.rsslinks
  );

  const dispatch = useDispatch();

  if (!profile?.isAdmin) {
    return null;
  }

  let filteredPodcasts: Podcast[] = [];
  if (podcasts?.length && podcastsubscriptions?.length) {
    const subscriptionIds = [
      ...(podcastsubscriptions?.map((s) => s.podcastId) || []),
      ...(ownPodcasts?.map((p: Podcast) => p.id) || []),
    ];
    filteredPodcasts = podcasts.filter((p: Podcast) =>
      subscriptionIds.includes(p.id as string)
    );
  }
  const cols = [
    'Hlaðvarp',
    'RSS',
    'Notkun',
    'IP tölur',
    'Staða',
    'Síðast notað',
    'Forrit',
    '',
  ];

  const generateLink = async (podcastId?: string) => {
    if (!podcastId || isLoading) {
      return;
    }
    setIsLoading(true);
    await call('/rss')({ podcastId, userId });
    setIsLoading(false);
  };

  const rssBusiness: RssBusiness = new RssBusiness(firestore);

  const closeLink = async (rssLink: RssLink) => {
    await rssBusiness.closeLink(rssLink);
  };
  const openLink = async (rssLink: RssLink) => {
    await rssBusiness.openLink(rssLink);
  };

  const renewLink = async (rssLink: RssLink) => {
    await call('/renew-rss-link')({ linkId: rssLink.id });
  };
  const ownPodcastsSubscriptions =
    ownPodcasts?.map((p: Podcast) => ({
      podcastId: p.id,
      endsAt: moment().add(1, 'year'),
    })) || [];
  const iterablePodcastSubscriptions = podcastsubscriptions || [];
  const rows = [...iterablePodcastSubscriptions, ...ownPodcastsSubscriptions]
    ?.filter((sub: Subscription) => {
      const podcast = filteredPodcasts.find(
        (p: Podcast) => p.id === sub.podcastId
      );
      return !!podcast && moment(sub.endsAt.toDate()).isAfter(moment());
    })
    .map((sub) => {
      const podcast = filteredPodcasts.find(
        (p: Podcast) => p.id === sub.podcastId
      );
      if (!podcast) {
        return [];
      }
      const rssLink = rsslinks?.find((l) => l.podcastId === sub.podcastId);
      const ret: any = [
        <Link className={s.link} to={`/${podcast.slug}`}>
          {podcast.name}
        </Link>,
        rssLink?.link || (
          <span className={s.link} onClick={() => generateLink(podcast.id)}>
            Búa til hlekk
          </span>
        ),
        rssLink?.useCount ? `${rssLink.useCount} skipti` : 'Engin',
        rssLink?.ips.length || 0,
        rssLink?.active ? 'Opið' : 'Lokað',
        rssLink?.lastUsed
          ? moment(rssLink.lastUsed.toDate()).format('DD. MMM YYYY')
          : 'Aldrei',
        rssLink?.selectedProvider,
      ];
      const actions = (
        <div style={{ textAlign: 'right', paddingTop: '8px' }}>
          <ContextMenu minWidth={160}>
            {!rssLink ? (
              <div onClick={() => generateLink(podcast.id)}>Útbúa hlekk</div>
            ) : rssLink.active ? (
              <div onClick={() => closeLink(rssLink)}>Loka</div>
            ) : (
              <div onClick={() => openLink(rssLink)}>Opna</div>
            )}
            <div
              onClick={() =>
                dispatch(
                  showModal(ModalType.CONFIRM_MODAL, {
                    title: 'Nýr hlekkur',
                    actionText: 'Staðfesta',
                    action: () => renewLink(rssLink as RssLink),
                    body: 'Búa til nýjan hlekk?',
                  })
                )
              }
            >
              Nýr hlekkur
            </div>
          </ContextMenu>
        </div>
      );
      ret.push(actions);
      return ret;
    });
  return isLoaded(podcasts) && isLoaded(podcastsubscriptions) ? (
    <PageContainer>
      <TopNav />
      <Container>
        <div className={s.container}>
          <h1>RSS hlekkir</h1>
          {user && (
            <div className={s.info}>
              <strong>Notandi:</strong> {user?.email}
            </div>
          )}
          {!!rows?.length ? (
            <SimpleTable cols={cols} rows={rows} />
          ) : (
            <EmptyIllustration
              message={'Notandi hefur ekki búið til RSS hlekk ennþá.'}
            />
          )}
        </div>
      </Container>
    </PageContainer>
  ) : (
    <Spinner />
  );
};

export default PardusRssAdminDetail;
