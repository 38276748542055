import React from 'react';
import { useTypedSelector } from '../../redux/reducers';
import { Button } from '../button/Button';
import s from './HomepageVisirHeader.module.css';
import cn from 'classnames';
import { TailwindContainer } from '../tailwind-container/TailwindContainer';
export type HomepageVisirHeaderProps = {};
export const HomepageVisirHeader: React.FC<HomepageVisirHeaderProps> = ({}) => {
  const auth = useTypedSelector((state) => state.firebase.auth);
  return (
    <div className="tw-bg-black">
      <TailwindContainer className="tw-text-white">
        <div className="tw-flex tw-flex-col-reverse lg:tw-flex-row tw-text-center lg:tw-text-left">
          <div className="tw-py-10 lg:tw-py-20 tw-mx-auto tw-max-w-lg lg:tw-max-w-xl lg:tw-pr-10">
            <h1 className="tw-text-3xl lg:tw-text-4xl tw-uppercase">
              Heimili <span className="tw-text-purple-500">íslenskra</span>{' '}
              hlaðvarpa
            </h1>
            <div className="tw-mt-5 tw-leading-7">
              Hér getur þú uppgötvað íslensk hlaðvörp, fylgst með hvað er
              vinsælast og fengið aukaefni. Tal býður skapandi einstaklingum
              tækifæri á að byggja upp sitt eigið samfélag og hjálpar þeim að
              stækka.
            </div>
            <div className="tw-mt-8">
              {auth.isLoaded && auth.isEmpty && (
                <>
                  <Button to={'/login'}>Innskráning</Button>
                </>
              )}
            </div>
          </div>
          <div className={cn(s.right, 'tw-flex-grow tw-pt-10 lg:tw-pt-0')}>
            <img
              src={'https://pardus-prod.imgix.net/logo/tal-header.png?w=810'}
              alt={''}
              className="tw-max-h-72 tw-mx-auto lg:tw-hidden"
            />
          </div>
        </div>
      </TailwindContainer>
    </div>
  );
};
