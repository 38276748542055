import React, { useState } from 'react';
import s from './LivestreamLabel.module.css';
import { call } from '../../utils/ApiManager';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from '../../redux/reducers';
import classNames from 'classnames';
import { warn } from '../../utils/Notifications';
type Props = {
  podcastId: string;
  disable?: boolean;
  hasSubscription?: boolean;
  slug?: string;
};
export const LivestreamLabel: React.FC<Props> = ({
  podcastId,
  disable,
  hasSubscription,
  slug,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const auth = useTypedSelector((state) => state.firebase.auth);
  const openLiveEvent = async () => {
    if (disable) {
      return;
    }
    const liveRoute = '/podcasts/detail/' + podcastId + '/live';
    const podcastRoute = '/' + podcastId;
    if (hasSubscription === undefined && auth.uid) {
      setIsLoading(true);
      const data = await call(`/podcasts/${podcastId}/episodes`, true)();
      setIsLoading(false);
      if (data.hasSubscription) {
        history.push(liveRoute);
      } else {
        warn('Þú verður að kaupa áskrift til að horfa á streymið.');
        history.push(podcastRoute);
      }
    } else {
      if (hasSubscription) {
        history.push(liveRoute);
      } else {
        warn('Þú verður að kaupa áskrift til að horfa á streymið.');
        history.push(podcastRoute);
      }
    }
  };
  return (
    <span
      className={classNames({
        [s.liveStreamLabel]: true,
        [s.loading]: isLoading,
        [s.disable]: disable,
      })}
      onClick={() => openLiveEvent()}
    >
      {!isLoading && <img className={s.icon} src={'/img/live.svg'} alt={''} />}
      {!isLoading ? 'Í beinni núna' : 'Hinkraðu...'}
    </span>
  );
};
