import React, { useState } from 'react';
import { Episode, Podcast } from '../types';
import PodcastBusiness from '../business/PodcastBusiness';
import { error, success } from '../utils/Notifications';
import { Redirect } from 'react-router';
import { useFirestore } from 'react-redux-firebase';
import { useTypedSelector } from '../redux/reducers';
import { EpisodeForm } from '../components/episode-form/EpisodeForm';
import withPodcast from '../utils/withPodcast';
import EpisodeBusiness from '../business/EpisodeBusiness';
import { PageContainer } from '../components/page-container/PageContainer';

const PodcastUploadsNew: React.FC<any> = (props) => {
  const { podcast } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');
  const firestore = useFirestore();
  const profile: any = useTypedSelector((state) => state.firebase.profile);
  const create = async (episode: Episode) => {
    setIsLoading(true);
    const episodeBusiness = new EpisodeBusiness(firestore, profile?.userId);
    try {
      await episodeBusiness.create(episode);
      setIsLoading(false);
      success('Þátturinn hefur verið vistaður.');
      setRedirect('/podcasts/uploads/' + podcast.id);
    } catch (err: any) {
      error(err.message || 'Ekki tókst að setja inn þátt.');
      setIsLoading(false);
    }
  };
  return (
    <PageContainer skipHeader>
      <EpisodeForm
        podcastId={podcast.id}
        save={create}
        isLoading={isLoading}
        title={'Nýr þáttur'}
        disclaimer={
          'ATH. Það getur tekið nokkrar mínútur þar til að þátturinn birtist á forsíðu hlaðvarpsins.'
        }
      />
      {redirect && <Redirect to={redirect} />}
    </PageContainer>
  );
};

export default withPodcast(PodcastUploadsNew);
