import React from 'react';
import s from './LiveEventsInstruction.module.css';

type Props = {};
export const LiveEventsInstruction: React.FC<Props> = ({}) => {
  return (
    <div className={s.container}>
      <ol>
        <li>
          Sæktu OBS studio á{' '}
          <a href={'https://obsproject.com/'} target={'_blank'}>
            obsproject.com
          </a>
        </li>
        <li>Veldu þitt stýrikerfi.</li>
        <li>
          Þegar þú opnar forritið í fyrsta skiptið ætti uppsetningargluggi að
          birtast. Veldu{' '}
          <strong>Optimize for streaming, recording is secondary</strong> og
          ýttu á <strong>Next.</strong>
        </li>
        <li>
          Ýttu aftur á <strong>Next</strong>
        </li>
        <li>
          Veldu <strong>Custom</strong> úr <strong>Service</strong>
        </li>
        <li>
          Settu <strong>rtmp://broadcast.api.video/s</strong> í{' '}
          <strong>Server</strong> reitinn.
        </li>
        <li>
          Afritaðu auðkennið þitt (<strong>Stream Key</strong>) og settu það í
          viðeigandi reit í OBS.
        </li>
        <li>
          Ýttu á <strong>Next</strong>.
        </li>
        <li>
          Ýttu á <strong>Yes</strong>. OBS mun finna bestu stillingar fyrir þína
          tölvu og nettengingu.
        </li>
        <li>
          Ýttu á <strong>Apply Settings</strong>
        </li>
        <li>
          Bættu við <strong>Source</strong> með því að ýta á plúsinn undir{' '}
          <strong>"Sources".</strong>
        </li>
        <li>
          Veldu <strong>Video Capture Device.</strong>
        </li>
        <li>
          Veldu <strong>Create new</strong>, veldu myndavél sem þú vilt nota og
          ýttu á <strong>OK.</strong>
        </li>
        <li>
          Nú getur þú byrjað streymi með því að ýta á{' '}
          <strong>Start Streaming.</strong>
        </li>
        <li>
          Á innan við 20-30s verður streymið{' '}
          <strong>sýnilegt sjálfkrafa</strong> fyrir áskrifendur.
        </li>
        <li>
          Þegar bein útsending klárast, ýttu þá á{' '}
          <strong>Stop streaming</strong> í OBS.
        </li>
        <li>
          <strong>
            Athugaðu að byrja aldrei að streyma aftur á innan við 30 sekúndum
            frá því að þú ýtir á Stop streaming, annars er hætta á því að gamalt
            streymi verði sýnt!
          </strong>
        </li>
      </ol>
    </div>
  );
};
