import React from 'react';
import s from './EpisodesGroup.module.css';
import { Episode, ModalType, Podcast } from '../../types';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { EpisodeItem } from './EpisodeItem';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modal';
import { formatMoney } from '../../utils/utilities';
import { error } from '../../utils/Notifications';
import { useTypedSelector } from '../../redux/reducers';
import { call } from '../../utils/ApiManager';

type Props = {
  title: string;
  podcast: Podcast;
  episodes: Episode[];
  isLocked: boolean;
  timeLimit?: string | null;
  timeLimitResetPrice?: number;
  isCollapsed?: boolean;
  embeddable?: boolean;
  subscriptionId?: string;
};
export const EpisodesGroup: React.FC<Props> = ({
  title,
  episodes,
  isLocked,
  timeLimit,
  isCollapsed,
  podcast,
  timeLimitResetPrice = 0,
  subscriptionId,
  embeddable
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const resetPrice = () =>
    dispatch(
      showModal(ModalType.CONFIRM_MODAL, {
        color: 'green',
        title: 'Staðfesta greiðslu',
        actionText: `Greiða (${formatMoney(timeLimitResetPrice)} kr.)`,
        actionTextColor: 'greenText',
        //@ts-ignore
        action: async () => {
          try {
            await call(
              `/subscriptions/${subscriptionId}/removetimelimit`,
              false,
              null,
              'POST'
            )();
          } catch (err: any) {
            error(err?.message || 'Greiðsla tókst ekki.');
          }
          window.location.reload();
        },
        body: `Greiðsla að upphæð ${formatMoney(
          timeLimitResetPrice
        )} kr. verður tekin af kortinu sem skráð er með áskriftinni þinni.`,
      })
    );
  const { isPlaying } = useTypedSelector((state) => state.player);
  const playingEpisode = useTypedSelector((state) => state.player)?.episode;
  return (
    <div className={s.container}>
      <h2 className={cn(s.title, { [s.isLocked]: isLocked })}>{title}</h2>
      {timeLimit && timeLimitResetPrice && (
        <div className={s.description}>
          Þú hefur aðgang að þáttum aftur að{' '}
          {moment(timeLimit).format('DD. MMM YYYY')}.{' '}
          <a href={'#'} onClick={() => resetPrice()}>
            Fá aðgang að öllu efni.
          </a>
        </div>
      )}
      <div className={s.episodes}>
        {episodes.slice(0, isCollapsed ? 5 : episodes.length).map((e) => (
          <EpisodeItem
            episode={e}
            podcast={podcast}
            isFeedLocked={isLocked}
            isPlaying={isPlaying && playingEpisode?.id === e.id}
            showEmbed={embeddable}
          />
        ))}
      </div>
      {isCollapsed && episodes.length > 4 && (
        <div className={'tw-text-center'}>
          <a
            href={'#'}
            onClick={(e) => {
              e.preventDefault();
              if (isLocked) {
                history.push('/podcasts/purchase/' + podcast.id);
              } else {
                dispatch(showModal(ModalType.EPISODES_MODAL, { podcast }));
              }
            }}
          >
            Sjá fleiri þætti
          </a>
        </div>
      )}
    </div>
  );
};
