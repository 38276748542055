import React from 'react';
import s from './PardusTextareaAutosize.module.css';
import TextareaAutosize from 'react-textarea-autosize';
import { TextareaAutosizeProps } from 'react-textarea-autosize';
import classNames from "classnames";

type Props = {
  gray?: boolean;
}
export const PardusTextareaAutosize: React.FC<TextareaAutosizeProps & Props> = props => {
  return (
    <div className={classNames({[s.container]: true, [s.gray]: props.gray, [s.disabled]: props.disabled})}>
      <TextareaAutosize {...props} />
    </div>
  );
};
