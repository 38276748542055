import React, { useState } from 'react';
import s from './UsernameModal.module.css';
import GeneralModal from './GeneralModal';
import { TextInput } from '../text-input/TextInput';
import { Button } from '../button/Button';
import FeedBusiness from '../../business/FeedBusiness';
import { useFirestore } from 'react-redux-firebase';
import { useTypedSelector } from '../../redux/reducers';
import { error } from '../../utils/Notifications';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../redux/actions/modal';

type Props = {
  callback?: (name: string) => void;
};

export const UsernameModal: React.FC<Props> = ({ callback }) => {
  const [name, setName] = useState<string>('');
  const [acceptCheckbox, setAcceptCheckbox] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const firestore = useFirestore();
  const auth = useTypedSelector((state) => state.firebase.auth);
  const feedBusiness: FeedBusiness = new FeedBusiness(firestore, auth.uid);
  const dispatch = useDispatch();
  const save = async () => {
    if (!name || !acceptCheckbox) {
      return;
    }
    setIsLoading(true);
    try {
      await feedBusiness.setUsername(name);
      if (callback) {
        callback(name);
      }
      dispatch(hideModal());
    } catch (err: any) {
      error(err.message || 'Eitthvað fór úrskeiðis.');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <GeneralModal>
      <div className={s.container}>
        <div className={s.head}>Umræðan á Pardus</div>
        <div className={s.content}>
          Vinsamlegast skrifaðu nafnið þitt til að taka þátt í umræðunni.
          Athugaðu að þú getur <strong>ekki</strong> breytt nafninu þínu seinna.
        </div>
        <div className={s.input}>
          <TextInput
            label={'Fullt nafn'}
            placeholder={'Skrifaðu fullt nafn...'}
            onChange={(val) => setName(val)}
            autofocus
          />
        </div>
        <div className={s.checkbox}>
          <input
            id={'confirm-name'}
            type={'checkbox'}
            checked={acceptCheckbox}
            onChange={(e) => setAcceptCheckbox(e.target.checked)}
          />{' '}
          <label htmlFor={'confirm-name'}>
            Ég staðfesti að nafnið mitt er rétt slegið inn.
          </label>
        </div>
        <div className={s.action}>
          <Button
            disabled={!name || !acceptCheckbox || isLoading}
            onClick={save}
          >
            {isLoading ? 'Hinkraðu...' : 'Senda athugasemd'}
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};
