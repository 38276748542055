import s from './PodcastDetailInfo.module.css';
import React, { PropsWithChildren } from 'react';
import { Podcast } from '../../types';
import { PurchaseButton } from '../purchase-button/PurchaseButton';
import { Button } from '../button/Button';
import { PodcastHead } from '../podcast-head/PodcastHead';

type Props = PropsWithChildren<{
  podcast: Podcast;
  hasSubscription: boolean;
  isOwner: boolean;
  isAdmin: boolean;
}>;

export const PodcastDetailInfo: React.FC<Props> = ({
  podcast,
  hasSubscription,
  isOwner,
  isAdmin,
}) => {
  return (
    <div className={s.container}>
      <PodcastHead podcast={podcast} hasSubscription={hasSubscription}>
        {(isOwner || isAdmin || !!podcast?.subscriptionPlans?.length) && (
          <div>
            {!hasSubscription && <PurchaseButton podcast={podcast} />}
            {(isOwner || isAdmin) && (
              <Button className={'green'} to={'/podcasts/manage/' + podcast.id}>
                Stjórna
              </Button>
            )}
            {hasSubscription && !isOwner && !isAdmin && (
              <Button className={'green'} disabled={true}>
                Aðgangur virkur
              </Button>
            )}
            {hasSubscription && podcast.isLiveStreaming && (
              <Button to={'/podcasts/detail/' + podcast.id + '/live'}>
                Opna streymi
              </Button>
            )}
          </div>
        )}
      </PodcastHead>
      <div className={s.clearfix} />
      <div className={s.description}>{podcast.description}</div>
    </div>
  );
};
