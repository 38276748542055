import React from 'react';
import s from './EmptyIllustration.module.css';
import cn from 'classnames';
import clientConfig from '../../client-config';
type Props = {
  message: string;
};

export const EmptyIllustration: React.FC<Props> = ({ message }) => {
  return (
    <div className={cn(s.empty, { [s.visir]: clientConfig.name === 'visir' })}>
      <div className={s.text}>{message}</div>
    </div>
  );
};
