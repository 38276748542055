import React, { FC, useEffect, useState } from 'react';
import { SubscriptionPlan } from '../../types';
import { call } from '../../utils/ApiManager';
import GeneralModal from '../modal/GeneralModal';
import { CenteredSpinner } from '../spinner/CenteredSpinner';
import s from './ThreeDSecurePayment.module.css';
type Props = {
  cardNumber: string;
  expiry: string;
  subscriptionPlan: SubscriptionPlan;
  podcastId: string;
  onSuccess: () => void;
  onError: (message: string) => void;
  subscriptionId?: string;
};
export const ThreeDSecurePayment: FC<Props> = ({
  cardNumber,
  expiry,
  subscriptionPlan,
  podcastId,
  onSuccess,
  onError,
  subscriptionId,
}) => {
  const [threeDForm, setThreeDForm] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const enrollThreeDSecure = async (
    mdStatus5: boolean = false,
    MPIToken?: string,
    TxId?: string,
    XId?: string
  ) => {
    try {
      const enrollment = await call(`/payment/enroll`)({
        pan: cardNumber.replace(/\s/g, ''),
        expMonth: expiry.split('/')[0].trim(),
        expYear: expiry.split('/')[1].trim(),
        price: subscriptionPlan.price,
        TxId,
        XId,
        mdStatus5,
        subscriptionId,
      });
      if (enrollment.MdStatus === '9' && enrollment.RedirectToACSForm) {
        const form = enrollment.RedirectToACSForm;
        const threeDSecureCallback = (message: any) => {
          if (message.data === true) {
            window.removeEventListener('message', threeDSecureCallback);
            makePayment(MPIToken || enrollment.MPIToken);
          } else if (message.data === false) {
            window.removeEventListener('message', threeDSecureCallback);
            onError('Auðkenning tókst ekki.');
            setThreeDForm('');
          }
        };
        setThreeDForm('data:text/html;charset=utf-8,' + encodeURI(form));
        window.addEventListener('message', threeDSecureCallback);
      } else if (enrollment.MdStatus === '50') {
        const form = enrollment.TDSMethodContent;
        setShowSpinner(true);
        setThreeDForm('data:text/html;charset=utf-8,' + encodeURI(form));
        setTimeout(() => {
          setThreeDForm('');
          setShowSpinner(false);
          enrollThreeDSecure(
            mdStatus5,
            enrollment.MPIToken,
            enrollment.TxId,
            enrollment.XId
          );
        }, 10 * 1000);
      } else if (enrollment.MdStatus === '5' && !mdStatus5) {
        enrollThreeDSecure(true);
      } else if (enrollment.MdStatus === '8' || enrollment.MdStatus === '0') {
        onError('Greiðsla tókst ekki.');
      } else {
        await makePayment(enrollment.MPIToken);
      }
    } catch (err: any) {
      onError(err?.message || 'Auðkenning tókst ekki.');
    }
  };
  const makePayment = async (mpiToken: string) => {
    const url = subscriptionId
      ? '/payment/update-subscription'
      : '/payment/purchase-subscription';
    try {
      await call(url)({
        podcastId,
        planId: subscriptionPlan.id,
        pan: cardNumber.replace(/\s/g, ''),
        expMonth: expiry.split('/')[0].trim(),
        expYear: expiry.split('/')[1].trim(),
        price: subscriptionPlan.price,
        mpiToken: mpiToken || undefined,
        subscriptionId,
      });
      onSuccess();
    } catch (err: any) {
      onError(err?.message || 'Greiðsla tókst ekki.');
    }
  };
  useEffect(() => {
    enrollThreeDSecure(false);
  }, []);
  return (
    <GeneralModal disableOutsideClick>
      <div style={{ minHeight: '220px' }}>
        {threeDForm ? (
          <>
            {showSpinner && <CenteredSpinner />}
            <iframe
              id={'threeDSecureIframe'}
              className={s.threeDForm}
              src={threeDForm}
            />
          </>
        ) : (
          <CenteredSpinner />
        )}
      </div>
    </GeneralModal>
  );
};
