import React from 'react';
import s from './PardusRssAdmin.module.css';
import { Podcast, ProductSubscription, RssLink } from '../../types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ContextMenu } from '../context-menu/ContextMenu';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { SimpleTable } from '../simple-table/SimpleTable';
import { EmptyIllustration } from '../empty-illustration/EmptyIllustration';
import { Spinner } from '../spinner/Spinner';
import { useTypedSelector } from '../../redux/reducers';
import { formatMoney } from '../../utils/utilities';
import { Button } from '../button/Button';

type Props = {};

export const PardusRssAdmin: React.FC<Props> = () => {
  useFirestoreConnect({
    collection: 'productsubscriptions',
    where: ['productType', '==', 'PREMIUM'],
    orderBy: ['createdAt', 'desc'],
  });

  useFirestoreConnect({
    collection: 'podcasts',
    orderBy: ['createdAt', 'desc'],
    where: [
      ['active', '==', true],
      ['status', '==', 'PUBLISHED'],
    ],
  });

  useFirestoreConnect({
    collection: 'rsslinks',
  });

  let productsubscriptions: ProductSubscription[] = useTypedSelector(
    (state) => state.firestore.ordered.productsubscriptions
  );
  let rssLinks: RssLink[] = useTypedSelector(
    (state) => state.firestore.ordered.rsslinks
  );
  let podcasts: Podcast[] = useTypedSelector(
    (state) => state.firestore.ordered.podcasts
  );
  const cols = [
    'Netfang',
    'Hlaðvarp',
    'Stofnað',
    'IP',
    'Notkun',
    'Síðast notað',
    'Forrit',
    '',
  ];

  const rows = rssLinks?.map((link) => {
    const lastUsed = link.lastUsed
      ? moment(link.lastUsed.toDate()).format('DD. MMM YYYY HH:mm')
      : 'Aldrei';
    const useCount = link.useCount;
    const ipsCount = link.ips?.length || 0;
    const ret: any = [
      <Link to={`/rss/${link.userId}`}>{link.userEmail}</Link>,
      podcasts.find((p) => p.id === link.podcastId)?.name || '',
      moment(link.createdAt.toDate()).format('DD. MMM YYYY'),
      ipsCount,
      useCount ? `${useCount} skipti` : 'Engin',
      lastUsed,
      link.selectedProvider || '-',
    ];
    const actions = (
      <div style={{ textAlign: 'right', paddingTop: '8px' }}>
        <ContextMenu minWidth={80}>
          <Link to={`/rss/${link.userId}`}>Skoða</Link>
        </ContextMenu>
      </div>
    );
    ret.push(actions);
    return ret;
  });

  return isLoaded(rssLinks) &&
    isLoaded(productsubscriptions) &&
    isLoaded(podcasts) ? (
    <div className={s.container}>
      <h1 style={{ marginTop: '40px', marginBottom: '30px' }}>RSS hlekkir</h1>
      {!!rows?.length ? (
        <SimpleTable cols={cols} rows={rows} />
      ) : (
        <EmptyIllustration message={'Engir hlekkir ennþá.'} />
      )}
    </div>
  ) : (
    <Spinner />
  );
};
