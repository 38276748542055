import React from 'react';
import s from './HomepagePodcasts.module.css';
import { Podcast } from '../../types';
import { Container } from 'react-bootstrap';
import { PodcastItem } from '../podcast-item/PodcastItem';
import { EmptyIllustration } from '../empty-illustration/EmptyIllustration';

type Props = {
  podcasts: Podcast[];
  title: string;
  isEmpty?: boolean;
};

export const HomepagePodcasts: React.FC<Props> = ({ title, podcasts, isEmpty }) => {
  return (
    <div className={s.container}>
      <Container>
        {!isEmpty && (
          <div className={s.title}>
            {title} <span>({podcasts?.length})</span>
          </div>
        )}
        {podcasts.map(podcast => (
          <PodcastItem key={podcast.id} podcast={podcast} />
        ))}
        {isEmpty && (
          <div style={{ marginBottom: '80px', marginTop: '-20px' }}>
            <EmptyIllustration
              message={'Því miður fannst ekkert fyrir gefin leitarskilyrði.'}
            />
          </div>
        )}
      </Container>
    </div>
  );
};
