import React from 'react';
import s from './PrivacyVisir.module.css';
import { TopNav } from '../../components/top-nav/TopNav';
import { PageContainer } from '../../components/page-container/PageContainer';
import clientConfig from '../../client-config';
import { Helmet } from 'react-helmet';
import Privacy from '../Privacy';
import { VisirPrivacy } from '../../visir-components/visir-privacy/VisirPrivacy';

const PrivacyVisir: React.FC = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Skilmálar | {clientConfig.title}</title>
        <link rel={'canonical'} href={clientConfig.canonical + '/privacy'} />
      </Helmet>
      <TopNav />
      <div className={s.container}>
        <VisirPrivacy />
      </div>
    </PageContainer>
  );
};

export default PrivacyVisir;
