import React from 'react';
import { useTypedSelector } from '../redux/reducers';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { Redirect, Route } from 'react-router-dom';
import { CenteredSpinner } from '../components/spinner/CenteredSpinner';

export const PrivateRoute: React.FC<any> = ({ component, ...rest }) => {
  const auth = useTypedSelector((state) => state.firebase.auth);
  const profile = useTypedSelector((state) => state.firebase.profile);
  const Component = component;
  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoaded(profile) && isLoaded(auth) && !isEmpty(auth) ? (
          <Component {...props} />
        ) : isLoaded(auth) && isEmpty(auth) ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        ) : (
          <CenteredSpinner />
        );
      }}
    />
  );
};
