import { Store } from 'react-notifications-component';

const notify = (title: string, text: string, type: string): void => {
  Store.addNotification({
    title: title,
    message: text,
    type: type as any,
    container: 'top-right',
    insert: 'top',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

export const warn = (text: string): void => {
  notify('Athugið', text, 'warning');
};
export const info = (text: string): void => {
  notify('Athugið', text, 'info');
};

export const error = (text: string): void => {
  notify('Athugið', text, 'danger');
};

export const success = (text: string): void => {
  notify('Aðgerð tókst', text, 'success');
};
