import React, { PropsWithChildren } from 'react';
import s from './PodcastHead.module.css';
import { Podcast } from '../../types';
import { LivestreamLabel } from '../livestream-label/LivestreamLabel';

type Props = PropsWithChildren<{
  podcast: Podcast;
  disableLiveLabel?: boolean;
  hasSubscription?: boolean;
}>;

export const PodcastHead: React.FC<Props> = ({
  podcast,
  disableLiveLabel,
  hasSubscription,
  children,
}) => {
  return (
    <div className={s.top}>
      <div className={s.title}>
        <img src={`${podcast.image}?h=300`} alt={podcast.name} />
        <div>
          {podcast.isLiveStreaming && (
            <LivestreamLabel
              slug={podcast.slug}
              hasSubscription={hasSubscription}
              podcastId={podcast.id as string}
              disable={disableLiveLabel}
            />
          )}
          <h1 className={s.name}>{podcast.name}</h1>
          {children}
        </div>
      </div>
    </div>
  );
};
