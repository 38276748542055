import React, { useState } from 'react';
import { ModalType, Podcast, SubscriptionPlan } from '../../types';
import { Button } from '../button/Button';
import { useTypedSelector } from '../../redux/reducers';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { useHistory, useLocation } from 'react-router-dom';
import { warn } from '../../utils/Notifications';
import s from './PurchaseButton.module.css';
import { formatMoney } from '../../utils/utilities';
import classNames from 'classnames';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modal';

type Props = {
  podcast: Podcast;
};

const SelectSubscriptionMonth: React.FC<{
  plans: SubscriptionPlan[];
  selected: SubscriptionPlan;
  onSelect: (plan: SubscriptionPlan) => void;
}> = ({ plans, onSelect, selected }) => {
  return (
    <div className={s.monthsContainer}>
      {plans.map((plan) => (
        <div
          key={plan.id}
          className={classNames({
            [s.active]: selected.id === plan.id,
            [s.monthItem]: true,
          })}
          onClick={() => onSelect(plan)}
        >
          {plan.months} mán
        </div>
      ))}
    </div>
  );
};

const PurchaseButtonAction: React.FC<{
  disable: boolean;
  isOneTimePurchase: boolean;
  subscriptionPlan: SubscriptionPlan;
  onClick: () => void;
}> = ({ disable, isOneTimePurchase, subscriptionPlan, onClick }) => {
  const getTimelimitText = (timeLimit: {
    unit: 'months' | 'days';
    value: number;
  }) => {
    if (timeLimit.unit === 'months') {
      return (
        'Áskriftinni fylgir efni ' +
        (timeLimit.value + (timeLimit.value === 1 ? ' mánuð' : ' mánuði')) +
        ' aftur í tíma'
      );
    } else if (timeLimit.unit === 'days') {
      return (
        'Áskriftinni fylgir efni ' +
        (timeLimit.value + (timeLimit.value === 1 ? ' dag' : ' daga')) +
        ' aftur í tíma'
      );
    }
  };

  return (
    <>
      <Button className={'green'} disabled={disable} onClick={onClick}>
        Kaupa {isOneTimePurchase ? '' : 'áskrift'} á{' '}
        {formatMoney(subscriptionPlan.price)} kr.
      </Button>
      {!isOneTimePurchase && (
        <div className={s.info}>
          Áskriftin endurnýjast eftir {subscriptionPlan.months}{' '}
          {subscriptionPlan.months === 1 ? 'mánuð' : 'mánuði'}
          {subscriptionPlan.timeLimit && (
            <>
              <br />
              {getTimelimitText(subscriptionPlan.timeLimit)}
            </>
          )}
        </div>
      )}
    </>
  );
};

export const PurchaseButton: React.FC<Props> = ({ podcast }) => {
  const auth = useTypedSelector((state) => state.firebase.auth);
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const [subscriptionPlan, setSubscriptionPlan] =
    useState<SubscriptionPlan | null>(
      podcast.subscriptionPlans?.length ? podcast.subscriptionPlans[0] : null
    );
  if (!isLoaded(auth)) {
    return null;
  }
  const isPastDeadline =
    !!podcast.deadline && moment(podcast.deadline.toDate()).isBefore(moment());
  return (
    <>
      {(podcast.subscriptionPlans?.length || 0) > 1 && (
        <SelectSubscriptionMonth
          selected={subscriptionPlan as SubscriptionPlan}
          plans={podcast.subscriptionPlans as SubscriptionPlan[]}
          onSelect={(plan) => setSubscriptionPlan(plan)}
        />
      )}
      {!isEmpty(auth) && subscriptionPlan ? (
        <PurchaseButtonAction
          disable={isPastDeadline}
          isOneTimePurchase={!!podcast.oneTimePurchase}
          subscriptionPlan={subscriptionPlan as SubscriptionPlan}
          onClick={() => history.push('/podcasts/payment/' + podcast.id)}
        />
      ) : subscriptionPlan ? (
        <PurchaseButtonAction
          disable={isPastDeadline}
          isOneTimePurchase={!!podcast.oneTimePurchase}
          subscriptionPlan={subscriptionPlan as SubscriptionPlan}
          onClick={() => {
            warn('Þú verður að vera skráður inn til að kaupa aðgang.');
            history.push({
              pathname: '/login',
              state: { from: location.pathname },
            });
          }}
        />
      ) : null}
      {isPastDeadline && (
        <div className={s.info}>Lokað er fyrir sölu eins og er.</div>
      )}
    </>
  );
};
