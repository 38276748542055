import {ModalAction, ModalActionType, ModalState, ModalType} from "../../types";

const initialState: ModalState = {
  modalType: null,
  modalProps: {}
};

export const modalReducer = (
  state: ModalState = initialState,
  action: ModalAction
): ModalState => {
  switch (action.type) {
    case ModalActionType.SHOW_MODAL:
      return {
        modalType: action.modalType as ModalType,
        modalProps: action.modalProps
      };
    case ModalActionType.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};
