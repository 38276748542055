import React from 'react';
import s from './RemoveTimeLimitButton.module.css';
import { formatMoney } from '../../utils/utilities';
import { Button } from '../button/Button';
import { ModalType } from '../../types';
import { showModal } from '../../redux/actions/modal';
import { useDispatch } from 'react-redux';
import { error } from '../../utils/Notifications';
import { call } from '../../utils/ApiManager';

type Props = {
  resetPrice: number;
  subscriptionId: string;
  reloadData: () => void;
};

export const RemoveTimeLimitButton: React.FC<Props> = ({
  resetPrice,
  subscriptionId,
  reloadData,
}) => {
  const dispatch = useDispatch();
  return (
    <div className={s.container}>
      <Button
        onClick={() =>
          dispatch(
            showModal(ModalType.CONFIRM_MODAL, {
              color: 'green',
              title: 'Staðfesta greiðslu',
              actionText: `Greiða (${formatMoney(resetPrice)} kr.)`,
              actionTextColor: 'greenText',
              //@ts-ignore
              action: async () => {
                try {
                  await call(
                    `/subscriptions/${subscriptionId}/removetimelimit`,
                    false,
                    null,
                    'POST'
                  )();
                } catch (err: any) {
                  error(
                    err?.message ||
                      'Greiðsla tókst ekki. Vinsamlegast hafðu samband við þjónustuver.'
                  );
                }
                reloadData();
              },
              body: `Greiðsla að upphæð ${formatMoney(
                resetPrice
              )} kr. verður tekin af kortinu sem skráð er með áskriftinni þinni.`,
            })
          )
        }
      >
        Kaupa aðgang að öllu efni á {formatMoney(resetPrice)} kr.
      </Button>
    </div>
  );
};
