import React, { useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import GeneralModal from './GeneralModal';
import { Button } from '../button/Button';
import s from './CropperModal.module.css';
import { hideModal } from '../../redux/actions/modal';
import { useDispatch } from 'react-redux';
import { dataURLtoFile, uuid } from '../../utils/utilities';
import { useTypedSelector } from '../../redux/reducers';
import { FileMimeType } from "../../utils/mime-types";

type Props = {
  src: string;
  save: (url: string) => Promise<void>;
  firebase: any;
  minCropBoxHeight?: number;
  minCropBoxWidth?: number;
  initialAspectRatio?: number;
  aspectRatio?: number;
};

export const CropperModal: React.FC<Props> = ({
  src,
  save,
  firebase,
  minCropBoxHeight,
  minCropBoxWidth,
  initialAspectRatio,
  aspectRatio,
}) => {
  const [cropper, setCropper] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useTypedSelector((state) => state.firebase.auth);
  const dispatch = useDispatch();
  const upload = async (content: string): Promise<string> => {
    let file = dataURLtoFile(content);
    //@ts-ignore
    let fileName = uuid() + '.' + (FileMimeType[file.type] || '.jpg');
    const uploadTask = await firebase.uploadFile(
      'logo/' + (auth?.uid + '/') + fileName,
      file
    );
    return (
      process.env.REACT_APP_IMGIX + '/logo/' + (auth?.uid + '/') + fileName
    );
    // return await uploadTask.uploadTaskSnapshot.ref.getDownloadURL();
  };
  console.log(minCropBoxWidth);
  return (
    <GeneralModal disableOutsideClick={true}>
      <Cropper
        src={src}
        style={{ height: 500, width: '100%' }}
        initialAspectRatio={initialAspectRatio || 1}
        viewMode={1}
        guides={false}
        minCropBoxHeight={minCropBoxHeight || 400}
        minCropBoxWidth={minCropBoxWidth}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false}
        aspectRatio={aspectRatio}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
      />
      <div className={s.cropAction}>
        <Button
          className={'gray'}
          onClick={() => {
            dispatch(hideModal());
          }}
        >
          Hætta við
        </Button>
        <Button
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            await save(await upload(cropper.getCroppedCanvas().toDataURL()));
            setIsLoading(false);
            dispatch(hideModal());
          }}
        >
          {isLoading ? 'Hinkraðu...' : 'Vista'}
        </Button>
      </div>
    </GeneralModal>
  );
};
