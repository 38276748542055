import { Episode, Podcast } from '../types';
import firebase from 'firebase/compat/app';

export default class EpisodeBusiness {
  constructor(
    private db: firebase.firestore.Firestore,
    private userId: string
  ) {}
  async create(episode: Episode) {
    return this.db.collection('episodes').add({
      ...episode,
      active: true,
      userId: this.userId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      subscribers: [],
    });
  }

  async update(episode: Episode) {
    const episodeRef = this.db.collection('episodes').doc(episode.id);
    await episodeRef.update({
      ...episode,
    });
  }
}
