import React from 'react';
import s from './TogglePodcast.module.css';
import Switch from "react-switch";

type Props = {
  onToggle: () => void;
  checked: boolean;
}

export const TogglePodcast: React.FC<Props> = ({ onToggle, checked }) => {
  return <div className={s.container}>
    <label htmlFor="material-switch">
      <span className={s.label}>{checked ? 'Hlaðvarpið er virkt' : 'Hlaðvarpið er óvirkt'}</span>
      <Switch
        checked={checked}
        onChange={onToggle}
        onColor="#7857C8"
        onHandleColor="#211148"
        handleDiameter={30}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
        className="react-switch"
        id="material-switch"
      />
    </label>
  </div>
};
