import React from 'react';
import s from './VisirPrivacy.module.css';

type Props = {};
export const VisirPrivacy: React.FC<Props> = () => {
  return (
    <div className={s.container}>
      <h1>Áskriftarskilmálar fyrir Tal á Vísi</h1>
      <ol>
        <li>
          Áskrift veitir áskrifanda aðgang að þeim þáttum og/eða áskriftum sem
          áskrifandi hefur valið að greiða fyrir. Með því að samþykkja þessa
          skilmála, ábyrgist áskrifandi að hafa heimild og lagalegan rétt til að
          undirgangast samning þennan samkvæmt lögum og samþykkja að vera
          bundinn af skilmálunum.
        </li>
        <li>
          Áskriftin er einungis til heimilis- og einkanota. Óheimilt er að
          dreifa áskriftinni með einhverjum þeim hætti að hún nýtist öðrum sem
          ekki eru skráðir notendur þjónustunnar. Eins er áskrifanda óheimilt að
          taka upp efni úr dagskrá nema nema að fengnu leyfi þáttastjórnenda.
        </li>
        <li>
          Áskriftir eru aðgengilegar á síðum TAL (tal.visir.is) og eru áskriftir
          greiddar fyrir fram, einn mánuð í senn. Áskrifandi getur sagt upp
          áskrift að einstöku efni hvenær sem er en hefur samt aðgengi að fyrir
          fram keyptu efni þar til áskriftinni lýkur.
        </li>
        <li>
          Kjósi áskrifandi að segja upp áskrift, getur hann gert það í gegnum
          Mitt Svæði á síðum TAL (tal.visir.is). Uppsögn tekur gildi þegar að sá
          mánuður sem viðskiptavinur hefur þegar greitt fyrir er búinn. Öll
          ábyrgð á að slíkar tilkynningar berist til Sýn hf. á réttan hátt og á
          réttum tíma liggur hjá áskrifanda.
        </li>
        <li>
          Áskriftargjald hvers mánaðar ber áskrifanda að greiða fyrir fram
          samkvæmt þeim verðskrárreglum sem eru í gildi á hverjum tíma hjá TAL
          (Sýn hf.). Verðskrá getur tekið breytingum á samningstíma. Áskrift
          getur gildi á þeim degi sem pöntun er framkvæmd.
        </li>
        <li>
          Ef áskrifandi hefur athugasemdir við greiðslu verður hann að láta vita
          um þær án tafar og eigi síðar en greiðsla næsta mánaðar er greidd. Að
          öðrum kosti telst greiðslan samþykkt.
        </li>
        <li>
          TAL (Sýn hf.) virðir almenna skilmála um varðveislu kortanúmera frá
          færsluhirðingafyrirtækjum/kortaútgefendum en að öðru leyti gilda
          ákvæði persónuverndarstefnu Sýnar hf. um meðferð persónuupplýsinga sem
          teljast órjúfanlegur hluti skilmála þessara.
        </li>
        <li>
          TAL (Sýn hf.) áskilur sér allan rétt til að breyta fyrirvaralaust
          áskriftarleiðum sem eru í boði hverju sinni og einnig allan rétt til
          að breyta fyrirvaralaust samsetningum áskriftarpakka.
        </li>
        <li>
          Allur réttur á myndefni, hljóðefni, ljósmyndum, grafík og öðru
          höfundarréttarvörðu efni er keypt undir samningi af Sýn og tilheyrir
          samstarfsaðilum félagsins, efniseigendunum. Efnið sem viðskiptavinir
          geta nálgast gegnum TAL, þar með talið höfundarréttarvarið efni innan
          efnisins, er eign efniseiganda og samstarfsaðila þeirra og er varið af
          höfundarréttarlögum sem og annarra höfundarréttarsamtaka víðs vegar um
          heim. TAL (Sýn hf.), efniseigendur og samstarfsaðilar efniseigenda
          veita viðskiptavinum Sýnar engin réttindi gagnvart því efni sem þeir
          kunna að eiga viðskipti með við Sýn.
        </li>
        <li>
          TAL (Sýn hf.) er heimilt að bjóða upp á samninga þar sem áskrifandi
          skuldbindur sig samkvæmt samningi beggja aðila. Ef áskrifandi segir
          upp slíkum samningi á samningstíma áskilur TAL (Sýn hf.) sér rétt til
          að krefja áskrifanda um þau mánaðargjöld sem ógreidd eru af
          samningstímanum.
        </li>
        <li>
          Brjóti áskrifandi gegn áskriftarskilmálum þessum, til dæmis, en
          takmarkast ekki við, að reynt sé að komast hjá gjaldtöku á óheimilan
          hátt eða koma gjaldskyldu yfir á óskyldan eða ógjaldfæran aðila, er
          TAL (Sýn hf.) heimilt, án fyrirvara, að loka á áskrift og krefjast
          greiðslu sem TAL (Sýn hf.) áætlar að af sanngirni bæti fyrir brot
          áskrifanda. Þá kann TAL (Sýn hf.) að leggja fram kæru til lögreglu
          vegna slíkrar háttsemi.
        </li>
        <li>
          TAL (Sýn hf.) hefur heimild til þess að breyta áskriftarskilmálum.
          Allar breytingar á skilmálum skulu tilkynntar með skýrum hætti með
          a.m.k. mánaðar fyrirvara á heimasíðu TAL (Sýn hf.) og/eða með
          tölvupósti til áskrifenda. Þar skal kynnt í hverju breytingar felast
          og eftir atvikum veittar upplýsingar um rétt áskrifanda til þess að
          segja upp gildandi samningi vegna breytinganna.
        </li>
        <li>
          TAL (Sýn hf.) áskilur sér rétt að vísa áskrifanda úr viðskiptum sýni
          hann ítrekaða ósæmilega hegðun í garð starfsfólks. Með ósæmilegri
          hegðun er átt við t.d. (en ekki eingöngu) ógnanir af einhverjum toga
          eða hótanir af einhverjum toga. Einnig er heimilt að vísa áskrifanda
          úr viðskiptum af öðrum ástæðum, svo sem vegna verulegra vanskila.
        </li>
        <li>
          TAL (Sýn hf.) áskilur sér rétt til að breyta skilmálum þessum vegna
          nýrra áskriftarleiða, án þess að tilkynna öllum áskrifendum um þær
          breytingar enda hafa þær breytingar ekki áhrif á gildandi áskriftir.
        </li>
        <li>
          Rísi upp ágreiningur um túlkun og skýringu á skilmálum þessum skal
          leitast við að ná sáttum með heiðarleika og sanngirni að leiðarljósi.
          Getur áskrifandi sent TAL (Sýn hf.) kvörtun og eftir atvikum rekið mál
          fyrir Héraðsdómi Reykjavíkur.
        </li>
      </ol>
    </div>
  );
};
