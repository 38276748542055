import React from 'react';
import s from './NoItems.module.css';

type Props = {
  message: string;
}

export const NoItems: React.FC<Props> = ({ message }) => {
  return <div className={s.container}>{message}</div>
};
