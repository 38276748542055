import { default as cn } from 'classnames';
import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import clientConfig from '../../client-config';
import { useTypedSelector } from '../../redux/reducers';
import { error } from '../../utils/Notifications';
import {
  readFilesFromFileList,
  uploadAttachment,
  uuid,
} from '../../utils/utilities';
import { Button } from '../button/Button';
import { PardusTextareaAutosize } from '../textarea-autosize/PardusTextareaAutosize';
import {
  FeedAttachments,
  FeedPostAttachment,
  FeedPostAttachmentType,
} from './FeedAttachments';
import s from './PostToFeed.module.css';
import { call } from '../../utils/ApiManager';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modal';
import { ModalType } from '../../types';
import { ProgressBar } from 'react-bootstrap';

type Props = {
  podcastId: string;
  userId: string;
};

const UploadImageButton: React.FC<{
  readFiles: (files: FileList) => void;
}> = ({ readFiles }) => {
  return (
    <>
      <label htmlFor="images" className={s.uploadImageButton} />
      <input
        className={s.hide}
        type="file"
        id="images"
        accept="image/*"
        onChange={(e) => readFiles(e.target.files as FileList)}
        multiple
      />
    </>
  );
};

const UploadVideoButton: React.FC<{
  readFiles: (files: FileList) => void;
}> = ({ readFiles }) => {
  return (
    <>
      <label htmlFor="video" className={s.uploadVideoButton} />
      <input
        className={s.hide}
        type="file"
        id="video"
        accept="video/*"
        onChange={(e) => readFiles(e.target.files as FileList)}
      />
    </>
  );
};

const EmbedVimeoButton: React.FC<{
  setVimeoUrl: (url: string) => void;
}> = ({ setVimeoUrl }) => {
  const dispatch = useDispatch();
  return (
    <div
      className={s.vimeoButton}
      onClick={() => {
        dispatch(
          showModal(ModalType.VIMEO_URL, {
            callback: (url: string) => setVimeoUrl(url),
          })
        );
      }}
    />
  );
};

const EmbedKlippaributton: React.FC<{
  setKlippariUrl: (url: string) => void;
}> = ({ setKlippariUrl }) => {
  const dispatch = useDispatch();
  return (
    <div
      className={s.klippariButton}
      onClick={() => {
        dispatch(
          showModal(ModalType.KLIPPARI_URL, {
            callback: (url: string) => setKlippariUrl(url),
          })
        );
      }}
    />
  );
};

export const PostToFeed: React.FC<Props> = ({ podcastId, userId }) => {
  const [content, setContent] = useState<string>('');
  const [allowComments, setAllowComments] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<FeedPostAttachment[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const firebase = useFirebase();
  const auth = useTypedSelector((state) => state.firebase.auth);
  const uploadFiles = async (attachmentsList: FeedPostAttachment[]) => {
    attachmentsList.forEach((a) => {
      uploadAttachment(
        a.file as File,
        firebase,
        auth?.uid,
        a.type === 'VIDEO',
        setUploadProgress
      ).then((url) => {
        a.isUploading = false;
        a.url = url;
        setAttachments([...attachmentsList]);
      });
    });
  };

  const readFiles = async (
    files: FileList,
    type: FeedPostAttachmentType,
    inputId: string
  ) => {
    const maxFileSizeGb = 4;
    const fileList = await readFilesFromFileList(files, inputId);
    const sizeMb =
      fileList.reduce((a, b) => a + b.size, 0) / 1024 / 1024 / 1024;
    if (sizeMb > maxFileSizeGb) {
      return error('Skrárnar mega ekki fara yfir ' + maxFileSizeGb + ' Gb');
    }
    const attachmentsList = fileList.map((f) => ({
      id: uuid(),
      isUploading: true,
      name: f.name,
      file: f.file,
      type,
    }));
    setAttachments(attachmentsList);
    await uploadFiles(attachmentsList);
  };
  const post = async () => {
    if (!content || attachments.findIndex((a) => a.isUploading) >= 0) {
      return;
    }
    const feedType = !attachments.length
      ? 'TEXT'
      : attachments[0].type === 'IMAGE'
      ? 'IMAGE'
      : 'VIDEO';
    setIsLoading(true);
    await call('/feeds')({
      userId,
      content,
      attachments: attachments.map((a) => ({
        ...a,
        content: null,
        file: null,
      })),
      allowComments,
      feedType,
      podcastId,
    });
    setIsLoading(false);
    setContent('');
    setAttachments([]);
  };
  const addVimeoFile = async (url: string) => {
    if (url && url.startsWith('https://vimeo.com')) {
      const id = uuid();
      const attachment = {
        isUploading: true,
        id,
        type: 'VIMEO' as const,
        vimeo: {
          url,
        },
      } as any;
      const newAttachments = [attachment];
      setAttachments([...newAttachments]);
      const thumbnailsData = await call('/vimeo/thumbnails')({ url });
      if (!thumbnailsData || !thumbnailsData.thumbnails.length) {
        error('Ekki tókst að sækja myndband.');
        setAttachments(attachments);
        return;
      }
      if ((window as any).uploadTask) {
        (window as any).uploadTask.cancel();
        (window as any).uploadTask = null;
      }
      attachment.thumbnail = thumbnailsData.thumbnails[0].link;
      attachment.isUploading = false;
      setAttachments([...newAttachments]);
    } else {
      error('Röng slóð að myndbandi.');
    }
  };
  const addKlippariFile = async (url: string) => {
    if (url && url.startsWith('https://')) {
      const id = uuid();
      if ((window as any).uploadTask) {
        (window as any).uploadTask.cancel();
        (window as any).uploadTask = null;
      }
      setAttachments([
        {
          isUploading: false,
          id,
          type: 'VIDEO' as const,
          klippari: {
            url,
          },
        },
      ]);
    } else {
      error('Röng slóð að myndbandi. Notið https.');
    }
  };
  return (
    <div
      className={cn(s.container, { [s.visir]: clientConfig.name === 'visir' })}
    >
      <div className={s.top}>Ný færsla</div>
      <div className={s.content}>
        <PardusTextareaAutosize
          value={content}
          maxRows={20}
          minRows={3}
          placeholder={'Skrifaðu færslu'}
          onChange={(e) => setContent(e.target.value)}
        />
      </div>
      <FeedAttachments
        attachments={attachments}
        setAttachments={setAttachments}
      />
      {!!uploadProgress &&
        uploadProgress < 100 &&
        attachments.length > 0 &&
        attachments[0].type === 'VIDEO' && (
          <div className={s.progress}>
            <ProgressBar
              now={uploadProgress}
              label={`${Math.round(uploadProgress)}%`}
            />
          </div>
        )}
      <div className={s.bottom}>
        <div style={{ flex: '1 1 0%' }}>
          <UploadImageButton
            readFiles={(files: FileList) => readFiles(files, 'IMAGE', 'images')}
          />
          <UploadVideoButton
            readFiles={(files: FileList) => readFiles(files, 'VIDEO', 'video')}
          />
          <EmbedVimeoButton setVimeoUrl={addVimeoFile} />
          <EmbedKlippaributton setKlippariUrl={addKlippariFile} />
          <span className={s.allowComments}>
            <input
              id={'allow-comments'}
              type={'checkbox'}
              checked={allowComments}
              onChange={(e) => setAllowComments(e.target.checked)}
            />
            <label htmlFor={'allow-comments'}>Athugasemdir</label>
          </span>
        </div>
        <Button
          disabled={
            isLoading ||
            !content ||
            attachments.findIndex((a) => a.isUploading) >= 0
          }
          onClick={post}
        >
          {isLoading ? 'Hinkraðu...' : 'Vista'}
        </Button>
      </div>
    </div>
  );
};
