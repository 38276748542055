import type { PropsWithChildren } from 'react';
import { Button } from '../button/Button';
import { Link } from 'react-router-dom';

export const RssContainer = () => {
  return (
    <div
      className={
        'tw-p-8 tw-border tw-space-y-4 tw-border-[#cecece] tw-rounded-md tw-mb-4'
      }
    >
      <h1 className={'tw-text-lg tw-font-600'}>Hlustaðu í hlaðvarpsappi</h1>
      <div>
        RSS hlekkir eru tengdir við þinn aðgang og gera þér kleift að hlusta á
        áskriftarþætti í nánast hvaða hlaðvarpsappi sem er.
      </div>
      <Link to={'/rss'} className={'tw-inline-block'}>
        <Button>Tengjast appi</Button>
      </Link>
    </div>
  );
};
