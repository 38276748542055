import firebase from 'firebase/compat/app';
import { call } from '../utils/ApiManager';
import { error } from '../utils/Notifications';
import { LiveEventComment } from '../types';

export default class LivesBusiness {
  constructor(private db?: firebase.firestore.Firestore) {}
  async createLiveEvent(podcastId: string) {
    try {
      await call(`/lives/create-live-event`)({ podcastId });
    } catch (err: any) {
      console.error(err);
      error(err.message || 'Óvænt villa kom upp. Reyndu aftur síðar.');
    }
  }
  async toggleLiveStream(podcastId: string, isLiveStreaming: boolean) {
    const podcastRef = await this.db?.collection('podcasts').doc(podcastId);
    if (!podcastRef) {
      throw new Error('Hlaðvarp fannst ekki.');
    }
    if (isLiveStreaming) {
      await podcastRef.update({
        isLiveStreaming,
        lastLiveStreamingStart: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } else {
      await podcastRef.update({
        isLiveStreaming,
      });
    }
  }

  async postComment(
    liveEventId: string,
    comment: LiveEventComment,
    userId: string,
    podcastId: string
  ) {
    await this.db?.collection('liveeventscomments').add({
      ...comment,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      userId: userId,
      liveEventId,
      podcastId,
    });
  }

  async deleteComment(commentId: string) {
    const comment = await this.db
      ?.collection('liveeventscomments')
      .doc(commentId);
    await comment?.delete();
  }
}
