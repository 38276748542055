import React, { useState } from 'react';
import s from './Login.module.css';
import { TextInput } from '../components/text-input/TextInput';
import { Button } from '../components/button/Button';
import { useFirebase } from 'react-redux-firebase';
import { validateEmail } from '../utils/utilities';
import { error, success, warn } from '../utils/Notifications';
import { useTypedSelector } from '../redux/reducers';
import { Link, Redirect } from 'react-router-dom';
import { call } from '../utils/ApiManager';
import { PageContainer } from '../components/page-container/PageContainer';
export const Register: React.FC<any> = (props) => {
  const [email, setEmail] = useState<string>('');
  const [confirmEmail, setConfirmEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [privacySelected, setPrivacySelected] = useState<boolean>(true);

  const firebase = useFirebase();
  const auth = useTypedSelector((state) => state.firebase.auth);
  const register = async (email: string, password: string): Promise<any> => {
    if (!email) {
      return warn('Netfang vantar.');
    }
    if (email !== confirmEmail) {
      return warn('Netföngin passa ekki saman.');
    }
    if (!password || !repeatPassword || password.length < 6) {
      return warn('Lykilorð verður að vera a.m.k. 6 stafir að lengd.');
    }
    if (password !== repeatPassword) {
      return warn('Lykilorðin passa ekki saman.');
    }
    setIsLoading(true);
    try {
      await call('/register', true)({ email, password });
      await firebase.login({
        email: email.toLowerCase().trim(),
        password,
      });
      success('Takk fyrir skráninguna. Vertu velkomin/n í Pardus!');
    } catch (err: any) {
      error(err.message || 'Nýskráning tókst ekki');
    }
    setIsLoading(false);
  };

  return (
    <PageContainer>
      {auth.isLoaded && auth.isEmpty && (
        <div className={s.flex}>
          <div className={s.left} />
          <div className={s.right}>
            <div className={s.register}>
              Notandi nú þegar?{' '}
              <Link to={'/login'} className={s.registerLink}>
                Innskráning
              </Link>
            </div>
            <div className={s.loginContainer}>
              <div className={s.loginContent}>
                <div className={s.title}>Nýskráning</div>
                <p>Fylltu út upplýsingar hér að neðan til að stofna aðgang</p>
                <div className={s.inputs}>
                  <TextInput
                    autofocus={true}
                    value={email}
                    label={'Netfang'}
                    placeholder={'Skrifaðu netfangið þitt'}
                    onChange={(val) => setEmail(val)}
                  />
                  <div style={{ marginTop: '20px' }}>
                    <TextInput
                      value={confirmEmail}
                      label={'Netfang aftur'}
                      placeholder={'Netfangið þitt aftur'}
                      onChange={(val) => setConfirmEmail(val)}
                    />
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <TextInput
                      value={password}
                      label={'Lykilorð'}
                      type={'password'}
                      placeholder={'Minnst 6 stafir að lengd'}
                      onChange={(val) => setPassword(val)}
                    />
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <TextInput
                      value={repeatPassword}
                      label={'Lykilorð aftur'}
                      type={'password'}
                      placeholder={'Lykilorð aftur'}
                      onChange={(val) => setRepeatPassword(val)}
                      onEnter={() => register(email, password)}
                    />
                  </div>
                  <div className={s.privacy}>
                    <input
                      checked={privacySelected}
                      onChange={(e) => setPrivacySelected(e.target.checked)}
                      id="privacy-checkbox"
                      type={'checkbox'}
                    />{' '}
                    <label htmlFor={'privacy-checkbox'}>
                      Ég samþykki <Link to={'privacy'}>skilmála</Link> Pardus.
                    </label>
                  </div>
                  <div className={s.actions}>
                    <Button to={'/login'} className={'gray'}>
                      Til baka
                    </Button>
                    <Button
                      disabled={
                        isLoading ||
                        !email ||
                        !password ||
                        !privacySelected ||
                        !confirmEmail
                      }
                      onClick={() => register(email, password)}
                    >
                      {isLoading ? 'Hinkraðu...' : 'Stofna aðgang'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {auth.isLoaded && !auth.isEmpty && (
        <Redirect to={props.location.state?.from || '/'} />
      )}
    </PageContainer>
  );
};
