import React, { useState } from 'react';
import { PodcastForm } from '../components/podcast-form/PodcastForm';
import { Podcast } from '../types';
import PodcastBusiness from '../business/PodcastBusiness';
import { error, success } from '../utils/Notifications';
import { Redirect } from 'react-router';
import withPodcast from '../utils/withPodcast';
import { PageContainer } from '../components/page-container/PageContainer';

const EditPodcast: React.FC<any> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');
  const { firestore, profile, podcast } = props;
  const save = async (podcast: Podcast) => {
    setIsLoading(true);
    const podcastBusiness = new PodcastBusiness(
      firestore,
      profile?.userId,
      profile?.email
    );
    try {
      await podcastBusiness.update(podcast);
      setIsLoading(false);
      success('Hlaðvarpi hefur verið breytt.');
      setRedirect('/podcasts');
    } catch (err: any) {
      error(err.message || 'Ekki tókst að breyta hlaðvarpi.');
      setIsLoading(false);
    }
  };
  return (
    <PageContainer>
      <PodcastForm
        title={'Breyta hlaðvarpi'}
        save={save}
        isLoading={isLoading}
        podcast={podcast}
      />
      {redirect && <Redirect to={redirect} />}
    </PageContainer>
  );
};

export default withPodcast(EditPodcast);
