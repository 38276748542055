import React from 'react';
import withPodcast from '../utils/withPodcast';
import {
  ExtendedFirebaseInstance,
  ExtendedFirestoreInstance,
  isEmpty,
  isLoaded,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { Episode, ManagePodcastTab, Podcast } from '../types';
import { TopNav } from '../components/top-nav/TopNav';
import { ManagePodcast } from '../components/manage-podcast/ManagePodcast';
import { useTypedSelector } from '../redux/reducers';
import { NoItems } from '../components/no-items/NoItems';
import { Button } from '../components/button/Button';
import { ManageEpisodesLists } from '../components/manage-episodes-list/ManageEpisodesList';
import EpisodeBusiness from '../business/EpisodeBusiness';
import { Spinner } from '../components/spinner/Spinner';
import { PageContainer } from '../components/page-container/PageContainer';

const ManagePodcastUploads: React.FC<any> = (props) => {
  const {
    firebase,
    firestore,
    podcast,
    profile,
  }: {
    firebase: ExtendedFirebaseInstance;
    firestore: ExtendedFirestoreInstance;
    podcast: Podcast;
    profile: any;
  } = props;
  useFirestoreConnect({
    collection: 'episodes',
    orderBy: ['createdAt', 'desc'],
    where: profile.isAdmin
      ? [
          ['podcastId', '==', podcast.id],
          ['active', '==', true],
        ]
      : [
          ['podcastId', '==', podcast.id],
          ['userId', '==', profile.userId],
          ['active', '==', true],
        ],
  });

  const episodes = useTypedSelector(
    (state) => state.firestore.ordered.episodes
  );
  const deleteEpisode = (episode: Episode) => {
    return async () => {
      const episodeBusiness = new EpisodeBusiness(firestore, profile?.userId);
      await episodeBusiness.update({ id: episode.id, active: false });
    };
  };
  return (
    <PageContainer>
      <TopNav />
      <ManagePodcast context={ManagePodcastTab.UPLOADS} podcast={podcast}>
        {isLoaded(episodes) && isEmpty(episodes) ? (
          <>
            <NoItems message={'Þú hefur ekki hlaðið upp neinum þætti ennþá.'} />
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <Button to={`/podcasts/uploads/${podcast.id}/new`}>
                + Nýr þáttur
              </Button>
            </div>
          </>
        ) : (
          <>
            {isLoaded(episodes) ? (
              <ManageEpisodesLists
                deleteEpisode={deleteEpisode}
                episodes={episodes || []}
                podcastId={podcast.id as string}
              />
            ) : (
              <Spinner />
            )}
          </>
        )}
      </ManagePodcast>
    </PageContainer>
  );
};

export default withPodcast(ManagePodcastUploads);
