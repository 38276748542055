import { RssLink } from '../types';
import firebase from 'firebase/compat/app';

export default class RssBusiness {
  constructor(private db: firebase.firestore.Firestore) {}

  async closeLink(link: RssLink): Promise<void> {
    await this.db.collection('rsslinks').doc(link.id).update({
      active: false,
    });
  }
  async openLink(link: RssLink): Promise<void> {
    await this.db.collection('rsslinks').doc(link.id).update({
      active: true,
    });
  }
}
