import withPodcast from '../utils/withPodcast';
import { PageContainer } from '../components/page-container/PageContainer';
import { TopNav } from '../components/top-nav/TopNav';
import { ManagePodcast } from '../components/manage-podcast/ManagePodcast';
import { ManagePodcastTab, ModalType, Podcast } from '../types';
import React, { useState } from 'react';
import { PardusTextareaAutosize } from '../components/textarea-autosize/PardusTextareaAutosize';
import { Button } from '../components/button/Button';
import s from './ManagePodcastEmail.module.css';
import { TextInput } from '../components/text-input/TextInput';
import { useDispatch } from 'react-redux';
import { showModal } from '../redux/actions/modal';
import { error, success } from '../utils/Notifications';
import { BoundTextInput } from '../components/bound-text-input/BoundTextInput';
import { call } from '../utils/ApiManager';

const ManagePodcastEmail: React.FC<any> = (props) => {
  const [content, setContent] = useState('');
  const [subject, setSubject] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {
    podcast,
    profile,
  }: {
    podcast: Podcast;
    profile: any;
  } = props;

  const sendEmails = async () => {
    setIsLoading(true);
    try {
      await call('/sendnotification')({
        podcastId: podcast.id,
        subject,
        content,
      });
      success('Tilkynning hefur verið send.');
      setContent('');
      setSubject('');
    } catch (err: any) {
      error(err.message || 'Ekki tókst að senda tilkynningu.');
    } finally {
      setIsLoading(false);
    }
  };

  const dispatch = useDispatch();
  return (
    <PageContainer>
      <TopNav />
      <ManagePodcast context={ManagePodcastTab.EMAIL} podcast={podcast}>
        <div className={s.top}>
          Hér getur þú sent út tilkynningu í tölvupósti til allra virkra
          áskrifenda.
        </div>
        <BoundTextInput
          label={'Fyrirsögn tölvupósts (e. subject)'}
          placeholder={'Skrifaðu fyrirsögn'}
          onChange={(e) => setSubject(e.target.value)}
          value={subject}
        />
        <div className={s.content}>
          <PardusTextareaAutosize
            value={content}
            maxRows={30}
            minRows={8}
            disabled={isLoading}
            placeholder={'Skrifaðu hér...'}
            onChange={(e) => setContent(e.target.value)}
            gray={true}
          />
        </div>
        <div className={s.button}>
          <Button
            disabled={!content || !subject || isLoading}
            onClick={() =>
              dispatch(
                showModal(ModalType.CONFIRM_MODAL, {
                  color: 'green',
                  title: 'Senda tilkynningu?',
                  body: 'Tilkynning verður send í tölvupósti á alla virka áskrifendur',
                  actionText: 'Staðfesta',
                  action: () => sendEmails(),
                })
              )
            }
          >
            Senda tölvupóst til allra áskrifenda
          </Button>
        </div>
      </ManagePodcast>
    </PageContainer>
  );
};

export default withPodcast(ManagePodcastEmail);
