import React, { useState } from 'react';
import s from './Login.module.css';
import { TextInput } from '../components/text-input/TextInput';
import { Button } from '../components/button/Button';
import { useFirebase } from 'react-redux-firebase';
import { error, success, warn } from '../utils/Notifications';
import { useTypedSelector } from '../redux/reducers';
import { Link, Redirect } from 'react-router-dom';

export const ForgotPassword: React.FC<any> = (props) => {
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);
  const firebase = useFirebase();
  const auth = useTypedSelector((state) => state.firebase.auth);
  const proceed = async (email: string): Promise<any> => {
    if (!email) {
      return warn('Netfang vantar.');
    }
    setIsLoading(true);
    try {
      await firebase.resetPassword(email);
      setEmail('');
      setIsLoading(false);
      setRedirectToLogin(true);
      success(
        'Við höfum sent þér tölvupóst með leiðbeiningum til að endursetja lykilorðið þitt.'
      );
    } catch (err: any) {
      error(err.message || 'Tókst ekki að senda tölvupóst.');
      setIsLoading(false);
    }
  };

  return (
    <>
      {auth.isLoaded && auth.isEmpty && (
        <div className={s.flex}>
          <div className={s.left} />
          <div className={s.right}>
            <div className={s.register}>
              <Link to={'/login'} className={s.registerLink}>
                Innskráning
              </Link>
            </div>
            <div className={s.loginContainer}>
              <div className={s.loginContent}>
                <div className={s.title}>Gleymt lykilorð</div>
                <p>Skrifaðu netfangið þitt til að halda áfram</p>
                <div className={s.inputs}>
                  <TextInput
                    autofocus={true}
                    value={email}
                    label={''}
                    placeholder={'Netfang'}
                    onChange={(val) => setEmail(val)}
                    onEnter={() => proceed(email)}
                  />

                  <div className={s.actions}>
                    <Button to={'/login'} className={'gray'}>
                      Til baka
                    </Button>
                    <Button
                      disabled={isLoading || !email}
                      onClick={() => proceed(email)}
                    >
                      {isLoading ? 'Hinkraðu...' : 'Áfram'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {auth.isLoaded && !auth.isEmpty && (
        <Redirect to={props.location.state?.from || '/'} />
      )}
      {redirectToLogin && <Redirect to={'/login'} />}
    </>
  );
};
