import React, { useEffect, useState } from "react";
import s from "./TextInput.module.css";
import classNames from "classnames";
import {uuid} from "../../utils/utilities";

type Props = {
  id?: string;
  label: string;
  placeholder: string;
  className?: string;
  type?: string;
  disabled?: boolean;
  autofocus?: boolean;
  onChange: (s: string) => void;
  onEnter?: () => void;
  isNumeric?: boolean;
  value?: string;
  maxLength?: number;
  postfix?: string;
};

export const TextInput: React.FC<Props> = props => {
  const uid = uuid();
  const inputId = props.id || uid;
  const [value, setValue] = useState(props.value || "");
  useEffect(() => {
    if (props.autofocus) {
      document.getElementById(inputId)?.focus();
    }
  }, []);
  return (
    <div
      className={classNames({
        [s.textInput]: true,
        [s.disabled]: props.disabled,
        [props.className || ""]: !!props.className
      })}
    >
      {props.label && <label>{props.label}</label>}
      <div className={s.inputContainer}>
        <input
          id={inputId}
          value={value}
          autoComplete={"off"}
          type={props.type ? props.type : "text"}
          onChange={e => {
            const re = /^[0-9\b]+$/;
            if (
              props.isNumeric &&
              e.target.value !== "" &&
              !re.test(e.target.value)
            ) {
              return;
            }
            props.onChange(e.target.value);
            setValue(e.target.value);
          }}
          placeholder={props.placeholder}
          onKeyDown={e =>
            props.onEnter && e.key === "Enter" ? props.onEnter() : null
          }
          maxLength={props.maxLength}
        />
        {props.postfix && <span className={s.postfix}>{props.postfix}</span>}
      </div>
    </div>
  );
};
