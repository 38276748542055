import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import clientConfig from '../client-config';

let api = process.env.REACT_APP_REST_API;

export const call = (
  endpoint: string,
  publicEndpoint: boolean = false,
  customHeaders?: any,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' = 'GET'
) => {
  return async (data?: any) => {
    const token = await firebase.auth().currentUser?.getIdToken(true);
    const headers: any = {
      Authorization: token || '',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': 'is',
      'client-name': clientConfig.name,
    };
    if (customHeaders) {
      Object.keys(customHeaders).forEach((key) => {
        if (customHeaders.hasOwnProperty(key)) {
          headers[key] = customHeaders[key];
        }
      });
    }
    let opts: any = {
      method: method === 'GET' && data ? 'POST' : method,
      headers,
    };
    if (data) {
      opts.body = JSON.stringify(data);
    }
    const response = await fetch(
      (publicEndpoint ? api + '/public' : api + '/private') + endpoint,
      opts
    );
    const res = await response.json();
    if (response.status === 401) {
      await firebase.auth().signOut();
      throw new Error('Vinsamlegast skráðu þig aftur inn.');
    } else if (response.status >= 400) {
      throw new Error(res.message || 'Óvænt villa kom upp');
    }
    return res;
  };
};
