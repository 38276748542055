import React from 'react';
import s from './Center.module.css';

type Props = {
  children?: React.ReactNode;
  onlyText?: boolean;
};

export const Center: React.FC<Props> = ({ children,onlyText }) => {
  if (onlyText) {
    return <div className={s.onlyText}>{children}</div>
  }
  return <div className={s.center}>{children}</div>
};
