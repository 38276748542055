import React, { InputHTMLAttributes } from 'react';
import s from './BoundTextInput.module.css';
import classNames from 'classnames';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeholder: string;
  disabled?: boolean;
}

export const BoundTextInput: React.FC<Props> = ({
  label,
  placeholder,
  disabled,
  ...rest
}) => {
  return (
    <div
      className={classNames({
        [s.textInput]: true,
        [s.disabled]: disabled,
      })}
    >
      {label && <label>{label}</label>}
      <div className={s.inputContainer}>
        <input autoComplete={'off'} placeholder={placeholder} {...rest} />
      </div>
    </div>
  );
};
