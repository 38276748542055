import React, { useEffect, useState } from 'react';
import s from './PodcastFeedComments.module.css';
import { FeedItem, ModalType, Podcast } from '../../types';
import classNames from 'classnames';
import { PardusTextareaAutosize } from '../textarea-autosize/PardusTextareaAutosize';
import { useTypedSelector } from '../../redux/reducers';
import {
  isEmpty,
  isLoaded,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';
import FeedBusiness from '../../business/FeedBusiness';
import { error } from '../../utils/Notifications';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modal';
import { FeedCommentsList } from '../feed-comments-list/FeedCommentsList';

export const PodcastFeedComments: React.FC<{
  feedId: string;
  slug: string;
  podcastId: string;
}> = ({ feedId, podcastId, slug }) => {
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useTypedSelector((state) => state.firebase.auth);
  const profile = useTypedSelector((state) => state.firebase.profile);
  const dispatch = useDispatch();
  const firestore = useFirestore();

  useFirestoreConnect({
    collection: 'feedcomments',
    where: [['feedId', '==', feedId]],
    storeAs: 'feedcomments-' + feedId,
    orderBy: ['createdAt', 'asc'],
  });

  const comments = useTypedSelector(
    (state) => state.firestore.ordered['feedcomments-' + feedId]
  );
  const [visible, setVisible] = useState<boolean>(true);
  const feedBusiness = new FeedBusiness(firestore, auth.uid);
  const submitComment = async (name?: string) => {
    if (!content) {
      return;
    }
    if (auth.isEmpty) {
      error('Þú verður að skrá þig inn til að skrifa athugasemd.');
      return;
    }
    if (!profile.name && !name) {
      dispatch(
        showModal(ModalType.USERNAME_MODAL, { callback: submitComment })
      );
      return;
    }
    setIsLoading(true);
    try {
      await feedBusiness.postComment(podcastId, feedId, {
        userName: name || profile.name,
        content,
      });
      setContent('');
    } catch (err: any) {
      error(err.message || 'Tókst ekki að vista athugasemd.');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className={classNames({ [s.container]: true, [s.visible]: visible })}>
      <div className={s.toggleBar}>
        <span className={s.commentsText} onClick={() => setVisible(!visible)}>
          {comments?.length || 0}{' '}
          {comments?.length === 1 ? 'athugasemd' : 'athugasemdir'}
        </span>
      </div>
      {visible && (
        <div className={s.commentsContent}>
          <div style={{ marginTop: '5px', marginBottom: '-5px' }}>
            <FeedCommentsList comments={comments} slug={slug} />
            <PardusTextareaAutosize
              value={content}
              maxRows={30}
              minRows={1}
              disabled={isLoading}
              placeholder={'Skrifaðu athugasemd...'}
              onChange={(e) => setContent(e.target.value)}
              onKeyDown={async (e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  await submitComment();
                }
              }}
              gray={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};
