import React, { useMemo, useState } from 'react';
import s from './EpisodeForm.module.css';
import { Center } from '../position/Center';
import { TitleCard } from '../title-card/TitleCard';
import { TextInput } from '../text-input/TextInput';
import { Button } from '../button/Button';
import { Episode, ModalType } from '../../types';
import { UploadSoundFile } from '../upload-sound-file/UploadSoundFile';
import { UploadPicture } from '../upload-picture/UploadPicture';
import { Link } from 'react-router-dom';
import { showModal } from '../../redux/actions/modal';
import { FormCheckbox } from '../form-fields/FormCheckbox';
import { DateTimePickerInput } from '../date-time-picker-input/DateTimePickerInput';
import Switch from 'react-switch';
import { isAfter } from 'date-fns';
import { Textarea } from '../textarea/Textarea';

type Props = {
  episode?: Episode;
  save: (episode: Episode) => void;
  title: string;
  podcastId: string;
  isLoading?: boolean;
  disclaimer?: string;
};

export const EpisodeForm: React.FC<Props> = ({
  episode,
  save,
  title,
  podcastId,
  isLoading,
  disclaimer,
}) => {
  const now = useMemo(() => new Date(), []);
  const [name, setName] = useState<string>(episode?.name || '');
  const [url, setUrl] = useState<string>(episode?.url || '');
  const [img, setImg] = useState<string>(episode?.img || '');
  const [isOpen, setIsOpen] = useState(episode?.isOpen || false);
  const [description, setDescription] = useState(episode?.description || '');
  const [displayDate, setDisplayDate] = useState<Date | null>(
    episode?.displayDate?.toDate() || new Date()
  );
  const [publishType, setPublishType] = useState<'NOW' | 'CUSTOM'>(
    episode?.displayDate && isAfter(episode.displayDate.toDate(), new Date())
      ? 'CUSTOM'
      : 'NOW'
  );
  const onSave = () => {
    const saveObj: any = {
      name,
      url,
      podcastId,
      img,
      isOpen,
      displayDate,
      description,
    };
    if (episode?.id) {
      saveObj.id = episode.id;
    }
    save(saveObj);
  };

  return (
    <Center>
      <TitleCard title={title}>
        <TextInput
          autofocus
          value={name}
          label={'Nafn'}
          placeholder={'Skrifaðu nafn þáttar'}
          onChange={(val) => setName(val)}
        />
        <div className={'tw-mt-4'}>
          <Textarea
            value={description}
            onChange={(val) => setDescription(val)}
            label={'Lýsing'}
            placeholder={'Skrifaðu lýsingu á þættinum'}
          />
        </div>
        <div className={s.inputs}>
          <UploadSoundFile url={url} save={(link) => setUrl(link)} />
          <div style={{ marginTop: '1rem' }}>
            <UploadPicture
              aspectRatio={1}
              url={img}
              label={''}
              save={async (link) => setImg(link)}
            />
          </div>
        </div>
        <div className={s.isOpen}>
          <FormCheckbox
            checked={isOpen}
            onChange={(e: any) => setIsOpen(e.target.checked)}
          >
            Opið efni
          </FormCheckbox>
        </div>
        <div className={'tw-mt-2 tw-flex tw-gap-2 tw-items-center'}>
          <Switch
            checked={publishType === 'CUSTOM'}
            onChange={() => {
              setDisplayDate(new Date());
              setPublishType(publishType === 'NOW' ? 'CUSTOM' : 'NOW');
            }}
            onColor="#7857C8"
            onHandleColor="#211148"
            handleDiameter={15}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={14}
            width={28}
            className="react-switch"
            id="material-switch"
          />
          Tímasetja þáttinn
        </div>
        {publishType === 'CUSTOM' && (
          <div className={'tw-my-2'}>
            <div className={'tw-font-600 tw-mb-2'}>
              Hvenær á þátturinn að koma út?
            </div>
            <DateTimePickerInput
              label={''}
              value={displayDate}
              onChange={(d) => setDisplayDate(d)}
            />
          </div>
        )}
        <div className={s.actions}>
          <Button className={'gray'} to={'/podcasts/uploads/' + podcastId}>
            Til baka
          </Button>
          <Button
            disabled={isLoading || !name || !url || !img}
            onClick={onSave}
          >
            {isLoading ? 'Hinkraðu...' : 'Vista'}
          </Button>
        </div>
        {disclaimer &&
          (publishType === 'NOW' || !displayDate || displayDate < now) && (
            <div className={s.disclaimer}>{disclaimer}</div>
          )}
      </TitleCard>
    </Center>
  );
};
