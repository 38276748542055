import React, { useEffect, useLayoutEffect, useState } from 'react';
import s from './PodcastDetailLive.module.css';
import { PageContainer } from '../components/page-container/PageContainer';
import { TopNav } from '../components/top-nav/TopNav';
import { Container } from 'react-bootstrap';
import withPodcast from '../utils/withPodcast';
import { PodcastHead } from '../components/podcast-head/PodcastHead';
import { Button } from '../components/button/Button';
import { Podcast } from '../types';
import { call } from '../utils/ApiManager';
import { CenteredSpinner } from '../components/spinner/CenteredSpinner';
import { LiveEventComments } from '../components/live-event-comments/LiveEventComments';
import { PlayerSdk } from '@api.video/player-sdk';
import { EmptyIllustration } from '../components/empty-illustration/EmptyIllustration';
import classNames from 'classnames';
import clientConfig from '../client-config';

type Props = {};

const PodcastDetailLive: React.FC<any> = (props) => {
  const {
    match: { params },
  } = props;
  const { podcastId } = params;
  const { podcast }: { podcast: Podcast } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState<{
    id: string;
    player: string;
    hls: string;
    iframe: string;
  } | null>(null);
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      setInfo(await call('/lives/info/' + podcastId)());
      setIsLoading(false);
    };
    getData();
    const $crisp = (window as any).$crisp;
    $crisp?.push(['do', 'chat:hide']);
    return () => {
      $crisp?.push(['do', 'chat:show']);
    };
  }, []);

  return (
    <PageContainer>
      <TopNav />
      <Container>
        <div
          className={classNames(s.container, {
            [s.visir]: clientConfig.name === 'visir',
          })}
        >
          <div className={s.flexContainer}>
            <PodcastHead podcast={podcast} disableLiveLabel={true}>
              <div>
                <Button to={`/${podcast.slug}`} className={'light-purple'}>
                  Til baka á áskriftarsíðu
                </Button>
              </div>
            </PodcastHead>
          </div>
          <div
            className={classNames(s.stream, {
              [s.containerWithoutComments]:
                info && !isLoading && podcast?.disableLiveStreamComments,
              [s.containerWithComments]:
                isLoading || !podcast.disableLiveStreamComments,
            })}
          >
            {isLoading ? (
              <CenteredSpinner />
            ) : podcast.isLiveStreaming ? (
              <iframe
                id={'live-player'}
                src={info?.player}
                width={'100%'}
                frameBorder={0}
                scrolling={'no'}
                allowFullScreen={true}
              />
            ) : (
              <div className={s.emptyScreen}>
                Ekkert streymi í gangi eins og er.
              </div>
            )}
          </div>
          {info && !isLoading && !podcast?.disableLiveStreamComments && (
            <div className={s.comments}>
              <LiveEventComments liveEventId={info.id} podcast={podcast} />
            </div>
          )}
        </div>
      </Container>
    </PageContainer>
  );
};

export default withPodcast(PodcastDetailLive);
