import React from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { FeedItem, ModalType } from '../../types';
import { useTypedSelector } from '../../redux/reducers';
import moment from 'moment';
import s from './PendingFeeds.module.css';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modal';
import { call } from '../../utils/ApiManager';

type Props = {
  podcastId: string;
};
export const PendingFeeds: React.FC<Props> = ({ podcastId }) => {
  const profile = useTypedSelector((state) => state.firebase.profile);
  const auth = useTypedSelector((state) => state.firebase.auth);
  const today = moment().toString();
  const yesterday = moment(today).subtract(1, 'day').toDate();
  const dispatch = useDispatch();
  useFirestoreConnect({
    collection: 'pendingfeeds',
    where: profile.isAdmin
      ? [
          ['podcastId', '==', podcastId],
          ['createdAt', '>', yesterday],
          ['success', '==', false],
        ]
      : [
          ['podcastId', '==', podcastId],
          ['createdAt', '>', yesterday],
          ['success', '==', false],
          ['userId', '==', auth.uid],
        ],
  });
  const pendingFeeds: FeedItem[] = useTypedSelector(
    (state) => state.firestore.ordered.pendingfeeds
  );
  if (!pendingFeeds?.length) {
    return null;
  }
  return (
    <div className={s.container}>
      {pendingFeeds.length} {pendingFeeds.length === 1 ? 'færsla' : 'færslur'} í
      bið -{' '}
      <a
        href={'#'}
        className={s.remove}
        onClick={(e) => {
          e.preventDefault();
          dispatch(
            showModal(ModalType.CONFIRM_MODAL, {
              title: 'Ertu viss?',
              body: 'Viltu fjarlægja færslur í bið?',
              actionText: 'Já',
              action: () => call('/remove-pending-feeds')({ podcastId }),
            })
          );
        }}
      >
        Hætta við
      </a>
    </div>
  );
};
