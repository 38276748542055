import React from 'react';
import { TopNav } from '../components/top-nav/TopNav';
import { PageContainer } from '../components/page-container/PageContainer';
import { Container } from 'react-bootstrap';
import { useTypedSelector } from '../redux/reducers';
import { Spinner } from '../components/spinner/Spinner';
import { PardusRss } from '../components/pardus-rss/PardusRss';
import { PardusRssAdmin } from '../components/pardus-rss-admin/PardusRssAdmin';
import { Center } from '../components/position/Center';

const PardusRssPage: React.FC<any> = (props) => {
  const auth = useTypedSelector((state) => state.firebase.auth);
  const profile = useTypedSelector((state) => state.firebase.profile);
  return (
    <PageContainer>
      <TopNav />
      <Container>
        {!auth?.isLoaded ? (
          <Center>
            <Spinner/>
          </Center>
        ) : profile.isAdmin ? (
          <PardusRssAdmin/>
        ) : (
          <PardusRss/>
        )}
      </Container>
    </PageContainer>
  );
};

export default PardusRssPage;
