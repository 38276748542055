import React, { FC } from 'react';
import s from './PodcastPrice.module.css';
import { formatMoney } from '../../utils/utilities';
import { Podcast } from '../../types';
import cn from 'classnames';
type Props = {
  podcast: Podcast;
  withBorder?: boolean;
};
export const PodcastPrice: FC<Props> = ({ podcast, withBorder }) => {
  const subscriptionPlan = podcast.subscriptionPlans?.length
    ? podcast.subscriptionPlans[0]
    : null;
  if (!subscriptionPlan) {
    return null;
  }
  return (
    <div className={cn(s.price, { [s.withBorder]: withBorder })}>
      {formatMoney(subscriptionPlan.price) + ' kr.'}
      {!podcast.oneTimePurchase && (
        <span>
          {subscriptionPlan.months === 1
            ? 'á mánuði'
            : '/' + subscriptionPlan.months + ' mán.'}
        </span>
      )}
    </div>
  );
};
