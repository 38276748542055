import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import s from './FeedPost.module.css';
import cn from 'classnames';

export const CollapsableText: FC<{
  podcastId: string;
  content: string;
  isLocked?: boolean;
}> = ({ content = '', podcastId, isLocked }) => {
  const [showAllText, setShowAllText] = useState(false);
  const history = useHistory();
  const maxContentSize = 120;
  if (content?.length < maxContentSize) {
    return <>{content}</>;
  }
  const triggerReadMore = () => {
    if (isLocked) {
      history.push('/podcasts/purchase/' + podcastId);
    } else {
      setShowAllText(true);
    }
  };
  return (
    <>
      <div className={s.blurredContent}>
        {!showAllText ? content.substr(0, maxContentSize) + '...' : content}
        {!showAllText && <div className={s.blurContent} />}
      </div>
      {!showAllText && (
        <div
          className={cn(s.readMore, { [s.readMoreLocked]: isLocked })}
          onClick={() => triggerReadMore()}
        >
          Halda áfram að lesa
        </div>
      )}
    </>
  );
};
