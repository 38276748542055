import React, { PropsWithChildren } from 'react';
import s from './HomepageHeader.module.css';
import { HomepageHeaderSearch } from '../homepage-header-search/HomepageHeaderSearch';
import { Button } from '../button/Button';

type Props = PropsWithChildren<{
  search: string;
  setSearch: (val: string) => void;
}>;

export const HomepageHeader: React.FC<Props> = ({
  search,
  setSearch,
  children,
}) => {
  return (
    <div className={s.container}>
      <div className={s.overlay} />
      <div className={s.content}>
        <div className={s.title}>
          Finndu þína <span className={s.bold}>uppáhalds afþreyingu</span>
        </div>
        <div className={s.description}>
          Hlaðvörp, þættir, rafrænir viðburðir og margt fleira á einum stað.
        </div>
        <div className={s.search}>
          <HomepageHeaderSearch search={search} setSearch={setSearch} />
        </div>
      </div>
      {children}
    </div>
  );
};
