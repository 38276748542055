import React from 'react';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useTypedSelector } from '../redux/reducers';
import { FrontCategory, ModalType, Podcast } from '../types';
import { Spinner } from '../components/spinner/Spinner';
import { PageContainer } from '../components/page-container/PageContainer';
import { Button } from '../components/button/Button';
import { OrderableList } from '../components/orderable-list/OrderableList';
import { showModal } from '../redux/actions/modal';
import { useDispatch } from 'react-redux';
import clientConfig from '../client-config';

const ManageCategoryPage: React.FC<any> = (props) => {
  const {
    match: { params },
  } = props;
  const { categoryId } = params;
  const dispatch = useDispatch();
  const firestore = useFirestore();
  useFirestoreConnect({
    collection: 'frontcategories',
    doc: categoryId,
    storeAs: 'category-' + categoryId,
  });
  useFirestoreConnect({
    collection: 'podcasts',
    orderBy: ['createdAt', 'asc'],
    where: [
      ['active', '==', true],
      ['status', '==', 'PUBLISHED'],
      ['client', '==', clientConfig.name],
    ],
  });
  const podcasts = useTypedSelector<Podcast[]>(
    (state) => state.firestore.ordered.podcasts
  );
  const profile: any = useTypedSelector((state) => state.firebase.profile);
  const auth = useTypedSelector((state) => state.firebase.auth);
  const data = useTypedSelector(
    (state) => state.firestore.ordered['category-' + categoryId]
  );
  if (!auth.isLoaded || !data?.length) {
    return <Spinner />;
  }
  if (!profile.isAdmin) {
    return null;
  }
  const category: FrontCategory = data ? data[0] : null;
  if (!category || !podcasts) {
    return <Spinner />;
  }
  const categoryPodcasts = category?.podcasts?.map((podcastId) =>
    podcasts.find((p) => p.id === podcastId)
  );
  const changeOrder = (id: string, fromId: number, toId: number) => {
    const result = Array.from(categoryPodcasts);
    const [removed] = result.splice(fromId, 1);
    result.splice(toId, 0, removed);
    firestore
      .collection('frontcategories')
      .doc(category.id as string)
      .update({
        podcasts: result.map((r) => r?.id),
      });
  };
  const addPodcast = async (id: string) => {
    await firestore
      .collection('frontcategories')
      .doc(category.id as string)
      .update({
        podcasts: [...categoryPodcasts.map((p) => p?.id), id],
      });
  };
  const removePodcast = async (id: string) => {
    await firestore
      .collection('frontcategories')
      .doc(category.id as string)
      .update({
        podcasts: [
          ...categoryPodcasts.filter((p) => p?.id !== id).map((p) => p?.id),
        ],
      });
  };
  return (
    <PageContainer>
      <div className="tw-container tw-mx-auto tw-px-4">
        <div className="tw-text-xl tw-font-800 tw-mt-8 tw-border-b tw-border-gray-200 tw-pb-4 tw-flex tw-justify-between tw-items-center">
          <h1>Flokkur: {category.name}</h1>
          <div>
            <Button className={'gray'} to={'/admin'}>
              Til baka
            </Button>
            <Button
              disabled={podcasts?.length === categoryPodcasts?.length}
              onClick={() =>
                dispatch(
                  showModal(ModalType.SELECT_OPTION, {
                    options: podcasts
                      ?.filter(
                        (p) => !categoryPodcasts.some((cp) => cp?.id === p.id)
                      )
                      .map((p) => ({ name: p.name, val: p.id })),
                    callback: addPodcast,
                  })
                )
              }
            >
              + Bæta við
            </Button>
          </div>
        </div>
        <OrderableList
          className="tw-mt-5"
          items={categoryPodcasts
            ?.filter((cp) => !!cp)
            ?.map((podcast) => ({
              id: podcast?.id as string,
              name: podcast?.name as string,
              actions: [
                {
                  name: 'Fjarlægja',
                  onClick: () =>
                    dispatch(
                      showModal(ModalType.CONFIRM_MODAL, {
                        title: 'Fjarlægja úr flokk',
                        body: 'Viltu fjarlægja hlaðvarpið úr flokk?',
                        actionText: 'Já',
                        action: () => removePodcast(podcast?.id as string),
                      })
                    ),
                  isRed: true,
                },
              ],
            }))}
          changeOrder={changeOrder}
        />
      </div>
    </PageContainer>
  );
};

export default ManageCategoryPage;
