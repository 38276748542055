import React from 'react';
import s from './ManageEpisodesList.module.css';
import { SimpleTable } from '../simple-table/SimpleTable';
import { Episode, ModalType } from '../../types';
import { Button } from '../button/Button';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ContextMenu } from '../context-menu/ContextMenu';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modal';
import { format, isBefore, isEqual } from 'date-fns';

type Props = {
  episodes: Episode[];
  podcastId: string;
  deleteEpisode: (episode: Episode) => () => Promise<void>;
};

export const ManageEpisodesLists: React.FC<Props> = ({
  episodes,
  podcastId,
  deleteEpisode,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        <Button to={`/podcasts/uploads/${podcastId}/new`}>+ Nýr þáttur</Button>
      </div>
      <SimpleTable
        cols={[
          'Dagsetning',
          'Tímasetning',
          'Þáttur',
          'Stakar hlustanir',
          'Allar hlustanir',
          'Aðgangur',
          '',
        ]}
        rows={episodes.map((e) => [
          format(e.createdAt!.toDate(), 'dd. MMM yyyy HH:mm'),
          !e.displayDate || isBefore(e.displayDate!.toDate(), new Date())
            ? 'Í birtingu'
            : format(
                e.displayDate?.toDate() || new Date(),
                'dd. MMM yyyy HH:mm'
              ),
          e.name,
          e.played || 0,
          e.totalPlayed || 0,
          e.isOpen ? 'Opinn' : 'Aðeins áskrifendur',
          <div style={{ textAlign: 'right', paddingTop: '8px' }}>
            <ContextMenu>
              <Link to={`/episodes/${e.id}/edit`}>Breyta</Link>
              <div
                className={s.delete}
                onClick={() =>
                  dispatch(
                    showModal(ModalType.CONFIRM_MODAL, {
                      title: 'Eyða þætti',
                      actionText: 'Eyða',
                      action: deleteEpisode(e),
                      body: 'Ertu viss um að þú viljir eyða?',
                    })
                  )
                }
              >
                Eyða
              </div>
            </ContextMenu>
          </div>,
        ])}
      />
    </>
  );
};
