import React, { FC, PropsWithChildren } from 'react';
import { Podcast } from '../../types';
import s from './PodcastDescription.module.css';
import ReactMarkdown from 'react-markdown';
type Props = PropsWithChildren<{
  podcast: Podcast;
}>;
export const PodcastDescription: FC<Props> = ({ podcast, children }) => {
  return (
    <div className={s.container}>
      <h2>Um þáttinn</h2>
      <div className={s.content}>
        <ReactMarkdown>{podcast.description || ''}</ReactMarkdown>
      </div>
    </div>
  );
};
