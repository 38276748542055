import React from 'react';
import s from './ManagePodcast.module.css';
import { ManagePodcastTab, Podcast } from '../../types';
import { PodcastNav } from '../podcast-nav/PodcastNav';
import { Container } from 'react-bootstrap';
import { useTypedSelector } from '../../redux/reducers';
import { Redirect } from 'react-router';

type Props = {
  context: ManagePodcastTab;
  podcast: Podcast;
  children?: any;
};
export const ManagePodcast: React.FC<Props> = ({
  children,
  context,
  podcast,
}) => {
  const profile = useTypedSelector((state) => state.firebase.profile);
  const auth = useTypedSelector((state) => state.firebase.auth);
  if (!auth.isLoaded || !profile) {
    return null;
  }
  if (podcast.userId !== auth.uid && !profile.isAdmin) {
    return <Redirect to={'/' + podcast.slug} />;
  }

  return (
    <Container>
      <div className={s.manageCampaign}>
        <PodcastNav active={context} podcastId={podcast.id!} />
        <div style={{ marginTop: '15px' }}>
          <div className={s.content}>{children}</div>
        </div>
      </div>
    </Container>
  );
};
