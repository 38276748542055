import React from 'react';
import s from './HomepageHeaderSearch.module.css';
import {TextInput} from "../text-input/TextInput";

type Props = {
  search: string;
  setSearch: (val: string) => void;
}

export const HomepageHeaderSearch: React.FC<Props> = ({ search, setSearch }) => {
  return <div className={s.container}>
    <TextInput className={s.input} label={''} placeholder={'Leita...'} onChange={(val) => setSearch(val)} />
  </div>
};
