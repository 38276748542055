import React, { PropsWithChildren } from 'react';
import s from './LiveStreamBanner.module.css';
import { VisirTheme } from '../../visir-components/visir-theme/VisirTheme';
import { Button } from '../button/Button';
import { NavLink } from 'react-router-dom';

type Props = PropsWithChildren<{
  podcastId: string;
  hasSubscription: boolean;
}>;
export const LiveStreamBanner: React.FC<Props> = ({
  podcastId,
  hasSubscription,
}) => {
  return (
    <VisirTheme>
      <div className={s.container}>
        Bein útsending í gangi núna!{' '}
        <NavLink
          to={
            hasSubscription
              ? '/podcasts/detail/' + podcastId + '/live'
              : '/podcasts/purchase/' + podcastId
          }
          className={s.link}
        >
          Horfa á streymið
        </NavLink>
      </div>
    </VisirTheme>
  );
};
