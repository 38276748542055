import React, { PropsWithChildren } from 'react';
import s from './PageContainer.module.css';
import classNames from 'classnames';
import { useTypedSelector } from '../../redux/reducers';
import { NavLink } from 'react-router-dom';
import Footer from 'rc-footer';
import 'rc-footer/assets/index.css';
import clientConfig from '../../client-config';
import { VisirTheme } from '../../visir-components/visir-theme/VisirTheme';
import { VisirHeader } from '../../visir-components/visir-header/VisirHeader';
import { Helmet } from 'react-helmet';

type FooterItem = {
  navLink?: string;
  anchorLink?: string;
  title: string;
};

const FooterItem: React.FC<{ item: FooterItem }> = ({ item }) => {
  return (
    <span className={s.footerItem}>
      {item.navLink && (
        <NavLink
          exact
          activeClassName={s.active}
          key={item.navLink}
          to={item.navLink as string}
        >
          {item.title}
        </NavLink>
      )}
      {item.anchorLink && <a href={item.anchorLink}>{item.title}</a>}
      {!item.anchorLink && !item.navLink && <span>{item.title}</span>}
    </span>
  );
};

const ThemeContainer = ({
  children,
  skipHeader,
}: PropsWithChildren<{
  skipHeader?: boolean;
}>) => {
  if (clientConfig.name === 'visir') {
    return (
      <VisirTheme>
        <Helmet>
          <title>Tal - Vísir | Heimili íslenskra hlaðvarpa</title>
          <link rel={'canonical'} href={'https://tal.visir.is'} />
          <meta
            name="description"
            content="Tal - Vísir | Heimili íslenskra hlaðvarpa"
          />
        </Helmet>
        {!skipHeader && <VisirHeader />}
        {children}
      </VisirTheme>
    );
  }
  return <>{children}</>;
};

export const PageContainer = ({
  children,
  skipHeader,
}: PropsWithChildren<{
  skipHeader?: boolean;
}>) => {
  const { episode } = useTypedSelector((state) => state.player);
  if (episode) {
    const $crisp = (window as any).$crisp || [];
    $crisp.push(['do', 'chat:hide']);
  }
  return (
    <div className={classNames({ [s.withPlayer]: !!episode })}>
      <ThemeContainer skipHeader={skipHeader}>{children}</ThemeContainer>
    </div>
  );
};
