import React, { useRef, useState } from 'react';
import s from './ContextMenu.module.css';
import useOutsideClick from '../../utils/useOutsideClick';
import { MaterialIcon } from '../material-icon/MaterialIcon';

type Props = {
  icon?: string;
  minWidth?: number;
  children: React.ReactNode;
};
export const ContextMenu: React.FC<Props> = ({ icon, minWidth, children }) => {
  const [show, setShow] = useState<boolean>(false);
  if (!icon) {
    icon = 'dots';
  }
  const ref = useRef(null);
  useOutsideClick(ref, () => setShow(false));

  return (
    <span ref={ref} className={s.container}>
      <MaterialIcon
        onClick={() => setShow(!show)}
        icon={'/img/icons/menu.svg'}
      />
      {show && (
        <div
          style={{ minWidth: minWidth + 'px' || 0 }}
          className={s.dropdown}
          data-jest={'dropdown'}
          onClick={() => setShow(false)}
        >
          {children}
        </div>
      )}
    </span>
  );
};
