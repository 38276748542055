import React from 'react';
import s from './LiveCommentItem.module.css';
import { FeedComment, LiveEventComment, ModalType, Podcast } from '../../types';
import moment from 'moment';
import { useTypedSelector } from '../../redux/reducers';
import FeedBusiness from '../../business/FeedBusiness';
import { useFirestore } from 'react-redux-firebase';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modal';
import LivesBusiness from '../../business/LivesBusiness';
type Props = {
  comment: LiveEventComment;
};

export const LiveCommentItem: React.FC<Props> = ({ comment }) => {
  const auth = useTypedSelector((state) => state.firebase.auth);
  const profile = useTypedSelector((state) => state.firebase.profile);
  const podcasts = useTypedSelector(
    (state) => state.firestore.ordered['podcast-' + comment.podcastId]
  );
  const firestore = useFirestore();
  const liveBusiness = new LivesBusiness(firestore);
  const dispatch = useDispatch();
  if (!podcasts?.length || podcasts[0].id !== comment.podcastId) {
    return null;
  }
  const podcast: Podcast = podcasts[0];

  return (
    <div className={s.container}>
      <div className={s.content}>
        <div className={s.name}>{comment.userName}</div>
        <div className={s.textContent}>{comment.content}</div>
      </div>
      <div className={s.footer}>
        {moment(comment.createdAt?.toDate()).fromNow(true)}
        {(auth?.uid === comment?.userId ||
          profile?.isAdmin ||
          auth?.uid === podcast?.userId) && (
          <span
            className={s.delete}
            onClick={() =>
              dispatch(
                showModal(ModalType.CONFIRM_MODAL, {
                  title: 'Fjarlægja athugasemd',
                  body: 'Viltu fjarlægja þessa athugasemd?',
                  actionText: 'Já',
                  action: () =>
                    liveBusiness.deleteComment(comment.id as string),
                })
              )
            }
          >
            Fjarlægja
          </span>
        )}
      </div>
    </div>
  );
};
