import React, { useEffect, useRef, useState } from 'react';
import clientConfig from '../../client-config';
import { useTypedSelector } from '../../redux/reducers';
import s from './AudioPlayer.module.css';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'moment-duration-format';
import cn from 'classnames';
//@ts-ignore
import ReactPlayer from 'react-player/file';
import { useDispatch } from 'react-redux';
import { hidePlayer, togglePlay } from '../../redux/actions/player';
import moment from 'moment';
import { Simulate } from 'react-dom/test-utils';
import { useWindowSize } from '../../hooks/use-window.size';
export const AudioPlayer: React.FC<{ cachedTimes: any | null }> = ({
  cachedTimes,
}) => {
  const playerRef = useRef();
  const [width] = useWindowSize();
  const dispatch = useDispatch();
  const { podcast, episode, isPlaying } = useTypedSelector(
    (state) => state.player
  );
  const episodeUrl = episode?.url;
  const initialTime =
    episode?.id && cachedTimes ? cachedTimes[episode.id as string] : 0;

  const [seeking, setSeeking] = useState(false);
  const [played, setPlayed] = useState(0);
  const [audioMeta, setAudioMeta] = useState<any>({});
  const img = episode?.img ? `${episode?.img}?h=56` : '';
  const title = podcast?.name;
  const subtitle = episode?.name;
  const player = (playerRef as any)?.current;
  const durationInSeconds = player?.getDuration();
  useEffect(() => {
    if (width < 640 && episode) {
      document.documentElement.classList.add('with-modal');
      return () => {
        document.documentElement.classList.remove('with-modal');
      };
    } else {
      document.documentElement.classList.remove('with-modal');
    }
  }, [width, episode]);
  useEffect(() => {
    if (!episode) {
      return;
    }
    if (initialTime && player && durationInSeconds) {
      player.seekTo(initialTime / durationInSeconds);
    }
  }, [initialTime, player, episode, durationInSeconds]);
  useEffect(() => {
    if (episode?.id && played) {
      localStorage?.setItem(
        'time',
        JSON.stringify({ [episode?.id]: (played / 100) * durationInSeconds })
      );
    }
  }, [episode, played]);
  if (!episode) {
    return null;
  }
  const formatDuration = (duration: number) => {
    if (!duration) {
      return '00:00:00';
    }
    return moment
      .duration(duration, 'seconds')
      .format('hh:mm:ss', { trim: false });
  };
  const handleProgress = (val: any) => {
    if (!seeking) {
      setPlayed((val.played as number) * 100);
      setAudioMeta(val);
    }
  };
  const forwards = (n: number) => {
    const playedInSeconds = (played / 100) * durationInSeconds;
    const newPlayedInSeconds = playedInSeconds + n;
    const newPlayed = newPlayedInSeconds / durationInSeconds;
    player?.seekTo(newPlayed);
  };
  return (
    <div
      className={cn(s.container, {
        [s.visir]: clientConfig.name === 'visir',
        'visir-container': clientConfig.name === 'visir',
      })}
    >
      <span
        className={s.close}
        onClick={() => {
          setTimeout(() => {
            const $crisp = (window as any).$crisp || [];
            $crisp.push(['do', 'chat:show']);
          }, 500);
          dispatch(hidePlayer());
        }}
      >
        {width < 640 && <>Loka</>}
      </span>
      {width < 640 && (
        <div className={s.mobileImg}>
          <img src={`${episode?.img || podcast?.image}?h=250`} alt={''} />
        </div>
      )}
      <Slider
        step={1 / 1000}
        className={s.slider}
        min={0}
        max={100}
        trackStyle={{
          background: clientConfig.name === 'visir' ? '#6a5ef0' : '#180E33',
        }}
        value={played}
        onBeforeChange={() => setSeeking(true)}
        onChange={(val) => setPlayed(val as number)}
        handleStyle={{
          width: '20px',
          height: '20px',
          top: '2px',
          opacity: 1,
          backgroundColor:
            clientConfig.name === 'visir' ? '#6a5ef0' : '#180E33',
          border:
            clientConfig.name === 'visir'
              ? '2px solid #6a5ef0'
              : '2px solid #180E33',
        }}
        onAfterChange={(value) => {
          setSeeking(false);
          player.seekTo((value as number) / 100);
        }}
      />
      <div className={s.innerContainer}>
        <div className={s.actionsContainer}>
          <span
            className={s.rewind}
            onClick={() => {
              forwards(clientConfig.name === 'visir' ? -10 : -30);
            }}
          />
          <div
            className={cn({
              [s.playBtn]: true,
              [s.playBtnPlaying]: isPlaying,
            })}
            onClick={() => dispatch(togglePlay(episode, podcast))}
          />
          <span
            className={s.forwards}
            onClick={() => {
              forwards(clientConfig.name === 'visir' ? 10 : 15);
            }}
          />
        </div>
        <ReactPlayer
          ref={playerRef as any}
          url={episode.url}
          width={0}
          height={0}
          playing={isPlaying}
          onProgress={handleProgress}
        />
        <div className={s.titleContainer}>
          {img && (
            <div className={s.img}>
              <img src={img} alt={title} />
            </div>
          )}
          <div className={s.text}>
            <div className={s.podcastName}>{title}</div>
            <div className={s.title}>{subtitle}</div>
          </div>
        </div>
        <div className={s.duration}>
          {formatDuration(
            seeking
              ? (played / 100) * durationInSeconds
              : audioMeta.playedSeconds
          )}{' '}
          / {formatDuration(durationInSeconds)}
        </div>
      </div>
    </div>
  );
};
