import React, { useState } from 'react';
import GeneralModal from './GeneralModal';
import { useDispatch } from 'react-redux';
import { Button } from '../button/Button';
import { hideModal } from '../../redux/actions/modal';
import clientConfig from '../../client-config'

type Props = {
  podcastId: string;
  episodeId: string;
};

export const EmbedModal: React.FC<Props> = ({ podcastId, episodeId }) => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    const textBox: any = document.getElementById('embed-input');
    textBox?.select();
    document.execCommand('copy');
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };
  return (
    <GeneralModal disableOutsideClick>
      <div
        className={
          'tw-p-4 tw-max-w-[540px] tw-flex tw-flex-col tw-gap-4 tw-items-start'
        }
      >
        <div className={'tw-text-xl tw-font-700'}>HTML kóði</div>
        <textarea
          id={'embed-input'}
          className={
            'tw-w-full tw-resize-none tw-px-3 tw-pt-3 tw-bg-gray-100 tw-pb-4 tw-border tw-border-gray-300 tw-rounded-md tw-outline-none'
          }
          rows={5}
          readOnly
        >
          {`<iframe style="border-radius:12px" src="https://embed.pardus.is?podcastId=${podcastId}&episodeId=${episodeId}&client=${clientConfig.name}" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>`}
        </textarea>
        <div className={'tw-flex'}>
          <Button
            onClick={() => copyToClipboard()}
            className={copied ? 'green' : 'gray'}
          >
            {copied ? 'Afritað' : 'Afrita'}
          </Button>
          <Button onClick={() => dispatch(hideModal())}>Loka</Button>
        </div>
      </div>
    </GeneralModal>
  );
};
