import React, { useState } from 'react';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router';
import CategoryBusiness from '../../business/CategoryBusiness';
import { CategoryForm } from '../../components/category-form/CategoryForm';
import { PageContainer } from '../../components/page-container/PageContainer';
import { useTypedSelector } from '../../redux/reducers';
import { FrontCategory } from '../../types';
import { error, success } from '../../utils/Notifications';

const EditCategory: React.FC<any> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');
  const firestore = useFirestore();
  const {
    match: { params },
  } = props;
  const { categoryId } = params;

  useFirestoreConnect({
    collection: 'frontcategories',
    doc: categoryId,
    storeAs: 'category' + categoryId,
  });

  const data = useTypedSelector(
    (state) => state.firestore.ordered['category' + categoryId]
  );
  const category: FrontCategory = data ? data[0] : null;
  if (!category) {
    return null;
  }
  const save = async (cat: FrontCategory) => {
    setIsLoading(true);
    const categoryBusiness = new CategoryBusiness(firestore);
    try {
      await categoryBusiness.update(cat);
      setIsLoading(false);
      success('Aðgerð tókst');
      setRedirect('/admin');
    } catch (err: any) {
      error(err.message || 'Ekki tókst að búa til flokk');
      setIsLoading(false);
    }
  };
  return (
    <PageContainer>
      <CategoryForm
        title={'Breyta flokk'}
        save={save}
        isLoading={isLoading}
        cat={category}
      />
      {redirect && <Redirect to={redirect} />}
    </PageContainer>
  );
};

export default EditCategory;
