import React, { useState } from 'react';
import s from './CopyableInput.module.css';
import classNames from 'classnames';
import { uuid } from '../../utils/utilities';
type Props = {
  label: string;
  text: string;
  bottomSpace?: boolean;
};
export const CopyableInput: React.FC<Props> = ({
  label,
  text,
  bottomSpace,
  ...rest
}) => {
  const id = uuid();
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    const textBox: any = document.getElementById(id);
    textBox?.select();
    document.execCommand('copy');
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };
  return (
    <div
      className={s.container}
      style={{ marginBottom: bottomSpace ? `20px` : `0px` }}
    >
      <div className={s.label}>{label}</div>
      <div className={s.input}>
        <input
          id={id}
          type={'text'}
          readOnly={true}
          value={text}
          onClick={() => copyToClipboard()}
          className={classNames({ [s.copiedPadding]: copied })}
        />
        <button className={s.copyBtn} onClick={() => copyToClipboard()}>
          Afrita
        </button>
        {copied && <span className={s.copied}>Afritað!</span>}
      </div>
    </div>
  );
};
