import React, { useState } from 'react';
import { TopNav } from '../components/top-nav/TopNav';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useTypedSelector } from '../redux/reducers';
import { Spinner } from '../components/spinner/Spinner';
import { Podcast } from '../types';
import { HomepagePodcasts } from '../components/homepage-podcasts/HomepagePodcasts';
import { PageContainer } from '../components/page-container/PageContainer';
import { HomepageHeader } from '../components/homepage-header/HomepageHeader';
import { WithPardusFooter } from '../components/pardus-footer/PardusFooter';
import { CategoriesList } from '../components/categories-list/CategoriesList';

const Homepage: React.FC = (props) => {
  useFirestoreConnect({
    collection: 'podcasts',
    orderBy: ['createdAt', 'asc'],
    where: [
      ['active', '==', true],
      ['status', '==', 'PUBLISHED'],
      ['client', '==', 'main']
    ],
  });
  const podcasts = useTypedSelector(
    (state) => state.firestore.ordered.podcasts
  );

  let publishedPodcasts = podcasts?.filter(
    (p: Podcast) => p.status === 'PUBLISHED'
  );

  const orderedPodcasts = publishedPodcasts?.filter(
    (p: Podcast) => p.ordinal || p.ordinal === 0
  );
  const rest = publishedPodcasts?.filter(
    (p: Podcast) => !p.ordinal && p.ordinal !== 0
  );
  orderedPodcasts?.sort((a: Podcast, b: Podcast) => {
    return (a.ordinal as number) - (b.ordinal as number);
  });
  const allPodcasts = podcasts ? [...orderedPodcasts, ...rest] : [];
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const filteredPodcasts = allPodcasts.filter(
    (p: Podcast) =>
      (search ||
        !selectedCategory ||
        p.categories?.includes(selectedCategory)) &&
      (!search ||
        p.name?.toLowerCase().includes(search?.toLowerCase()) ||
        p.description?.includes(search?.toLowerCase()))
  );
  const categories: Set<string> = new Set();
  allPodcasts.forEach((p: Podcast) =>
    p.categories?.forEach((c) => categories.add(c))
  );
  const getCountForCategory = (cat: string): number => {
    return allPodcasts.reduce(
      (a, b) => a + (b.categories?.includes(cat) ? 1 : 0),
      0
    );
  };
  return (
    <PageContainer>
      <HomepageHeader search={search} setSearch={setSearch}>
        <TopNav transparent={true} />
      </HomepageHeader>
      {isLoaded(podcasts) ? (
        <WithPardusFooter>
          {!search && (
            <CategoriesList
              categories={Array.from(categories).map((cat) => ({
                title: cat,
                count: getCountForCategory(cat),
              }))}
              setSelectedCategory={(cat: string) => setSelectedCategory(cat)}
              selectedCategory={selectedCategory}
              podcastsCount={allPodcasts?.length || 0}
            />
          )}
          <HomepagePodcasts
            title={
              !search
                ? selectedCategory || 'Áskriftir og efni'
                : 'Áskriftir og efni'
            }
            podcasts={filteredPodcasts}
            isEmpty={!!search && !filteredPodcasts.length}
          />
        </WithPardusFooter>
      ) : (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Spinner />
        </div>
      )}
    </PageContainer>
  );
};

export default Homepage;
